import CraftGrids   from "./grids";
import CraftModals  from "./modals";
import CraftButtons from "./buttons";
import CraftLists   from "./lists";
import CraftFields  from "./fields";
import CraftHelpers from "./helpers";
import CraftHooks   from "./hooks";
import CraftBlocks  from "./blocks";
import CraftForms from "./forms";


const Craft = {
    Grids   : CraftGrids,
    Modals  : CraftModals,
    Buttons : CraftButtons,
    Lists   : CraftLists,
    Fields  : CraftFields,
    Helpers : CraftHelpers,
    Hooks   : CraftHooks,
    Blocks  : CraftBlocks,
    Forms   : CraftForms
}

export default Craft;