import React from "react";

import Furnishing from "components/furnishing";
import { CustomTabs } from "components/service";
import { Wraps } from "components/layout";
import { useMe, useVarParam } from "components/use-hooks";
import { localizeIt } from "components/service/localize";


const FurnishingPage = () => {

    const appParams = useVarParam(),
          moment = useVarParam().get("moment"),
          { me } = useMe();

    return (
        <Wraps.Container className="page furnishing-page">
            <CustomTabs
                defaultTab = { "storage" }
                tabSwitcher= {
                    <Furnishing.Buttons.ChangeTab
                        buttonsArr={ [ 'storage', 'orderLog' ]  }
                    />
                }
            >
                <Furnishing.Tabs.Storage
                    key  = "storage"
                    eeId = { me.eeCurrentId }
                    beforeLoad = { () => {
                        appParams.set({
                            headerTitle: localizeIt("PAGE.Title_FurnishingLeftovers"),
                            bottomBar: true
                        });
                    }}
                />
                <Furnishing.Tabs.Refund
                    key  = "orderLog"
                    eeId = { me.eeCurrentId }
                    moment = { moment }
                    beforeLoad = { () => {
                        appParams.set({
                            headerTitle: localizeIt("PAGE.Title_FurnishingReturns"),
                            bottomBar: true
                        });
                    }}
                />
            </CustomTabs>
        </Wraps.Container>
    );
}

export default FurnishingPage;