const restorePosition = (history, pathname, state) => {

    setTimeout( () => {

        if(!state.positionChanged){
            window.scroll({ top: state.pagePosition, behavior: "auto" });
            history.replace( {
                pathname
            }, { ...state, positionChanged : true } );
        }
    }, 50);

}

export default restorePosition;