import { notification } from "antd";


const successNotification = (props) => {

    const {
        title,
        description,
        duration = 4,
    } = props;

    notification['success']({
        message: title,
        description: description,
        duration: duration,
        className: "success-notification",
        //icon: <Icons.ActionStatus type="success"/>,
        //closeIcon: <Icons.Close />,
    });

};

export default successNotification;