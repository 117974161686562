import CraftActionsModal from "./craft-actions-modal";
import CraftOverviewFilterModal from "./craft-overview-filter-modal";

import "./craft-modals.scss";


const CraftModals = {
    Actions     : CraftActionsModal,
    FilterModal : CraftOverviewFilterModal
};

export default CraftModals;