import React from "react";
import { useQuery } from "@apollo/client";

import { GET_FURNISHING } from "graphql/query/furnishing-gql";

import FurnishingCustomList from "../furnishing-custom-list";
import { Loader, NoData } from "components/request-result";
import { Localize } from "components/service";
import { whereConditionBuilder } from "utils";
import FurnishingLists from "../index";


const FurnishingList = ({
        eeId,
        perPage = 50,
        itemClassName,
        loaderType = "block",
        fetchPolicy = "cache-and-network",
        optimized = true,
        ActionComponent,
        ...props
    }) => {

    const { data, loading, fetchMore } = useQuery( GET_FURNISHING, {
        variables: {
            where: whereConditionBuilder({
                eeId : {
                    column: "EE_ID",
                    operator : "EQ",
                    value : eeId
                },
            }),
            orderBy: [{ column: "TITLE", order : "ASC" }],
            first: perPage,
        },
        fetchPolicy,
        notifyOnNetworkStatusChange: true
    });

    const {
        furnishingsCursor : {
            edges: furnishings = [],
            pageInfo = {}
        } = {}
    } = data ? data : {};

    return(
        <>
            { !furnishings.length && !loading &&
                <NoData
                    text={{
                        after :  <Localize br={ <br /> }>NO_DATA.Furnishing_Storage</Localize>
                    }}
                />
            }
            {!!furnishings.length &&
                <>
                    { optimized ?
                        <FurnishingCustomList
                            data={{
                                furnishings,
                                pageInfo,
                                fetchMore,
                                perPage,
                                model: "furnishingsCursor"
                            }}
                            className="furnishing-list"
                            itemClassName={itemClassName}
                            loading={loading}
                            ActionComponent={ActionComponent}
                            {...props}
                        /> :
                        <div className="furnishing-selected">
                            {furnishings.map(
                                ({node}) => {

                                    return (
                                        <FurnishingLists.Single
                                            key={ node.id }
                                            {...node}
                                            {...props}
                                            ActionComponent={ActionComponent}
                                        />
                                    );
                                })
                            }
                        </div>
                    }
                </>
            }
            { loading && <Loader type={ loaderType } /> }
        </>
    );
}

export default FurnishingList;