import React from "react";
import { Button } from "antd";

import FurnishingForms from "../../forms";
import { ModalStandard, Localize } from "components/service";
import { Icons } from "components/layout";
import { useWindowDimensions } from "components/use-hooks";


const FurnishingRefundModal = (props) => {

    const { width } = useWindowDimensions();

    return(
        <div className="furnishing-refund-modal-wrap">
            <ModalStandard
                extraClass="furnishing-refund-modal grey"
                title={
                    <Localize>FURNISHING.Modal_Title_ReturnFurnishing</Localize>
                }
                centered={ !(width <= 768) }
                modalButton={
                    <Button type="default" className="icon round">
                        <Icons.Edit />
                    </Button>
                }
            >
                <FurnishingForms.Refund
                    { ...props }
                />
            </ModalStandard>
        </div>
    );
};

export default FurnishingRefundModal;