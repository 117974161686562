import React, { useState } from "react";


const CustomTabs = ({ defaultTab, tabList = [], tabSwitcher, className = "", ...props }) => {

    const [ tabKey, setTabKey ] = useState(defaultTab);


    return(
        <div className={ `custom-tabs ${ className }` }>
            <div className="custom-tabs-switcher">
                { tabSwitcher &&
                    React.cloneElement(
                        tabSwitcher,
                        { tabKey, setTabKey }
                    )
                }
            </div>
            <div className="custom-tabs-wrap">
                { props.children.map(
                    (item) => {
                        return tabKey === item.key ? item : null;
                    })
                }
            </div>
        </div>
    )

}

export default CustomTabs;