import React from "react";
import { Button } from "antd";

import { Icons } from "components/layout";
import { ImagePanPinchViewer, Localize } from "components/service";
import { saveAsFile } from "utils";


const PhotoViewerBlock = ({ title, photo, file,  callback }) => {

    const Actions = ({ rotate, /*rotation,*/ callback }) => (
        <div className="photo-viewer-actions">
            <div className="group">
                <Button
                    type="default"
                    className="icon"
                    onClick={ () => rotate() }
                >
                    <Icons.Turn />
                </Button>
                <Button
                    type = "default"
                    className = "icon"
                    onClick = { () => saveAsFile.document(file ? file : photo) }
                >
                    <Icons.Download />
                </Button>
            </div>
            <Button
                type="default"
                onClick={ () => callback() }
            >
                <Localize wrap>GLOBAL.Button_Text_Cancel</Localize>
            </Button>
        </div>
    );

    return(
        <div className="photo-viewer-wrap">
            <h3>{ title }</h3>
            <ImagePanPinchViewer
                extraClass={ "photo-viewer" }
                title={ title }
                image={ photo }
            >
                <Actions callback={ callback } />
            </ImagePanPinchViewer>
        </div>
    )
}

export default PhotoViewerBlock;