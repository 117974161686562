import React from 'react';
import Icon from '@ant-design/icons';


const ProtocolSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M34.4,7.6h-2.6C31.4,6.7,30.6,6,29.6,6H19c-1,0-1.8,0.7-2.1,1.6h-2.6c-2.2,0-4,1.8-4,4v25.5c0,2.2,1.8,4,4,4h20.1
		c2.2,0,4-1.8,4-4V11.6C38.4,9.4,36.6,7.6,34.4,7.6z M34.8,37.2c0,0.2-0.2,0.4-0.4,0.4H14.3c-0.2,0-0.4-0.2-0.4-0.4V11.6
		c0-0.2,0.2-0.4,0.4-0.4H17c0.3,0.9,1.1,1.5,2.1,1.5h10.6c1,0,1.8-0.6,2.1-1.5h2.7c0.2,0,0.4,0.2,0.4,0.4V37.2z"/>
        <path d="M30.5,16.6H18.1c-1,0-1.8,0.8-1.8,1.8c0,1,0.8,1.8,1.8,1.8h12.4c1,0,1.8-0.8,1.8-1.8C32.3,17.4,31.5,16.6,30.5,16.6z"/>
        <path d="M30.5,22.2H18.1c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8h12.4c1,0,1.8-0.8,1.8-1.8S31.5,22.2,30.5,22.2z"/>
        <path d="M30.5,27.8H18.1c-1,0-1.8,0.8-1.8,1.8c0,1,0.8,1.8,1.8,1.8h12.4c1,0,1.8-0.8,1.8-1.8C32.3,28.6,31.5,27.8,30.5,27.8z"/>
    </svg>
);

const ProtocolIcon = props => {
    return <Icon component={ ProtocolSvg } className={ props.className } />;

};

export default ProtocolIcon;