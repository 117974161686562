import React from 'react';
import Icon from '@ant-design/icons';


const MenuSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M39.9,12.6H8.4c-0.7,0-1.2-0.6-1.2-1.2s0.6-1.2,1.2-1.2h31.5c0.7,0,1.2,0.6,1.2,1.2S40.6,12.6,39.9,12.6z"/>
        <path d="M39.9,25.5H8.4c-0.7,0-1.2-0.6-1.2-1.2S7.7,23,8.4,23h31.5c0.7,0,1.2,0.6,1.2,1.2S40.6,25.5,39.9,25.5z"/>
        <path d="M39.9,38.5H8.4c-0.7,0-1.2-0.6-1.2-1.2S7.7,36,8.4,36h31.5c0.7,0,1.2,0.6,1.2,1.2S40.6,38.5,39.9,38.5z"/>
    </svg>
);

const MenuIcon = props => {
    return <Icon component={ MenuSvg } className={ props.className } />;

};


export default MenuIcon;