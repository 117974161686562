import JobChangeStatusBtn from "./job-change-status-btn";
import JobFlagBtn from "./job-flag-btn";

import "./job-buttons.scss";


const JobButtons = {
    ChangeStatus : JobChangeStatusBtn,
    ChangeFlag   : JobFlagBtn
}

export default JobButtons;