import { useEffect, useRef } from "react";
import { useMutation } from "@apollo/client";

import { DAMAGE_REPORT_CREATE, DAMAGE_REPORT_UPDATE } from "graphql/mutation/damages-gql";

import { useNotification } from "components/use-hooks";
import cache from "graphql/cache";


const useCreateDamageReportHook = ({stand, loading, data, setCurrentReportId}) => {

    const { nError } = useNotification();

    const willUnmount = useRef(false);

    const [ reportCreate, { createLoading }] = useMutation( DAMAGE_REPORT_CREATE, {
        update(cache, { data : { damageReportCreate } }) {
            setCurrentReportId(
                damageReportCreate.damageReport.id
            )
        }
    } );

    const [ damageReportUpdate ] = useMutation( DAMAGE_REPORT_UPDATE );

    useEffect( () => {
        if(!data && !loading){
            reportCreate({
                variables : {
                    input : {
                        stand_id : stand.id,
                        status   : "delete"
                    }
                }
            }).catch( (error) => nError(error) );
        }

        return () => {
            willUnmount.current = true;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect( () => {
        return () => {
            if( willUnmount.current === true ) {

                const { damageReport } = data;

                let status = damageReport.status;


                if(damageReport.status === "delete" && damageReport.damageFiles.length > 0){
                    status = "new";
                }

                if( damageReport.damageFiles.length === 0 && damageReport.status !== "delete"){
                    status = "delete";
                }

                if(damageReport.status !== status){
                    damageReportUpdate({
                        variables : {
                            input: {
                                id : damageReport.id,
                                status
                            }
                        },

                    }).then( () => {

                        cache.modify({
                            id: cache.identify(stand),
                            fields: {
                                damageReport() {
                                    return {
                                        ...damageReport,
                                        status
                                    };
                                }
                            }
                        });

                    }).catch( nError );
                } else {

                    cache.modify({
                        id: cache.identify(stand),
                        fields: {
                            damageReport() {
                                return {
                                    ...damageReport,
                                    status
                                };
                            }
                        }
                    });

                }


            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return createLoading;
}


export default useCreateDamageReportHook;