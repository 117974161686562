import React from 'react';
import Icon from '@ant-design/icons';


const NoHallSvg = ({
        colors = {
            bg      : { fill: "#EAEAEB" },
            border  : { fill: "#C3C4C6" },
            bg2     : { fill: "#FFFFFF" },
            border2 : { fill: "#A5A7A9" },
        }
    }) => (
    <svg fill="currentColor"  width="1em" height="1.331em" viewBox="0 0 69.5 92.5" strokeWidth="1" >
        <g>
            <g>
                <polygon style={{ ...colors.bg }} points="69.3,92.2 0.3,92.2 0.3,0.2 46.8,0.2 69.3,22.4 		"/>
                <path style={{ ...colors.border }} d="M69.5,92.5H0V0h46.9L47,0.1l22.5,22.3V92.5z M0.5,92H69V22.6L46.7,0.5H0.5V92z"/>
            </g>
            <path style={{ ...colors.bg2 }} d="M27,29.3H12.3v43h43.6v-34H27C27,38.3,27.2,29.3,27,29.3z"/>
            <polygon style={{ ...colors.border }} points="69.2,22.7 46.6,22.7 46.6,0.2 47.1,0.2 47.1,22.2 69.2,22.2 	"/>
            <polygon style={{ ...colors.border2 }} points="40,37.8 40,38.8 41.2,38.8 41.2,46.8 42.2,46.8 42.2,38.8 55.4,38.8 55.4,55.3 52.6,55.3 52.6,56.3
		55.4,56.3 55.4,71.8 39.4,71.8 39.4,56.3 47.4,56.3 47.4,55.3 42.2,55.3 42.2,52 41.2,52 41.2,55.3 31.9,55.3 31.9,52 30.9,52
		30.9,55.3 28.4,55.3 28.4,56.3 38.4,56.3 38.4,71.8 12.8,71.8 12.8,56.1 23.3,56.1 23.3,55.1 12.8,55.1 12.8,29.8 26.5,29.8
		26.5,38.8 30.9,38.8 30.9,46.8 31.9,46.8 31.9,38.8 33.7,38.8 33.7,37.8 27.5,37.8 27.5,28.8 11.8,28.8 11.8,72.8 56.4,72.8
		56.4,37.8 	"/>
        </g>
    </svg>
);

const NoKeysSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 176 176" strokeWidth="1" >
        <path d="M143.2,170.3H34.7c-6.9,0-12.4-5.6-12.4-12.4V79.5c0-6.9,5.6-12.4,12.4-12.4h108.5c6.9,0,12.4,5.6,12.4,12.4
		v78.3C155.6,164.7,150,170.3,143.2,170.3z M34.7,68.1c-6.3,0-11.4,5.1-11.4,11.4v78.3c0,6.3,5.1,11.4,11.4,11.4h108.5
		c6.3,0,11.4-5.1,11.4-11.4V79.5c0-6.3-5.1-11.4-11.4-11.4H34.7z"/>
        <path d="M88.9,141.2c-3.9,0-7.2-3.2-7.2-7.2v-12.9c-3.9-2.5-6.3-6.7-6.3-11.3c0-7.4,6-13.4,13.4-13.4
		c7.4,0,13.4,6,13.4,13.4c0,4.6-2.3,8.9-6.3,11.3V134C96.1,138,92.9,141.2,88.9,141.2z M88.9,97.4c-6.8,0-12.4,5.6-12.4,12.4
		c0,4.4,2.3,8.4,6,10.6l0.2,0.1V134c0,3.4,2.8,6.2,6.2,6.2c3.4,0,6.2-2.8,6.2-6.2v-13.5l0.2-0.1c3.8-2.3,6-6.3,6-10.6
		C101.3,102.9,95.8,97.4,88.9,97.4z"/>
        <path d="M119,67.9h-1V51.7c0-16-13-29.1-29.1-29.1s-29.1,13-29.1,29.1v16.2h-1V51.7c0-16.6,13.5-30.1,30.1-30.1
		c16.6,0,30.1,13.5,30.1,30.1V67.9z"/>
        <path d="M137,67.9h-1V51.7c0-26-21.1-47.1-47.1-47.1c-26,0-47.1,21.1-47.1,47.1v16.2h-1V51.7
		c0-26.5,21.6-48.1,48.1-48.1S137,25.2,137,51.7V67.9z"/>
        <path style={{ opacity: 0.08 }} d="M101.8,109.8c0-7.1-5.8-12.9-12.9-12.9c-7.1,0-12.9,5.8-12.9,12.9c0,4.7,2.5,8.8,6.3,11.1V134
		c0,3.7,3,6.7,6.7,6.7s6.7-3,6.7-6.7v-13.2C99.3,118.6,101.8,114.5,101.8,109.8z"/>
    </svg>
);

const NoJobSvg = () => (
    <svg fill="currentColor"  width="1em" height="1.4076em" viewBox="0 0 79 111.2" strokeWidth="1" >
        <path d="M18,55.3c-0.1,0-0.2,0-0.3-0.1l-5.5-5.5c-0.1-0.1-0.1-0.4,0-0.5s0.4-0.1,0.5,0l5.2,5.2l8.9-8.9
		c0.1-0.1,0.4-0.1,0.5,0s0.1,0.4,0,0.5l-9.2,9.2C18.2,55.3,18.1,55.3,18,55.3z"/>
        <path d="M78.6,111.2H0.4c-0.2,0-0.4-0.2-0.4-0.4V15.6c0-0.2,0.2-0.4,0.4-0.4h17.8c0.2,0,0.4,0.2,0.4,0.4
		c0,0.2-0.2,0.4-0.4,0.4H0.7v94.4h77.5V16H60.9c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4h17.7c0.2,0,0.4,0.2,0.4,0.4v95.1
		C79,111,78.8,111.2,78.6,111.2z"/>
        <g>
            <path style={{ opacity: 0.08 }} d="M49.5,9.9c-0.3-5.3-4.6-9.5-10-9.5s-9.7,4.2-10,9.5H18.3v17h42.6v-17H49.5z"/>
            <path d="M60.8,27.3H18.3c-0.2,0-0.4-0.2-0.4-0.4v-17c0-0.2,0.2-0.4,0.4-0.4h11C29.7,4.2,34.1,0,39.5,0
			c5.4,0,9.9,4.2,10.3,9.5h11c0.2,0,0.4,0.2,0.4,0.4v17C61.2,27.1,61,27.3,60.8,27.3z M18.6,26.5h41.8V10.3H49.5
			c-0.2,0-0.4-0.2-0.4-0.4c-0.2-5.2-4.5-9.2-9.6-9.2c-5.1,0-9.4,4-9.6,9.2c0,0.2-0.2,0.4-0.4,0.4H18.6V26.5z"/>
        </g>
        <path d="M64.3,51.9H35.2c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4h29.1c0.2,0,0.4,0.2,0.4,0.4
		C64.7,51.8,64.5,51.9,64.3,51.9z"/>
        <path d="M18,71.9L18,71.9c-0.1,0-0.2,0-0.3-0.1l-5.5-5.5c-0.1-0.1-0.1-0.4,0-0.5c0.1-0.1,0.4-0.1,0.5,0L18,71l8.9-8.9
		c0.1-0.1,0.4-0.1,0.5,0c0.1,0.1,0.1,0.4,0,0.5l-9.2,9.2C18.2,71.8,18.1,71.9,18,71.9z"/>
        <path d="M64.3,68.5H35.2c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4h29.1c0.2,0,0.4,0.2,0.4,0.4
		C64.7,68.3,64.5,68.5,64.3,68.5z"/>
        <path d="M18,88L18,88c-0.1,0-0.2,0-0.3-0.1l-5.5-5.5c-0.1-0.1-0.1-0.4,0-0.5c0.1-0.1,0.4-0.1,0.5,0l5.2,5.2l8.9-8.9
		c0.1-0.1,0.4-0.1,0.5,0s0.1,0.4,0,0.5l-9.2,9.2C18.2,88,18.1,88,18,88z"/>
        <path d="M64.3,84.6H35.2c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4h29.1c0.2,0,0.4,0.2,0.4,0.4S64.5,84.6,64.3,84.6z"/>
    </svg>
);

const NoStandSvg = () => (
    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 176 176" strokeWidth="1" >
        <g>
            <rect x="30.3" y="108.2" transform="matrix(0.868 -0.4966 0.4966 0.868 -47.5244 38.6321)" width="37.1" height="1"/>
            <rect x="129.7" y="90.3" transform="matrix(0.5073 -0.8618 0.8618 0.5073 -30.9395 166.4875)" width="1" height="40"/>
            <path d="M89.1,90c-0.1,0-0.2,0-0.2-0.1L13.2,46l0.5-0.8L89.1,89l74.1-42.8l0.5,0.8l-74.3,43C89.3,90,89.2,90,89.1,90z
			"/>
            <path d="M89.1,114.5c-0.1,0-0.2,0-0.2-0.1L13.1,69.7l0.5-0.8l75.5,44.6l74.2-42.7l0.2,1.2l-74.2,42.4
			C89.3,114.5,89.2,114.5,89.1,114.5z"/>
            <path d="M89.1,174.1c-0.1,0-0.2,0-0.2-0.1l-75.8-45.3c-0.1-0.1-0.2-0.2-0.2-0.4V45.6c0-0.2,0.1-0.3,0.2-0.4L89,2
			c0.1-0.1,0.3-0.1,0.5,0l74.4,44c0.1,0.1,0.2,0.2,0.2,0.4v83.4c0,0.2-0.1,0.3-0.2,0.4L89.4,174C89.3,174.1,89.2,174.1,89.1,174.1z
			 M13.8,128.1l75.3,45l74.1-43.5V46.7L89.2,3L13.8,45.9V128.1z"/>
            <rect x="88.8" y="89.3" width="1" height="84.5"/>
            <rect x="32" y="80.2" width="1" height="59.4"/>
            <rect x="146.6" y="80.7" width="1" height="58.5"/>
        </g>
        <polygon style={{ opacity: 0.08 }} points="64.7,99.8 32.5,118.2 32.5,139.8 89.1,173 147,139.4 147,120 113.5,100.4 89,114 	"/>
    </svg>
);

const NoContentIcon = props => {

    if(props.type === "hall"){
        return <Icon component={ NoHallSvg } colors={ props.colors } className={ props.className } />;
    }

    if(props.type === "keys"){
        return <Icon component={ NoKeysSvg } className={ props.className } />;
    }

    if(props.type === "job"){
        return <Icon component={ NoJobSvg } className={ props.className } />;
    }

    if(props.type === "stand"){
        return <Icon component={ NoStandSvg } className={ props.className } />;
    }

    return <Icon component={ NoHallSvg } className={ props.className } />;

};


export default NoContentIcon;