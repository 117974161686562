import React from 'react';
import Icon from '@ant-design/icons';


const QuestionSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M31.8,8.7c-2.2-2.2-5.4-3.2-8.6-2.8c-7.1,1-10.1,6.9-10.7,9.9c-0.2,1.1,0.5,2.1,1.6,2.3c1.1,0.2,2.1-0.5,2.4-1.6
		c0-0.2,1.3-5.9,7.3-6.7c2-0.3,3.9,0.3,5.3,1.6c1.1,1.1,1.7,2.6,1.5,4.1c-0.3,2.3-0.9,3.8-5.2,7.6c-4.6,4-3.7,9.1-3.6,9.3
		c0.2,1,1,1.6,2,1.6c0.1,0,0.2,0,0.4,0c1.1-0.2,1.8-1.2,1.6-2.3c0-0.1-0.5-3.1,2.3-5.6c4.6-4,6.1-6.4,6.5-10.2
		C34.7,13.4,33.8,10.7,31.8,8.7z"/>
        <path d="M23.9,37.1c-1.3,0-2.4,1.1-2.4,2.4c0,1.3,1.1,2.4,2.4,2.4s2.4-1.1,2.4-2.4C26.3,38.1,25.2,37.1,23.9,37.1z"/>
    </svg>
);

const QuestionIcon = props => {
    return <Icon component={ QuestionSvg } className={ props.className } />;

};

export default QuestionIcon;