import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Button } from "antd";

import Icons from "../icons";


const BackButton = ({ logo = false }) => {

    const history = useHistory(),
          location = useLocation();


    if(location.pathname === "/" || logo){
        return(
            <Link
                to="/"
                className="menu-button home"
            >
                <Icons.Logo className="logo"/>
            </Link>
        )
    }

    const goBackLink = location.state && location.state.from ? location.state.from : "/";

    return(

        <Button
            onClick={
                () => {
                    if(goBackLink !== "/"){
                        if(location.state.returnPagePosition){
                            history.replace(
                                goBackLink,
                                { pagePosition : location.state.returnPagePosition }
                            )
                        } else {
                            history.go(-1)
                        }
                    } else {
                        history.push(goBackLink)
                    }
                }
            }
            className="back-button icon rounded"
        >
            <Icons.Arrow type="full" />
        </Button>
    );
};

export default BackButton;