import Job from "components/job";
import { Icons } from "components/layout";
import { localizeIt } from "components/service/localize";
import CraftHelpers from "components/craft/helpers";
import JobHelpers from "components/job/helpers";


// const getCurrentEE = (varParams) => {
//
//     const { exhibitionEvent, eeCurrentId } = varParams.get("me");
//
//     return exhibitionEvent.find((ee) => ee.id === eeCurrentId);
// }

const sortStatistic = (statistic) => {

    const order = [ "stands" , "jobs_claims", "jobs_customer_claims","jobs_last_min", "mp_control", "jobs_damages",  "protocol" ],
          rStatistic = [];

    if(!statistic){
        return rStatistic;
    }

    order.forEach( (type) => {
        rStatistic.push({
            "type" : type,
            label  : statisticLabelByType(type),
            icon   : statisticIconByType(type),
            ...statistic[type],
            ...getFilterByType(type)
        });
    });

    return rStatistic;
}

const statisticLabelByType = (type) => {
    const labels = {
        stands               : "EXHIBITION.Statistic_Type_Stands",
        jobs_claims          : "EXHIBITION.Statistic_Type_Claims",
        jobs_customer_claims : "EXHIBITION.Statistic_Type_CustomerClaims",
        jobs_last_min        : "EXHIBITION.Statistic_Type_LastMinute",
        mp_control           : "EXHIBITION.Statistic_Type_MpControl",
        jobs_damages         : "EXHIBITION.Statistic_Type_Damages",
        protocol             : "EXHIBITION.Statistic_Type_Protocol",
    }

    return labels[type];
}

const statisticIconByType = (type) => {

    switch (type){
        case "jobs_claims"          : return <Icons.Claims />;
        case "jobs_customer_claims" : return <Icons.Claims />;
        case "jobs_last_min"        : return <Icons.LastMinute />;
        case "mp_control"           : return <Icons.Control />;
        case "jobs_damages"         : return <Icons.Damages />;
        case "protocol"             : return <Icons.Protocol />;
        default: return <Icons.Stands />;
    }
}

const getFilterByType = (type) => {


    const filters = {
        "stands": {},
        "jobs_claims" :{
            route  :  "/jobs/",
            getFilter : () => {

                return {
                    filter : {
                        where: {
                            status : {
                                label    :
                                    Job.Helpers.Status.arrToLabelStr(
                                        { jobStatuses : ["waiting"]}
                                    ),
                                column   : "STATUS",
                                operator : "IN",
                                value    : ["waiting"],
                            },

                            type : {
                                label    :
                                    JobHelpers.NameByType.localized({ jobTypes: ["all_claims"] }),
                                column   : "TYPE",
                                operator : "IN",
                                value    : ["claims"],
                            }
                        },
                        activeFilters : 2
                    }
                }
            }
        },
        "jobs_customer_claims" :{
            route  :  "/jobs/",
            getFilter : () => {

                return {
                    filter : {
                        where: {
                            status : {
                                label    :
                                    Job.Helpers.Status.arrToLabelStr(
                                        { jobStatuses : ["waiting"]}
                                    ),
                                column   : "STATUS",
                                operator : "IN",
                                value    : ["waiting"],
                            },
                            type : {
                                label    :
                                    JobHelpers.NameByType.localized({ jobTypes: ["all_claims"] }),
                                column   : "TYPE",
                                operator : "IN",
                                value    : ["claims"],
                            }
                        },
                        activeFilters : 2
                    }
                }
            }
        },
        "jobs_last_min":{
            route  :  "/jobs/",
            getFilter : () => {

                return {
                    filter : {
                        where: {
                            status : {
                                label    :
                                    Job.Helpers.Status.arrToLabelStr(
                                        { jobStatuses : ["waiting"]}
                                    ),
                                column   : "STATUS",
                                operator : "IN",
                                value    : ["waiting"],
                            },
                            type : {
                                label    :
                                    JobHelpers.NameByType.localized({ jobTypes: ["last_minutes"] }),
                                column   : "TYPE",
                                operator : "IN",
                                value    : ["last_minutes"],
                            }
                        },
                        activeFilters : 2
                    }
                }
            }
        },
        "mp_control" : {
            route  : "/stands/",
            getFilter : () => {

                return {
                    filter : {
                        whereCrafts: {
                            craftStatus: {
                                label: localizeIt("CRAFT.Status_Text_InProgress"),
                                column: "STATUS",
                                operator: "EQ",
                                value: "in_progress"
                            },
                            craftType :  {
                                label    :
                                    localizeIt(
                                        "JOB.Filter_Params_CraftType",
                                        { withFilter : CraftHelpers.Types.list( "mp_control" )}
                                    ),
                                column   : "TYPE",
                                operator : "EQ",
                                value    : "mp_control",
                            }
                        }
                    }
                }
            }
        },
        "jobs_damages" : {
            route  :  "/more/view-damages/",
            getFilter : () => {

                return {
                    filter : {
                        where: {
                            type : {
                                label    :
                                    localizeIt(
                                        "JOB.Filter_Params_JobType",
                                        { withFilter : localizeIt(Job.Helpers.NameByType.single({ jobType: "damages"})) }
                                    ),
                                column   : "TYPE",
                                operator : "IN",
                                value    : ["damages"],
                            }
                        }
                    }
                }
            }
        },
        "protocol" : {
            route  :  "/jobs/",
            getFilter : () => {

                return {
                    filter : {
                        where: {
                            status : {
                                label    :
                                    Job.Helpers.Status.arrToLabelStr(
                                        { jobStatuses : ["waiting"]}
                                    ),
                                column   : "STATUS",
                                operator : "IN",
                                value    : ["waiting"],
                            },
                            type : {
                                label    :
                                    localizeIt(
                                        "JOB.Filter_Params_JobType",
                                        { withFilter : localizeIt(Job.Helpers.NameByType.single({ jobType: "protocol_defects"})) }
                                    ),
                                column   : "TYPE",
                                operator : "IN",
                                value    : ["protocol_defects"],
                            }
                        },
                        activeFilters : 2
                    }
                }
            }
        }
    }

    return { ...filters[type] };
}


const ExhibitionStatisticHelper = {
    sort       : sortStatistic,
    iconByType : statisticIconByType
}

export default ExhibitionStatisticHelper;