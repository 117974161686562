import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { Button, Form } from "antd";
import { SyncOutlined } from "@ant-design/icons";

import { STAND_PHOTO_UPLOAD } from "graphql/mutation/stand-gql";

import { PreUploadImage, Localize } from "components/service";
import { Icons } from "components/layout";
import { useNotification } from "components/use-hooks";
import { NoData } from "components/request-result";


const StandPhotoForm = ({ id, standPhoto, callback }) => {

    const { nError, nSuccess } = useNotification(),
          [ fileSelected, setFileSelected ] = useState(false);

    const [ standPhotoAction, { loading }] = useMutation( STAND_PHOTO_UPLOAD,
        {
            update(cache, { data }) {

                const {
                    fileUpload : {
                        label,
                        message,
                        model
                    }
                } = data;

                cache.modify({
                    id: cache.identify(model),
                    fields: {
                        file_photo() {
                            return !model.file_photo ? null : model.file_photo + "?t=" + (+ new Date());
                        },
                    },
                });

                setFileSelected(false);

                nSuccess( label, message );
            }
        }
    );

    const [ form ] = Form.useForm();

    return(
        <Form
            layout="vertical"
            className= {`stand-photo-form`}
            form={ form }
            onValuesChange={ (changedValues) => {
                if(changedValues.file){
                    setFileSelected(true)
                }
            }}
            onFinish={ ({ file }) => {

                standPhotoAction({
                    variables: {
                        input: {
                            model: "Stand",
                            model_id: id,
                            model_field: "file_photo",
                            file : file?.originFileObj
                        }
                    }
                }).catch((error) => nError( error ))

                return false;
            } }
        >
            <Form.Item
                name="file"
                valuePropName="file"
                getValueFromEvent={ (e) => {
                    if (Array.isArray(e)) {
                        return e;
                    }

                    return e?.file;
                }}
            >
                <PreUploadImage
                    photo={ standPhoto }
                    noData = {
                        <NoData
                            className="stand-photo"
                            icon="stand"
                            text={{
                                before: <Localize br={ <br /> }>NO_DATA.StandPhoto</Localize>
                            }}
                        />
                    }
                    onDelete={ (callback) => {

                        if(!standPhoto) {
                            callback();
                            return false;
                        }

                        standPhotoAction({
                            variables: {
                                input: {
                                    model: "Stand",
                                    model_id: id,
                                    model_field: "file_photo",
                                    file : null
                                }

                            }
                        }).then( callback )
                          .catch((error) => nError( error ))

                    }}
                    extraAction = {
                        fileSelected ?
                            <Button
                                type = "primary"
                                htmlType="submit"
                                loading={ loading }
                            >
                                <Icons.Control />
                                <Localize wrap>STAND.Modal_Button_SavePhoto</Localize>
                            </Button> :
                            <Button
                                type = "default"
                                onClick = { callback }
                            >
                                <Localize wrap>GLOBAL.Button_Text_Cancel</Localize>
                            </Button>
                    }
                >
                    <Button
                        type = "primary"
                        className = { "add-photo" }
                    >
                        <Icons.Photo />
                        <Localize wrap>STAND.Modal_Button_AddPhoto</Localize>
                    </Button>
                    <Button type="default" className="icon" >
                        <SyncOutlined />
                    </Button>
                </PreUploadImage>
            </Form.Item>
        </Form>
    )
}

export default StandPhotoForm;