import React from 'react';
import Icon from '@ant-design/icons';


const SortSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M12.5,21.1L23.7,9.5l11.2,11.5c0.3,0.3,0.7,0.5,1.1,0.5c0.4,0,0.8-0.1,1-0.4c0.6-0.6,0.6-1.5,0-2.1L24.8,6.3
		c-0.6-0.6-1.6-0.6-2.2,0L10.3,19c-0.6,0.6-0.6,1.5,0,2.1C11,21.7,11.9,21.7,12.5,21.1z"/>
        <path d="M34.9,28.1L23.7,39.6L12.5,28.1c-0.6-0.6-1.5-0.6-2.1,0c-0.6,0.6-0.6,1.5,0,2.1l12.3,12.7c0.3,0.3,0.7,0.5,1.1,0.5
		s0.8-0.2,1.1-0.5L37,30.2c0.6-0.6,0.6-1.5,0-2.1C36.4,27.5,35.4,27.5,34.9,28.1z"/>
    </svg>
);

const SortIcon = props => {
    return <Icon component={ SortSvg } className={ props.className } />;

};


export default SortIcon;