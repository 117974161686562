import React from "react";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";


const FurnishingAddButton = ({ selected = [], setSelected,...currentItem}) => {

    return (
        <Button
            type="primary"
            className="icon round"
            disabled = { !currentItem.qty }
            onClick = {
                () => {

                    const existed = selected.find(
                        ({ furnishingId, type }) => furnishingId === currentItem.furnishingId && type !== "deleted"
                    );

                    if( existed ){
                        setSelected(
                            selected.map(
                                (item) => {

                                    if(item.furnishingId === existed.furnishingId){
                                        return( {
                                            ...item,
                                            type: "order",
                                            qty :  item.qty + 1,
                                        } )
                                    }

                                    return item
                                }
                            )
                        )
                    } else {
                        setSelected( [...selected,  { ...currentItem, qty: 1  }])
                    }

                }
            }
        >
            <PlusOutlined />
        </Button>
    )
}


export default FurnishingAddButton;