import JobEditModal from "./job-edit-modal";
import JobFilterModal from "./job-filter-modal";
import JobSortModal from "./job-sort-modal";
import JobPhotoModal from "./job-photo-modal";

import "./job-modals.scss";


const JobModals = {
    Edit   : JobEditModal,
    Filter : JobFilterModal,
    Sort   : JobSortModal,
    Photo  : JobPhotoModal
}

export default JobModals;