import React from 'react';
import { useMutation } from "@apollo/client";
import { Form, Input, Button } from 'antd';

import { USER_LOGIN } from "graphql/mutation/user-gql";
import { IS_LOGGED_IN } from "graphql/query/local-store-gql";

import { useAuthRedirect } from "components/use-hooks";
import { errorNotification } from 'components/request-result';
import { Icons } from "components/layout";
import Localize, { localizeIt } from "components/service/localize";


const SignInForm = () => {

    const [ form ] = Form.useForm();
    const authRedirect = useAuthRedirect({ user : false });

    const [ loginRequest, { loading } ] = useMutation(
        USER_LOGIN,
        {
            update(cache, { data: { userLogin } }) {

                const { access_token, user } = userLogin;

                localStorage.setItem("authToken", access_token);
                cache.writeQuery({
                    query: IS_LOGGED_IN,
                    data: {
                        isLoggedIn: access_token,
                    },
                });

                authRedirect( { user } );
            },
            onError(error) {
                errorNotification(error);
            }
        }
    );

    return (
        <div className="sign-in-form">

            <div className="logo-holder">
                <div className="logo">
                    <Icons.Logo />
                    <b className='logo-text'>ELFE</b>
                </div>
            </div>
            <Form
                form={ form }
                layout="vertical"
                onFinish={ (values) => {
                    loginRequest({
                        variables: {
                            data: {
                                username: values.username,
                                password: values.password,
                            }
                        },
                    }).catch((error) => {
                        errorNotification(error);
                    });
                } }
            >
                <Form.Item
                    label={ <Localize>FORMS.Input_Label_Login</Localize> }
                    name="username"
                    rules={ [
                        {
                            required: true,
                            message: localizeIt("FORM_RULES.Required_Login")
                        }
                    ] }
                >
                    <Input name="username" />
                </Form.Item>
                <Form.Item
                    label={ <Localize>FORMS.Input_Label_Password</Localize> }
                    name="password"
                    rules={ [
                        {
                            required: true,
                            message: localizeIt("FORM_RULES.Required_Password")
                        }
                    ] }
                >
                    <Input.Password
                        name="password"
                    />
                </Form.Item>
                <Form.Item>
                    <div className="form-actions">
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={ loading }
                        >
                            <Localize wrap>FORMS.Button_Text_Login</Localize>
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </div>

    );
};


export default SignInForm;