import React from 'react';
import Icon from '@ant-design/icons';

import { Loader } from "components/request-result";


const CheckSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M42.1,12.2c-0.8-0.8-2-0.8-2.8,0L19.2,32.3l-7.9-7.9c-0.8-0.8-2-0.8-2.8,0c-0.8,0.8-0.8,2,0,2.8l9.3,9.3
	c0.4,0.4,0.9,0.6,1.4,0.6s1-0.2,1.4-0.6l21.5-21.5C42.9,14.3,42.9,13,42.1,12.2z"/>
    </svg>
);

const DoubleCheckSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M35.1,12.2c-0.8-0.8-2-0.8-2.8,0L12.1,32.3l-7.9-7.9c-0.8-0.8-2-0.8-2.8,0c-0.8,0.8-0.8,2,0,2.8l9.3,9.3
		c0.4,0.4,0.9,0.6,1.4,0.6s1-0.2,1.4-0.6l21.5-21.5C35.8,14.3,35.8,13,35.1,12.2z"/>
        <path d="M47.1,12.2c-0.8-0.8-2-0.8-2.8,0L22.8,33.8c-0.8,0.8-0.8,2,0,2.8c0.4,0.4,0.9,0.6,1.4,0.6s1-0.2,1.4-0.6l21.5-21.5
		C47.9,14.3,47.9,13,47.1,12.2z"/>
    </svg>
);

const CircleCheckSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path className="st0" d="M41.7,12.7c-0.8-0.8-2-0.8-2.8,0L25.6,26.1l-4.9-4.9c-0.8-0.8-2-0.8-2.8,0c-0.8,0.8-0.8,2,0,2.8l6.3,6.3
		c0.4,0.4,0.9,0.6,1.4,0.6s1-0.2,1.4-0.6l14.7-14.7C42.5,14.8,42.5,13.5,41.7,12.7z"/>
        <path className="st0" d="M39.5,22.6c-1,0-1.8,0.8-1.8,1.8c0,7.6-6.2,13.7-13.7,13.7c-7.6,0-13.7-6.2-13.7-13.7
		c0-7.6,6.2-13.7,13.7-13.7c3.2,0,6.3,1.1,8.7,3.1c0.8,0.6,1.9,0.5,2.5-0.2c0.6-0.8,0.5-1.9-0.2-2.5c-3.1-2.6-7-4-11-4
		C14.4,7,6.6,14.8,6.6,24.4s7.8,17.3,17.3,17.3c9.6,0,17.3-7.8,17.3-17.3C41.3,23.4,40.5,22.6,39.5,22.6z"/>
    </svg>
);

const CircleDoubleCheckSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M14.4,21.2c-0.8-0.8-2-0.8-2.8,0c-0.8,0.8-0.8,2,0,2.8l6.3,6.3c0.4,0.4,0.9,0.6,1.4,0.6s1-0.2,1.4-0.6l14.7-14.7
		c0.8-0.8,0.8-2,0-2.8c-0.8-0.8-2-0.8-2.8,0L19.3,26.1L14.4,21.2z"/>
        <path d="M45.2,12.7c-0.8-0.8-2-0.8-2.8,0L27.6,27.5c-0.8,0.8-0.8,2,0,2.8c0.4,0.4,0.9,0.6,1.4,0.6s1-0.2,1.4-0.6l14.7-14.7
		C45.9,14.8,45.9,13.5,45.2,12.7z"/>
        <path d="M33.9,31.7c-0.9-0.6-2.2-0.4-2.8,0.5c-2.6,3.7-6.8,6-11.3,6c-7.6,0-13.7-6.2-13.7-13.7c0-7.6,6.2-13.7,13.7-13.7
		c2.7,0,5.2,0.8,7.5,2.2c0.9,0.6,2.2,0.3,2.8-0.6s0.3-2.2-0.6-2.8c-2.9-1.9-6.2-2.8-9.6-2.8c-9.8,0-17.7,8-17.7,17.7
		c0,9.8,8,17.7,17.7,17.7c5.8,0,11.3-2.9,14.6-7.7C35.1,33.5,34.9,32.3,33.9,31.7z"/>
    </svg>
);

const CheckIcon = props => {

    let icon = "";

    if(props.loading){
        return <Loader />;
    }


    switch (props.type){
        case "double"           : icon = DoubleCheckSvg; break;
        case "circle"           : icon = CircleCheckSvg; break;
        case "doubleWithCircle" : icon = CircleDoubleCheckSvg; break;
        default                 : icon = CheckSvg;
    }

    return <Icon component={ icon } className={ props.className } />;
};


export default CheckIcon;