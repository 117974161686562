import React from 'react';
import Icon from '@ant-design/icons';
import {Loader} from "../../request-result";


const DeleteSvg = () => (

	<svg fill="currentColor" width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1">
		<g>
			<path d="M37.4,8.8h-6.8c-0.5-2.9-3.1-5.1-6.2-5.1s-5.6,2.2-6.2,5.1h-6.8c-1,0-1.8,0.8-1.8,1.8v4.3c0,1,0.8,1.8,1.8,1.8h0.2v24.8
		c0,1.4,1.2,2.6,2.6,2.6h20.2c1.4,0,2.6-1.2,2.6-2.6V16.8h0.2c1,0,1.8-0.8,1.8-1.8v-4.3C39.2,9.6,38.4,8.8,37.4,8.8z M24.4,6.1
		c1.7,0,3.1,1.1,3.6,2.6h-7.2C21.3,7.3,22.8,6.1,24.4,6.1z M12.2,11.3h24.5v3H12.2V11.3z M34.7,41.5c0,0.1-0.1,0.1-0.1,0.1H14.3
		c-0.1,0-0.1-0.1-0.1-0.1V16.8h20.5V41.5z"/>
			<path d="M19.1,37.9c0.7,0,1.2-0.6,1.2-1.2V22.8c0-0.7-0.6-1.2-1.2-1.2s-1.2,0.6-1.2,1.2v13.8C17.9,37.3,18.4,37.9,19.1,37.9z" />
			<path d="M24.4,37.9c0.7,0,1.2-0.6,1.2-1.2V22.8c0-0.7-0.6-1.2-1.2-1.2s-1.2,0.6-1.2,1.2v13.8C23.2,37.3,23.7,37.9,24.4,37.9z" />
			<path d="M29.7,37.9c0.7,0,1.2-0.6,1.2-1.2V22.8c0-0.7-0.6-1.2-1.2-1.2s-1.2,0.6-1.2,1.2v13.8C28.5,37.3,29,37.9,29.7,37.9z" />
		</g>
	</svg>
);

const DeleteIcon = (props) => {
	if(props.loading){
		return <Loader />;
	}

	return <Icon component={ DeleteSvg } />;
};


export default DeleteIcon;