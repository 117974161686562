import React from "react";
import { Button } from "antd";

import { Localize } from "components/service";


const FurnishingChangeTabButton = ({ tabKey, setTabKey, buttonsArr = [] }) => {

    return buttonsArr.map(
        (key) => {

            const translationKey = key.charAt(0).toUpperCase() + key.slice(1);

            return(
                <Button
                    key={ key }
                    type={ tabKey === key ? "primary" : "ghost" }
                    onClick = { () => setTabKey(key) }
                >
                    <Localize wrap>{ `FURNISHING.TabName_${ translationKey }` }</Localize>
                </Button>
            )
        }
    )

}


export default FurnishingChangeTabButton;