import React from 'react';
import Icon from '@ant-design/icons';


const MangelSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M24.3,5.9c-10,0-18.1,8.1-18.1,18.1c0,10,8.1,18.1,18.1,18.1c10,0,18.1-8.1,18.1-18.1C42.4,14,34.2,5.9,24.3,5.9z
		 M24.3,38.2c-7.9,0-14.3-6.4-14.3-14.3c0-7.9,6.4-14.3,14.3-14.3c7.9,0,14.3,6.4,14.3,14.3C38.6,31.8,32.2,38.2,24.3,38.2z"/>
        <path d="M31.8,16.4c-0.7-0.7-1.9-0.7-2.7,0l-4.8,4.8l-4.8-4.8c-0.7-0.7-1.9-0.7-2.7,0c-0.7,0.7-0.7,1.9,0,2.7l4.8,4.8l-4.8,4.8
		c-0.7,0.7-0.7,1.9,0,2.7c0.4,0.4,0.9,0.6,1.3,0.6c0.5,0,1-0.2,1.3-0.6l4.8-4.8l4.8,4.8c0.4,0.4,0.9,0.6,1.3,0.6
		c0.5,0,1-0.2,1.3-0.6c0.7-0.7,0.7-1.9,0-2.7L27,23.9l4.8-4.8C32.5,18.4,32.5,17.2,31.8,16.4z"/>
    </svg>
);

const MangelIcon = props => {
    return <Icon component={ MangelSvg } className={ props.className } />;

};


export default MangelIcon;