import React, { useEffect } from "react";
import { mergeDeep } from "@apollo/client/utilities";
import { useLocation } from "react-router-dom";

import Stand from "components/stand";
import { Wraps } from "components/layout";
import { useVarParam } from "components/use-hooks";
import { localizeIt } from "components/service/localize";


const StandsPage = () => {

    const appParams = useVarParam();
    const { state : {
        queryParams
    } = {} } = useLocation();

    const { filter, sort } = Stand.Hooks.useFilter(
        queryParams ?
            queryParams : appParams.get("rememberQueryParams").stand,
    );

    useEffect( () => {
        if(filter && queryParams){
            filter.set(mergeDeep(filter.data,  queryParams.filter));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParams])

    appParams.set({
        headerTitle: localizeIt("PAGE.Title_Stands"),
        bottomBar: true
    });

    return (
        <Wraps.Container className="page stands-page">
            <Stand.Blocks.Filter
                filter = { filter }
                sort = { sort }
            />
            <Stand.Grids.All
                filter = { filter.data }
                sort = { sort.data }
            />
        </Wraps.Container>
    );

}

export default StandsPage;