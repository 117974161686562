import FurnishingCustomList, { FurnishingCustomListItem } from "./furnishing-custom-list";
import FurnishingList from "./furnishing-list";

import "./furnishing-lists.scss";


const FurnishingLists = {
    Single : FurnishingCustomListItem,
    Simple : FurnishingList,
    Custom : FurnishingCustomList
}

export default FurnishingLists;