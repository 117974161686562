import React from "react";
import { useQuery } from "@apollo/client";
import { Select } from "antd";

import { GET_SUBCONTRACTORS } from "graphql/query/subcontractor-gql";


import { Icons } from "components/layout";
import { useMe } from "components/use-hooks";
import { Localize } from "components/service";


const { Option } = Select;

const ExhibitionSubcontractorSelectField = ({ ...props }) => {

    const { me } = useMe();
    const { data, loading } = useQuery(GET_SUBCONTRACTORS, {
        variables: {
            eeId : me.eeCurrentId
        },
        fetchPolicy: 'cache-first'
    });

    const {
        subcontractorsCursor : {
            edges : subcontractors = []
        } = {}
    } = data ? data : {};

    return(
        <Select
            className={ `exhibition-subcontractor-select-field` }
            suffixIcon={
                <Icons.Arrow type="filled"/>
            }
            loading={ loading }
            defaultValue={ null }
            { ...props }
        >
            <Option value={ null }>
                <Localize>FORMS.Select_DefaultValue_All</Localize>
            </Option>
            { subcontractors.map(
                ( { node : { id, title } } ) =>
                    <Option
                        key={ id }
                        value={ `${ id }:${ title }` }>
                        { title }
                    </Option>
            )}
        </Select>
    );
}

export default ExhibitionSubcontractorSelectField;