import ExhibitionSelectionList from "./exhibition-selection-list";
import ExhibitionDocumentList from "./exhibition-document-list";

import "./exhibition-lists.scss";


const ExhibitionLists = {
    Selection : ExhibitionSelectionList,
    Document  : ExhibitionDocumentList
}

export default ExhibitionLists;