import React, { useState, useEffect } from "react";
import { Modal } from "antd";

import Icons from "components/layout/icons";
import { cloneChildren } from "utils";


const ModalStandard = ({
        modalButton,
        extraClass = "",
        children,
        modalVisible = false,
        ...props
    }) => {

    const [ showModal, setShowModal ] = useState(modalVisible);

    useEffect( () => {
        setShowModal( !!modalVisible )
    }, [ modalVisible ]);

    return(
        <>
            { modalButton &&
                React.cloneElement( modalButton, { onClick : () =>  setShowModal(true)})
            }
            <Modal
                className={ `modal-standard ${ extraClass }` }
                visible={ showModal }
                centered
                width={ "auto" }
                closeIcon={ <Icons.Cross /> }
                destroyOnClose={ true }
                footer={ null }
                onOk={() => setShowModal(false)}
                onCancel={() => {
                    setShowModal(false)
                }}
                {...props }
            >
                { cloneChildren(
                    children,
                    { callback : () =>  setShowModal(false)}
                )}
            </Modal>
        </>
    );
}

export default ModalStandard;

