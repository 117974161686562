import React from "react";
import { VirtuosoGrid  } from 'react-virtuoso';

import { fetchQueryMore, cloneChildren } from "utils";


const OptimizedGrid = ({
        useWindowScroll = true,
        gridItems,
        perPage,
        pageInfo,
        model,
        fetchMore,
        listClass,
        itemClass,
        ...props
    }) => {

    return(
        <VirtuosoGrid
            useWindowScroll = { useWindowScroll }
            totalCount={ gridItems.length ?? perPage }
            className={ "virtuoso-scroller" }
            overscan={ 100 }
            endReached={
                () => fetchQueryMore(
                    model,
                    fetchMore,
                    pageInfo,
                    gridItems
                )
            }
            itemClassName={ itemClass }
            listClassName={ listClass }
            components={{
                Item: React.forwardRef((props, ref) => {
                    return <div {...props} ref={ref} />
                }),
                List: React.forwardRef((props, ref) => {
                    return <div {...props} ref={ref}  />
                }),
            }}
            itemContent={(index) => {

                if(index >= gridItems.length) return null;
                const { node } = gridItems[index];

                return cloneChildren( props.children, { ...node, key: node.id });
            }}
        />
    );
}

export default OptimizedGrid;