import React from "react";

import { SortModalStandard, SortListStandard } from "components/service";


const StandSortModal = ({ sort }) => {

    const sortValues = [
        {
            label : "SORT.Text_NewestByCreation",
            value : [{ column: "ID", order : "DESC" }]
        },
        {
            label : "SORT.Text_OldestByCreation",
            value : [{ column: "ID", order : "ASC" }]
        },
        {
            label : "SORT.Text_ExhibitorNameAZ",
            value : [{ column: "EXHIBITOR", order : "ASC" }]
        },
        {
            label : "SORT.Text_ExhibitorNameZA",
            value : [{ column: "EXHIBITOR", order : "DESC" }]
        },
        {
            label : "SORT.Text_StandNumberAZ",
            value : [{ column: "CORRIDOR", order : "ASC" }, { column: "STAND_NUMBER", order : "ASC" }]
        },
        {
            label : "SORT.Text_StandNumberZA",
            value : [{ column: "CORRIDOR", order : "DESC" }, { column: "STAND_NUMBER", order : "DESC" }]
        },
        {
            label : "SORT.Text_BlockAZ",
            value : [{ column: "BLOCK", order : "ASC" }, { column: "STAND_NUMBER", order : "ASC" }]
        },
        {
            label : "SORT.Text_BlockZA",
            value : [{ column: "BLOCK", order : "DESC" }, { column: "STAND_NUMBER", order : "DESC" }]
        }
    ];

    return(
        <SortModalStandard
            wrapClass="stand-sort-modal-wrap"
            modalClass="stand-sort-modal"
        >
            <SortListStandard
                sortValues={ sortValues }
                setQuerySort={ sort.set }
                defaultSort={ sort.data }
            />
        </SortModalStandard>
    );
};

export default StandSortModal;