import DamageBlocks from "./blocks";
import DamageGrids from "./grids";
import DamageHelpers from "./helpers";
import DamageModals from "./modals";
import DamageButtons from "./buttons";
import DamageHooks from "./hooks";


const Damage = {
    Blocks  : DamageBlocks,
    Grids   : DamageGrids,
    Helpers : DamageHelpers,
    Modals  : DamageModals,
    Buttons : DamageButtons,
    Hooks   : DamageHooks
}

export default Damage;