import React from "react";
import { Link, useLocation } from "react-router-dom";

import HallHelpers from "../../helpers";
import { UserHelpers } from "components/user";
import { Icons } from "components/layout";


const { permission } = UserHelpers;

const CraftOverviewGridItem = ({ id, title : hallName, statistic }) => {

    const location = useLocation();

    return(
        <div className="hall-overview-grid-item">
            <h3>
                <Link
                    to={{
                        pathname :`/more/expo-plan/${id}` ,
                        state    : { from : location.pathname }
                    }}
                >
                    { hallName } <Icons.Arrow />
                </Link>

            </h3>
            <div className="overview-statistic bg-light-bordered">
                { HallHelpers.OverviewCountTypes.map(
                    ( { label, type, route, getFilter = () => {}, getColor = () => {} } ) => {

                        const Wrap = ({ children, className = "" }) => {

                            const routeExist = route.includes("jobs") ? permission().has({type: "craft_job", value: "view_job"}) : true;

                            if(statistic[type] > 0 && route && routeExist){
                                return(
                                    <Link
                                        className= { `overview-statistic-item ${ className }` }
                                        to={{
                                            pathname: route,
                                            state: {
                                                from : location.pathname,
                                                queryParams : getFilter({ hallName, hallId: id })
                                            }
                                        }}
                                    >
                                        { children }
                                    </Link>
                                )
                            }

                            return (
                                <div className = { `overview-statistic-item ${ className }` } >
                                    { children }
                                </div>
                            )
                        }


                        return(
                            <Wrap
                                key       = { type }
                                className = { getColor( statistic[type] ) }
                            >
                                <h4>{ statistic[type] }</h4>
                                <p>{ label }</p>
                            </Wrap>
                        )
                    }) }
            </div>

        </div>
    )
};

export default CraftOverviewGridItem;