import React from "react";
import { useMutation } from "@apollo/client";
import { Button } from "antd";

import { DAMAGE_FILE_UPDATE } from "graphql/mutation/damages-gql";

import { useNotification } from "components/use-hooks";
import { Icons } from "components/layout";


const DamageUpdateDescriptionBtn = ({ id, description, disabled }) => {

    const { nError, nSuccess } = useNotification();

    const [ damageFileUpdate, { loading }] = useMutation( DAMAGE_FILE_UPDATE,
        {
            update(cache, { data }) {

                const {
                    damageFileUpdate : {
                        label,
                        message
                    }
                } = data;

                nSuccess( label, message );
            }
        }
    );

    return(
        <Button
            className = "round icon"
            type      = "primary"
            disabled  = { disabled }
            onClick   = { () => {
                if(!loading){

                    damageFileUpdate({
                        variables: {
                            input : {
                                id, description
                            }
                        }
                    }).catch( nError )
                }
            }}
        >
            <Icons.Arrow type="full" loading={ loading } />
        </Button>
    )

};

export default DamageUpdateDescriptionBtn;