import React from "react";
import { useLocation, Redirect, useHistory } from "react-router-dom";
import { Button } from "antd";

import cache from "graphql/cache";

import Exhibition from "components/exhibition";
import { useMe, useLogout, useVarParam } from "components/use-hooks";
import { Icons, Wraps } from "components/layout";
import Localize, { localizeIt } from "components/service/localize";
import { checkUser } from "utils";


const ExhibitionsPage = () => {

    const { me } = useMe(),
          appParams = useVarParam(),
          location = useLocation(),
          { logout } = useLogout(),
          history = useHistory(),
          { hasActiveEvent, hasEvents } = checkUser;

    appParams.set({
        headerTitle : localizeIt("PAGE.Title_Overview"),
        bottomBar   : hasActiveEvent(me),
        logoOn      : !hasActiveEvent(me),
        searchOn    : hasActiveEvent(me)
    });

    if( !hasActiveEvent(me) && location.pathname !== "/exhibitions" ){
        return <Redirect to="/exhibitions" />;
    }

    return(
        <Wraps.Container className="page exhibitions-page">
            { hasEvents( me.exhibitionEvent ) ?
                <div className="exhibitions-list-wrap">
                    <Localize
                        wrap="div"
                        wrapClass="instruction"
                        br={ <br /> }
                    >
                        EXHIBITION.Select_Instruction
                    </Localize>

                    <Exhibition.Lists.Selection
                        ee={ me.exhibitionEvent }
                        me={ me }
                        action={
                            (value) => {
                                localStorage.setItem("eeCurrentId", value);
                                cache.modify({
                                    id: cache.identify(me),
                                    fields: {
                                        last_visit_at() {
                                            return `${ value + Math.floor(Math.random() * (1000 - 10 + 1)) + 10 } second ago`;
                                        }
                                    }
                                });

                                setTimeout(() => history.replace("/"), 100);
                            }
                        }
                    />
                </div> :
                <div className="no-exhibitions">
                    <Localize wrap="p" >NO_DATA.Exhibitions</Localize>
                    <Icons.MagnifyingGlass />
                </div>
            }
            { !hasActiveEvent(me) &&
                <div className="action-wrap">
                    <Button
                        type="primary"
                        onClick={ () => logout({ redirectRoute: "/" }) }
                    >
                        <Icons.Logout />
                        <Localize wrap>GLOBAL.Button_Text_Logout</Localize>
                    </Button>
                </div>
            }
        </Wraps.Container>
    )
};

export default ExhibitionsPage;