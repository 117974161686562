import { useVarParam } from "components/use-hooks";
import translation from "mock-data/translation";


const useTranslation = () => {

    const appParams = useVarParam(),
          me = appParams.get("me");

    const defaultLang = "en";

    if(me){
        document.documentElement.setAttribute("lang", me.language_slug);
        return { ...translation[ defaultLang ], ...translation[ me.language_slug ] };
    }

    return { ...translation[ defaultLang ] }
}

export default useTranslation;