import React from 'react';
import Icon from '@ant-design/icons';


const StandsSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M21.4,23.3h-15c-0.7,0-1.2-0.6-1.2-1.2V8.9c0-0.7,0.6-1.3,1.2-1.3h15c0.7,0,1.2,0.6,1.2,1.3v13.2
	C22.6,22.8,22.1,23.3,21.4,23.3z M7.6,20.8h12.5V10.2H7.6V20.8z"/>
        <path d="M41.2,23.3h-15c-0.7,0-1.2-0.6-1.2-1.2V8.9c0-0.7,0.6-1.3,1.2-1.3h15c0.7,0,1.2,0.6,1.2,1.3v13.2
	C42.5,22.8,41.9,23.3,41.2,23.3z M27.5,20.8H40V10.2H27.5V20.8z"/>
        <path d="M21.4,41.6h-15c-0.7,0-1.2-0.6-1.2-1.2V27.2c0-0.7,0.6-1.2,1.2-1.2h15c0.7,0,1.2,0.6,1.2,1.2v13.2
	C22.6,41.1,22.1,41.6,21.4,41.6z M7.6,39.1h12.5V28.5H7.6V39.1z"/>
        <path d="M41.2,41.6h-15c-0.7,0-1.2-0.6-1.2-1.2V27.2c0-0.7,0.6-1.2,1.2-1.2h15c0.7,0,1.2,0.6,1.2,1.2v13.2
	C42.5,41.1,41.9,41.6,41.2,41.6z M27.5,39.1H40V28.5H27.5V39.1z"/>
    </svg>
);

const StandsIcon = props => {
    return <Icon component={ StandsSvg } className={ props.className } />;

};


export default StandsIcon;