import React from "react";

import { Icons } from "components/layout";


const ExhibitionDocumentItem = ({ title, description, path, original_name, extension }) => {


    return(
        <div className="exhibition-document-item">
            <a target="_blank"  rel="noreferrer" href={ path } className="bg-light-bordered">
                <Icons.Document type={ extension } className="document-type" />
                <div className="info">
                    <h4>{ title ? title : original_name }</h4>
                    { description && <p>{ description }</p> }
                </div>
                <Icons.Eye/>
            </a>
        </div>
    );
}


export default ExhibitionDocumentItem;