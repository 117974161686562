import React from "react";

import { Icons } from "components/layout";


const StandsSimpleGridItem = React.memo(({
        id,
        exhibitor,
        hall_name : hallName,
        block,
        stand_number : standNum,
        corridor,
        action
    }) => {

    const Wrap = ({ children }) => {
        if (action){
            return(
                <button onClick={ () => action({ id, exhibitor }) }>{ children }</button>
            )
        }

        return <>{ children }</>
    }


    return(
        <div className="stands-simple-grid-item bg-light-bordered">
            <Wrap>
                <div>
                    <h4>{ exhibitor }</h4>
                    <p className="info">
                        <span>{ hallName }</span>
                        { block &&
                            <span>{ block }</span>
                        }
                        { (corridor || standNum) &&
                            <span>{ `${ corridor ? corridor : "" }${ standNum }` } </span>
                        }
                    </p>
                </div>
                <Icons.Arrow/>
            </Wrap>
        </div>
    );
});

export default StandsSimpleGridItem;