import { gql } from '@apollo/client';


export const GET_JOBS = gql`
    query CraftJobsCursor(
        $text        : String
        $eeId        : ID
        $where       : QueryCraftJobsCursorWhereWhereConditions
        $whereCrafts : QueryCraftJobsCursorWhereCraftsWhereHasConditions
        $orderBy     : [QueryCraftJobsCursorOrderByRelationOrderByClause!]
        $first       : Int
        $after   : String
    ) {
        craftJobsCursor(
            text        : $text
            ee_id       : $eeId
            where       : $where
            whereCrafts : $whereCrafts
            orderBy     : $orderBy
            first       : $first
            after       : $after
        ) {
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
                total
                currentPage
                lastPage
            }
            edges {
                node{
                    id
                    number
                    flag
                    type
                    status
                    description
                    attachment
                    completed_at
                    completor {
                        id
                        name
                        surname
                    }
                    taken_at
                    taken {
                        id
                        name
                        surname
                    }
                    created_at
                    creator {
                        id
                        name
                        surname
                    }
                    stand {
                        id
                        hall_name
                        exhibitor
                        aquise_number
                        proauf_number
                        block
                        corridor
                        stand_number
                        delivery_time_at
                        file_stand_plan
                        file_info
                        file_graphic
                        file_photo
                    }
                    furnishingOrder{
                        id
                        qty
                        furnishing_id
                        type
                        ee_id
                        furnishing {
                            id
                            title
                            sku
                            qty
                            price
                            ee_id
                            image
                            created_at
                        }
                        created_at
                    }
                    craft {
                        id
                        title
                        type
                        ee_id
                        subcontractor {
                            id
                            title
                        }
                    }
                    updated_at
                }
            }
        }
    }
`;
