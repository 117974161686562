import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { GET_DAMAGE_REPORTS } from "graphql/query/damages-gql";

import DamageGridItem from "./damage-grid-item";
import { Loader, NoData } from "components/request-result";
import { useMe } from "components/use-hooks";
import { Localize, OptimizedGrid } from "components/service";
import { restorePosition, whereConditionBuilder } from "utils";


const DamageGrid = ({ sort = [], filter}) => {

    const { state = {}, pathname } = useLocation(),
          { me } = useMe(),
          history = useHistory();

    const perPage = 30;

    const { data, loading, fetchMore } = useQuery( GET_DAMAGE_REPORTS, {
        variables: {
            where: whereConditionBuilder(filter.where),
            orderBy: sort.length ? [ ...sort ] : [{ column: "CREATED_AT", order : "DESC" }],
            first: perPage,
        },
        fetchPolicy: state.pagePosition ? "cache-first" : "cache-and-network",
        notifyOnNetworkStatusChange: false,

    });

    const {
        damageReportsCursor : {
            edges : damages = [],
            pageInfo = {}
        } = {}
    } = data ? data : {};

    if( data && state.pagePosition ){
        restorePosition(history, pathname, state);
    }

    return(
        <>
            { !damages.length && !loading &&
                <NoData
                    text={{
                        after :  <Localize br={ <br /> }>NO_DATA.Damages</Localize>
                    }}
                    icon="job"
                />
            }
            { !!damages.length &&
                <OptimizedGrid
                    listClass = "damage-grid"
                    itemClass = "damage-grid-item-wrap"
                    gridItems = { damages.filter( ({ node }) =>  node.status !== "delete" ) }
                    pageInfo  = { pageInfo }
                    fetchMore = { fetchMore }
                    perPage   = { perPage }
                    model     = "damageReportsCursor"
                >
                    <DamageGridItem
                        me     = { me }
                    />
                </OptimizedGrid>
            }
            { loading && <Loader type="block" /> }
        </>
    );
}

export default DamageGrid;