import React from "react";

import { Icons } from "components/layout";


const IconHelper = ({ status }) => {
    switch (status){
        case "new": return <Icons.Question />;
        case "check": return <Icons.Check />;
        case "double_check": return <Icons.Check type="double" />;

        default: return null;
    }
};


const LabelHelper = (damageStatus) => {

    switch (damageStatus){
        case "new": return "DAMAGE.Status_Label_New";
        case "check": return "DAMAGE.Status_Label_Check";
        case "double_check": return "DAMAGE.Status_Label_DCheck";

        default: return null;
    }

};


const DamageStatusHelper = {
    Icon  : IconHelper,
    Label : LabelHelper
};

export default DamageStatusHelper;
