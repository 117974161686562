import React from "react";
import { Select } from "antd";

import StandHelpers from "../helpers";
import { Icons } from "components/layout";
import { Localize } from "components/service";


const { Option } = Select;

const StandTypeFilterField = ({ ...props }) => {

    return(
        <Select
            className={ `stand-type-filter-field` }
            suffixIcon={
                <Icons.Arrow type="filled"/>
            }
            defaultValue="all"
            //open={ true }
            { ...props }
        >
            <Option value="all">
                <Localize>FORMS.Select_DefaultValue_All</Localize>
            </Option>
            { StandHelpers.typeFilter.options.map(
                ({ label }, index) => {
                    if(label){
                        return(
                            <Option key={ index } value={ index }>
                                <Localize>{ label }</Localize>
                            </Option>
                        );
                    }
                    return null;
                }
            )}
        </Select>
    );
}

export default StandTypeFilterField;