import { gql } from "@apollo/client";


export const JOB_CHANGE_STATUS = gql`
    mutation JobUpdateStatus(
        $id       : ID!
        $status   : CraftJobStatusField!
    ) {
        craftJobUpdateStatus(
            id: $id
            status: $status
        ) {
            label
            message
            craft_job{
                id
                number
                flag
                type
                status
                completed_at
                description
                attachment
                craft_type
                completor {
                    id
                    name
                    surname
                }
                taken_at
                taken {
                    id
                    name
                    surname
                }
                created_at
                creator {
                    id
                    name
                    surname
                }
            }
        }
    }
`;

export const JOB_CHANGE_FLAG = gql`
    mutation JobUpdateStatus(
        $id    : ID!
        $flag  : Int!
    ) {
        craftJobChangeFlag(
            id: $id
            flag: $flag
        ) {
            label
            message
        }
    }
`;

export const JOB_CHANGE_STATUS_AUTO = gql`
    mutation JobUpdateStatus(
        $id       : ID!
        $status   : CraftJobStatusField!
    ) {
        craftJobUpdateStatus(
            id: $id
            status: $status
        ) {
            label
            message
        }
    }
`;

export const JOB_CREATE = gql`
    mutation JobCreate(
        $input: CraftJobCreateFieldsInput!
        $furnishings : [FurnishingOrderFieldsInput]
    ) {
        craftJobCreate(
            input : $input
            furnishings : $furnishings
        ) {
            label
            message
            craft_job{
                id
                number
                flag
                type
                status
                craft_type
                description
                attachment
                completed_at
                completor {
                    id
                    name
                    surname
                }
                taken_at
                taken {
                    id
                    name
                    surname
                }
                created_at
                creator {
                    id
                    name
                    surname
                }
                furnishingOrder{
                    id
                    qty
                    furnishing_id
                    type
                    ee_id
                    furnishing {
                        id
                        title
                        sku
                        qty
                        price
                        ee_id
                        image
                        created_at
                    }
                    created_at
                }
                stand {
                    id
                    ee_id
                    hall_id
                    hall_name
                    exhibitor
                    aquise_number
                    proauf_number
                    block
                    corridor
                    stand_number
                    delivery_time_at
                    file_stand_plan
                    file_info
                    file_graphic
                    file_photo
                    crafts {
                        id
                        title
                        subcontractor_id
                        stand_id
                        type
                        status
                        subcontractor{
                            id
                            title
                        }
                        craft_jobs{
                            id
                            type
                            status
                        }
                    }
                    created_at
                    updated_at
                }
            }
        }
    }
`;


export const JOB_UPDATE = gql`
    mutation JobUpdate(
        $input: CraftJobUpdateFieldsInput!
        $furnishings : [FurnishingOrderFieldsInput]
    ) {
        craftJobUpdate(
            input : $input
            furnishings : $furnishings
        ) {
            label
            message
            craft_job{
                id
                number
                flag
                type
                status
                craft_type
                description
                attachment
                completed_at
                completor {
                    id
                    name
                    surname
                }
                taken_at
                taken {
                    id
                    name
                    surname
                }
                created_at
                creator {
                    id
                    name
                    surname
                }
                furnishingOrder{
                    id
                    qty
                    furnishing_id
                    type
                    ee_id
                    furnishing {
                        id
                        title
                        sku
                        qty
                        price
                        ee_id
                        image
                        created_at
                    }
                    created_at
                }
            }
        }
    }
`;

export const JOB_PHOTO_UPLOAD = gql`
    mutation JobPhotoUpload(
        $input: FileUpload!
    ) {
        fileUpload(
            input : $input
        ) {
            label
            message
            model{
                ... on CraftJob {
                    id
                    number
                    flag
                    type
                    status
                    craft_type
                    description
                    attachment
                    completed_at
                    completor {
                        id
                        name
                        surname
                    }
                    taken_at
                    taken {
                        id
                        name
                        surname
                    }
                    created_at
                    creator {
                        id
                        name
                        surname
                    }
                }
            }
        }
    }
`;