import React from "react";

import { Icons } from "components/layout";


const NoData = ({
        text,
        icon,
        className = ""
    }) => {

    return(
        <div className={`no-data ${ className }`}>
            {  ((typeof text === "string") || (typeof text === "object" && text.before) ) &&
                <div className="no-data-message before">
                    { typeof text === "string" ?  text : text.before }
                </div>
            }
            { icon &&
                <div className="icon-wrap">
                    <Icons.NoContent type={ icon } />
                </div>
            }
            { typeof text === "object" && text.after &&
                <div className="no-data-message after">
                    { text.after }
                </div>
            }
        </div>
    );
}


export default NoData;