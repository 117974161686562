import React from "react";

import HallForms from "../../forms";
import { FilterModalStandard } from "components/service";


const HallOverviewFilterModal = (props) => {

    return(
        <FilterModalStandard
            wrapClass="craft-overview-filter-modal-wrap"
            modalClass="craft-overview-filter-modal"
        >
            <HallForms.OverviewFilter
                { ...props }
            />
        </FilterModalStandard>
    );
};

export default HallOverviewFilterModal;