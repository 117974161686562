import CraftGrid, { CraftGridItem } from "./craft-grid";
import CraftOverviewGrid from "./craft-overview-grid";

import "./craft-grids.scss";


const CraftGrids = {
    All      : CraftGrid,
    Single   : CraftGridItem,
    Overview : CraftOverviewGrid
};

export default CraftGrids;