import React from "react";

import { SortListStandard, SortModalStandard } from "components/service";


const JobSortModal = ({ sort }) => {

    const sortValues = [
        {
            label : "SORT.Text_NewestByCreation",
            value : { column: "CREATED_AT", order : "DESC" }
        },
        {
            label : "SORT.Text_OldestByCreation",
            value : { column: "CREATED_AT", order : "ASC" }
        },
        {
            label : "SORT.Text_ExhibitorNameAZ",
            value : { column: "EXHIBITOR", order : "ASC" }
        },
        {
            label : "SORT.Text_ExhibitorNameZA",
            value : { column: "EXHIBITOR", order : "DESC" }
        },
        {
            label : "SORT.Text_FinishedFirst",
            value : { column: "STATUS", order : "ASC" }
        },
        {
            label : "SORT.Text_InProgressFirst",
            value : { column: "STATUS", order : "DESC" }
        },
        {
            label : "SORT.Text_FlagFirst",
            value : { column: "FLAG", order : "DESC" }
        },
        {
            label : "SORT.Text_FlagLast",
            value : { column: "FLAG", order : "ASC" }
        },
    ];

    return(
        <SortModalStandard
            wrapClass="job-sort-modal-wrap"
            modalClass="job-sort-modal"
        >
            <SortListStandard
                sortValues={ sortValues }
                setQuerySort={ sort.set }
                defaultSort={ sort.data[0] }
                relSort={ "relStand" }
            />
        </SortModalStandard>
    );
};

export default JobSortModal;