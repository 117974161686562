import React from "react";
import { Button } from "antd";

import { Localize, ModalStandard, PhotoViewerBlock } from "../service";
import { Icons } from "components/layout";
import { useWindowDimensions } from "components/use-hooks";


const PhotoModalStandard = ({
        modalProps : {
            wrapClass = "",
            title = "",
            className : modalClass = ""
        },
        buttonProps : {
            type = "ghost",
            className = "light",
            icon = "Photo",
            text = <Localize wrap>JOB.Button_Text_ViewPhoto</Localize>
        },
        ...props
    }) => {

    const { width } = useWindowDimensions();
    const Icon = Icons[icon];

    return(
        <div className = { `photo-modal-wrap ${ wrapClass }` }>
            <ModalStandard
                extraClass = { `dark full ${ modalClass }` }
                title = { title }
                modalButton={
                    <Button
                        disabled = { !props.photo }
                        type = { type }
                        className = { className }
                    >
                        <Icon />{ text }
                    </Button>
                }
                centered={ !(width <= 768) }
            >
                <PhotoViewerBlock
                    { ...props }
                />
            </ModalStandard>
        </div>
    );
}

export default PhotoModalStandard;