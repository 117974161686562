import React from "react";

import JobModals from "../modals";
import { FilterParamsList } from "components/service";

const JobFilterBlock = ({ sort, filter }) => {

    return(
        <div className="filter-block job-filter-block">
            <div className="modals grid-two-col">
                <JobModals.Filter filter = { filter } />
                <JobModals.Sort sort={ sort } />
            </div>
            <FilterParamsList
                filter = { filter }
                fieldsArr={ [ "where", "whereCraft" ] }
            />
        </div>
    )
}

export default JobFilterBlock;