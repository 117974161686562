import { gql } from '@apollo/client';


export const GET_ME = gql`
    query GetMe {
        me{
            id
            name
            surname
            email
            phone
            status
            language_slug
            eeCurrentId @client
            role{
                id
                label
                slug
                permissions{
                    id
                    label
                    panel
                    type
                    value
                    description
                }
            }
            company
            subcontractor{
                id
                title
                number
                type
                description
            }
            exhibitionEvent{
                id
                title
                status
                path_model
                start_at
                end_at
                constructionStart_at
                constructionEnd_at
                standsCount
                hallsCount
                craftCountUniq
                created_at
                updated_at
            }
            is_online
            last_visit_at
            created_at
            updated_at
        }
    }
`;

export const GET_ME_LOCALE = gql`
    query GetMeLocale {
        me{
            id,
            language_slug
        }
    }
`;