import React from "react";
import { useRouteMatch } from "react-router-dom";

import Hall from "components/hall";
import { Wraps } from "components/layout";
import { useVarParam } from "components/use-hooks";
import { localizeIt } from "components/service/localize";


const HallPage = () => {

    const appParams = useVarParam(),
          match = useRouteMatch();

    appParams.set({
        headerTitle: localizeIt("PAGE.Title_HallPlan"),
        bottomBar: true
    });


    return (
        <Wraps.Container className="page hall-page">
            <Hall.Blocks.Plan
                hallId={ +match.params.id }
            />
        </Wraps.Container>
    );
}

export default HallPage;