import React from "react";
import { InputNumber } from "antd";

import { PhotoModalStandard } from "components/service";
import Localize from "components/service/localize";


const FurnishingCustomListItem = React.memo(({
        id,
        furnishingId = id,
        title,
        sku,
        qty,
        price,
        className = "",
        image,
        selected = [],
        changeQty,
        orderId,
        created_at,
        user,
        moment,
        type,
        ActionComponent,
        withCreatorData = false,
        withType = false,
        ...props
    }) => {

    const {
        surname,
        name
    } = user || {}

    const current = selected.find( ( item ) => id === item.furnishingId );
    let realQty = qty;

    if(current){
        const inOrderQty = current.inOrderQty || 0;
        realQty = qty + inOrderQty - current.qty;
    }

    return(
        <div className={ `furnishing-custom-list-item ${ className }` }>
            <div className="data">
                { withType && type &&
                    <Localize wrap="h5">{ `FURNISHING.Order_Type_${ type[0].toUpperCase() + type.slice(1) }` }</Localize>
                }
                <div className="info">
                    <div className="col">
                        <h4>{ title }</h4>
                        { sku &&
                            <p className="text-label">{ sku }</p>
                        }
                    </div>
                    <div className="col">
                        {image &&
                            <PhotoModalStandard
                                modalProps={{
                                    title: <Localize>FURNISHING.Modal_Title_Photo</Localize>,
                                }}
                                buttonProps={{
                                    text: null,
                                    icon: "Eye",
                                    type: "default",
                                    className: "icon round"
                                }}
                                file={image}
                                photo={image}
                                title={title}
                            />
                        }
                    </div>
                    <div className="col">
                        { changeQty ?
                            <InputNumber
                                name="qty"
                                //formatter={value => `${value} ${ localizeIt('GLOBAL.Text_Piece')  }`}
                                //parser={value => value.replace(localizeIt('GLOBAL.Text_Piece'), '')}
                                max={ props.storeQty }
                                min={ 1 }
                                value = { current ? current.qty : qty }
                                onChange = { (value) => {
                                     changeQty( value, furnishingId )
                                 } }
                            /> :
                            <span className="qty">
                                { realQty }  <Localize>GLOBAL.Text_Piece</Localize>
                            </span>
                        }
                    </div>
                </div>
                { withCreatorData &&
                    <div className={`created ${ !surname && !name && `no-user` }`}>
                        { surname && name &&
                            <span className="user">{ surname } { name }</span>
                        }
                        <span className="date">{ moment(created_at).format("DD.MM.YYYY") }</span>
                    </div>
                }
            </div>
            { ActionComponent &&
                <div className="action">
                    { React.cloneElement(
                        ActionComponent,
                        {
                            furnishingId,
                            title,
                            qty: realQty,
                            storeQty: qty,
                            sku,
                            image,
                            orderId
                        }
                    ) }
                </div>
            }

        </div>
    );
})


export default FurnishingCustomListItem;