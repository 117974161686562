import React from "react";
import {Button} from "antd";

import cache from "graphql/cache";

import ExhibitionLists from "../lists";
import { Icons } from "components/layout";
import { Localize, ModalStandard } from "components/service";
import { useWindowDimensions } from "components/use-hooks";


const ExhibitionSelectModal = ( { ee, currentEE, me } ) => {

    let ModalButton = false;

    if(ee.length > 1){
        ModalButton = <Button className="icon rounded" >
                        <Icons.ThreeDot />
                      </Button>
    }

    const { width } = useWindowDimensions();

    return(
        <ModalStandard
            extraClass="exhibition-select-modal"
            modalButton={ ModalButton }
            centered={ !(width <= 768) }
            title={ <Localize>EXHIBITION.Modal_Title</Localize> }
        >
            <ExhibitionLists.Selection
                ee={ ee }
                currentEEid={ currentEE.id }
                type="radio-list"
                action={
                    (value, callback) => {

                        localStorage.setItem("eeCurrentId", value);
                        cache.modify({
                            id: cache.identify(me),
                            fields: {
                                last_visit_at() {
                                    return `${ value + Math.floor(Math.random() * (1000 - 10 + 1)) + 10 } second ago`;
                                }
                            }
                        });

                        callback();
                    }
                }
             />
        </ModalStandard>
    );

}

export default ExhibitionSelectModal;