import React from 'react';
import Icon from '@ant-design/icons';


const PictureSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M38.3,4.6H9.8C7,4.6,4.7,6.8,4.7,9.7v28.5c0,2.8,2.3,5.1,5.1,5.1h28.5c2.8,0,5.1-2.3,5.1-5.1V9.7
		C43.4,6.8,41.1,4.6,38.3,4.6z M7.2,9.7c0-1.4,1.2-2.6,2.6-2.6h28.5c1.4,0,2.6,1.2,2.6,2.6v28.5c0,0.3-0.1,0.5-0.1,0.8l-9.3-18.7
		c-0.2-0.4-0.6-0.7-1.1-0.7c-0.4,0-0.9,0.2-1.1,0.6l-7,11.8l-4.9-4.3c-0.3-0.2-0.6-0.3-1-0.3c-0.4,0-0.7,0.2-0.9,0.5l-8,11.5
		c-0.2-0.4-0.3-0.8-0.3-1.2V9.7z M9.8,40.7c-0.1,0-0.1,0-0.2,0l7.2-10.3l5,4.4c0.3,0.2,0.7,0.3,1,0.3c0.4-0.1,0.7-0.3,0.9-0.6
		l6.6-11.1l8.6,17.3c-0.2,0-0.4,0.1-0.6,0.1H9.8z"/>
        <path d="M19.6,21.8c2.4,0,4.3-1.9,4.3-4.3c0-2.4-1.9-4.3-4.3-4.3s-4.3,1.9-4.3,4.3C15.3,19.9,17.2,21.8,19.6,21.8z M19.6,15.8
		c1,0,1.8,0.8,1.8,1.8s-0.8,1.8-1.8,1.8s-1.8-0.8-1.8-1.8S18.6,15.8,19.6,15.8z"/>
    </svg>
);

const PictureIcon = props => {
    return <Icon component={ PictureSvg } className={ props.className } />;

};

export default PictureIcon;