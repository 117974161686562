import React from "react";
import { Link } from "react-router-dom";

import { useVarParam } from "components/use-hooks";


const Page404 = ({
        goLink = "/",
        goText = "Go on main"
    }) => {

    const appParams = useVarParam();

    appParams.set({
        headerTitle: "Page not found",
        bottomBar: true
    });

    return(
        <div className="page page-404">
            <h1>404</h1>
            <p>Sorry, that page doesn't exist</p>
            <Link to={ goLink } className="link-text-bold">
                { goText }
            </Link>
        </div>
    );
}

export default Page404;