import { gql } from "@apollo/client";


export const DAMAGE_REPORT_CREATE = gql`
    mutation DamageReportCreate(
        $input : DamageReportFieldsInput! 
    ) {
        damageReportCreate(
            input: $input 
        ) {
            label
            message
            damageReport {
                id
                status
                total_files
                
#                stand {
#                    id    
#                }
            }
        }
    }
`;

export const DAMAGE_REPORT_UPDATE = gql`
    mutation DamageReportUpdate(
        $input : DamageReportFieldsInput!
    ) {
        damageReportUpdate(
            input: $input
        ) {
            label
            message
            damageReport {
                id
                status
                total_files
#                stand {
#                    id
#                }
            }
        }
    }
`;

export const DAMAGE_REPORT_DELETE = gql`
    mutation DamageReportDelete(
        $id : ID!
    ) {
        damageReportDelete(
            id: $id
        ) {
            label
            message
        }
    }
`;

export const DAMAGE_FILE_UPLOAD = gql`
    mutation damageFileUpload(
        $input : DamageReportFileUploadInput!
    ) {
        damageReportFileUpload(
            input: $input
        ) {
            label
            message
            damageReport{
                id
                status
                total_files
                damageFiles{
                    id
                    title
                    description
                    path
                    order
                    number
                    user{
                        id
                        name
                        surname
                    }
                    created_at
                }
            }
        }
    }
`;

export const DAMAGE_FILE_UPDATE = gql`
    mutation DamageFileUpdate(
        $input : DamageFileFieldsInput!
    ) {
        damageFileUpdate(
            input: $input
        ) {
            label
            message
            damageFile{
                id
                title
                description
                path
                order
                number
                user{
                    id
                    name
                    surname
                }
                created_at
            }
        }
    }
`;

export const DAMAGE_FILE_DELETE = gql`
    mutation DamageFileDelete(
        $id : ID!
    ) {
        damageFileDelete(
            id: $id
        ) {
            label
            message
            damageReport {
                id
                status
                total_files
                damageFiles{
                    id
                    title
                    description
                    path
                    order
                    number
                    user{
                        id
                        name
                        surname
                    }
                    created_at
                }
            }
        }
    }
`;