import JobTypeSelectField from "./job-type-select-field";
import JobStatusSelectField from "./job-status-select-field";
import JobFlagSelectField from "./job-flag-select-field";


const JobFields = {
    TypeSelect   : JobTypeSelectField,
    StatusSelect : JobStatusSelectField,
    FlagSelect   : JobFlagSelectField
}

export default JobFields;