import React from "react";

import Craft from "components/craft";
import { Localize } from "components/service";


const StandCraftsBlock = ({
        crafts,
        stand
    }) => {

    return(
        <div className="block-wrap stand-overview-block">
            <Localize wrap="h4" wrapClass={ "text-label" }>STAND.Block_Title_Crafts</Localize>
            <Craft.Lists.Contact
                crafts={ crafts.filter( ({ status }) => status !== "disabled") }
                stand={ stand }
            />
        </div>
    );

}

export default StandCraftsBlock;