import React from "react";
import { useQuery } from "@apollo/client";
import { Button } from "antd";

import { GET_HALL } from "graphql/query/hall-gql";

import { Loader, NoData } from "components/request-result";
import { Icons } from "components/layout";
import { ImagePanPinchViewer, Localize } from "components/service";
import { saveAsFile } from "utils";


const HallPlanBlock = ({ hallId }) => {

    const { data, loading } = useQuery(GET_HALL, {
        variables: {
            id : hallId
        },
        fetchPolicy: 'cache-first'
    });

    const {
        hall = {}
    } = data ? data : {};

    const {
        title = undefined,
        hall_plan : plan = undefined,
        hall_plan_img : planImg = undefined
    } = hall ? hall : {};


    const Actions = ({ rotate /*, rotation*/ }) => (
        <div className="hall-plan-actions">
            <Button
                type="primary"
                className="icon"
                onClick={ () => rotate() }
            >
                <Icons.Turn />
            </Button>
            <Button
                type = "primary"
                className = "icon"
                onClick = { () => saveAsFile.document(plan) }
            >
                <Icons.Download />
            </Button>
        </div>
    );

    return(
        <div className="hall-plan-wrap">
            { loading && <Loader type="block" /> }
            { hall && !loading &&
                <>
                    <h3>{ title }</h3>
                    { plan ?
                        <ImagePanPinchViewer
                            extraClass = { "hall-plan" }
                            image      = { planImg }
                            title      = { title }
                        >
                            <Actions />
                        </ImagePanPinchViewer> :
                        <NoData
                            text = { { after : <Localize>NO_DATA.HallPlan</Localize> } }
                            icon = "plan"
                            className = "dark"
                        />
                    }
                </>
            }
            { !loading && !hall &&
                <NoData
                    text =  { { after : <Localize>NO_DATA.Hall</Localize> } }
                    icon = "plan"
                    className = "dark"
                />
            }
        </div>
    )
}

export default HallPlanBlock;