import React, { useState } from "react";

import DamagePhotoDescriptionBlock from "./damage-photo-description-block";
import DeleteButtons from "../buttons";
import { ImagePanPinchViewer } from  "components/service";
import { useVarParam } from "components/use-hooks";


const DamagePhotoViewerBlock = ({ damageFiles }) => {

    const [ slideNumber, setSlideNumber ] = useState(damageFiles.length - 1);

    const moment = useVarParam().get("moment");

    const currentFile = damageFiles[ slideNumber ] === undefined ?
                            damageFiles[ slideNumber - 1 ] : damageFiles[ slideNumber ];

    return(
       <div className="damage-photo-viewer-block">
           <ImagePanPinchViewer
                image        = { damageFiles.map( ( file ) => file.path) }
                withDots     = { true }
                beforeChange = { (current, next) => {
                    setSlideNumber( next );
                } }
                initialSlide = { damageFiles.length - 1 }
                imgWrapClass = { "img-wrap" }

           />
           <div className="delete-wrap">
               <DeleteButtons.DeletePhoto id={ currentFile.id } file = { currentFile } />
           </div>
           { currentFile.number &&
               <div className="damage-photo-info">
                   <div className="number">
                       № <br />
                       { currentFile.number }
                   </div>
                   <div className="date">
                       { moment(currentFile.created_at).format("DD.MM.YYYY") } <br />
                       { moment(currentFile.created_at).format("HH:mm") }
                   </div>
                   <div className="author">
                       { currentFile.user.name }<br />
                       { currentFile.user.surname }
                   </div>
               </div>
           }
           <DamagePhotoDescriptionBlock
                fileId      = { currentFile.id }
                description = { currentFile.description || "" }
           />
       </div>
    )
};

export default DamagePhotoViewerBlock;