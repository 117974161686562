import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Collapse } from "antd";

import { JOB_CHANGE_STATUS_AUTO } from "graphql/mutation/job-gql";

import JobHelpers from "../../helpers";
import JobButtons from "../../buttons";
import JobBlocks from "../../blocks";
import JobModals from "../../modals";
import Furnishing from "components/furnishing";
import Craft from "components/craft";
import { UserHelpers } from "components/user";
import { PhotoModalStandard } from "components/service";
import { Icons } from "components/layout";
import Localize from "components/service/localize";
import { useNotification } from "components/use-hooks";


const { permission } = UserHelpers;

const { Panel } = Collapse;

const JobGridItem = ({
        id,
        number,
        status,
        type,
        craft,
        stand,
        description,
        completor,
        furnishingOrder,
        //taken,
        attachment,
        creator,
        created_at,
        //taken_at,
        flag,
        completed_at,
        moment,
        me,
        itsNew = false,// moment().diff(created_at, "days", true) < 1 && status === "waiting"
        ...props
    }) => {

    const {
        id : standId,
        exhibitor,
    } = stand,
    { subcontractor } = craft,
    location = useLocation();

    const { nError } = useNotification(),
          [ cStatus, setCStatus ] = useState(status);

    const [ jobChangeStatus, { loading }] = useMutation( JOB_CHANGE_STATUS_AUTO );

    //let statusIcon = "";

    // if(status === "in_progress"){
    //     statusIcon = <Icons.Check />;
    // } else if(status === "completed"){
    //     statusIcon = <Icons.Check type="double" />;
    // }

    const timeDiff = () => {
        const time = completed_at ?
            moment(completed_at).diff(created_at, "minutes") :
            moment().diff(created_at, "minutes");

        const hours = Math.floor(time / 60),
              minutes = Math.floor(time % 60);

        return {
            hours : hours <= 9 ? "0" + hours : hours,
            minutes : minutes <= 9 ? "0" + minutes : minutes,
        }
    }

    return(
        <div
            className={ `job-grid-item bg-light-bordered ${ itsNew ? "new-badge" : "" }` }
            // data-badgetext={ itsNew ? localizeIt("GLOBAL.Badge_Text_New") : "" }
        >
            { ["waiting", "in_progress"].indexOf(cStatus) !== -1 &&
                <div className={`badge ${cStatus}`}>
                    {cStatus === "waiting" && <Icons.Email type="closed" />}
                    {cStatus === "in_progress" && <Icons.Email type="open"/>}
                </div>
            }
            <div className="job-grid-item-hero grid-item-hero">
                <div className={ `title`}>
                    {/*{ statusIcon &&*/}
                    {/*    <div className="status-wrap">{ statusIcon }</div>*/}
                    {/*}*/}
                    <Localize wrap="h3">{ JobHelpers.NameByType.single({ jobType : type }) }</Localize>
                    <span className={ "time-on-task" }>
                        <Localize
                            time={ ` ${ timeDiff().hours }:${ timeDiff().minutes }` }
                        >
                            JOB.Text_DurationTime
                        </Localize>
                        <b>hh:mm</b>
                    </span>

                    <JobButtons.ChangeFlag
                        id={ id }
                        flag={ flag }
                    />
                </div>
                <JobBlocks.Info
                    jobNumber = { number }
                    { ...stand }
                />
            </div>
            <div className={ `job-grid-item-body grey` } >
                <Collapse
                    expandIconPosition = "end"
                    expandIcon = { () => <Icons.Arrow /> }
                    bordered = { false }
                    onChange = { ( [id] ) => {
                        const items = document.querySelectorAll('.ant-collapse-item-active');
                        items.forEach( (item) => item.querySelector(".ant-collapse-header").click() )

                        if(cStatus === "waiting" && creator.id !== me.id && id){

                            if(!loading){
                                jobChangeStatus({
                                    variables: {
                                        id,
                                        status : "in_progress"
                                    }
                                }).then(
                                    () => setCStatus("in_progress")
                                ).catch((error) => nError( error ))
                            }
                        }
                    } }
                >
                    <Panel
                        header={
                            <div className="craft-info" data-id = { id }>
                                <Craft.Grids.Single
                                    extraClass = { cStatus }
                                    type = { craft.type }
                                    hasJobType={ [ type ] }
                                    title={ craft.title }
                                />
                                <div>
                                    <h4>{ exhibitor }</h4>
                                    { subcontractor
                                        && <p className="subcontractor">{ subcontractor.title }</p>
                                    }
                                </div>
                            </div>
                        }
                        key={ id }
                        ghost
                    >
                        <div className="description">
                            { description }
                        </div>
                        { !!furnishingOrder.length &&
                            <div className="furnishing-selected">
                                { furnishingOrder.map(({ furnishing, ...item }) => {
                                    return {
                                        orderId      : item.id,
                                        furnishingId : item.furnishing_id,
                                        qty          : item.qty,
                                        inOrderQty   : item.qty,
                                        image        : furnishing.image,
                                        storeQty     : furnishing.qty + item.qty,
                                        eeId         : item.ee_id,
                                        title        : furnishing.title,
                                    }
                                }).map(
                                    ( item ) => {
                                        return(
                                            <Furnishing.Lists.Single
                                                key = { item.furnishingId }
                                                { ...item }
                                            />
                                        );
                                    })
                                }
                            </div>

                        }
                        { creator.id === me.id &&
                            <JobModals.Edit
                                buttonType="edit"
                                job={ { id, type, status : cStatus, attachment, description, furnishingOrder } }
                                jobType={ type }
                                craftData={ craft }
                                stand={ stand }
                            />
                        }
                        <div className="user-info">
                            { creator &&
                                <p className="creator">
                                    <Icons.Edit />
                                    <Localize
                                        whoCreate  = { `${ creator.name } ${ creator.surname }` }
                                        whenCreate = { moment(created_at).format("DD.MM.YYYY HH:mm") }
                                    >
                                        JOB.Text_Creator
                                    </Localize>
                                </p>
                            }
                            {/*{ taken &&*/}
                            {/*    <p className="taker">*/}
                            {/*        <Icons.Check type="circle"/>*/}
                            {/*        <Localize*/}
                            {/*            whoWork  = { `${ taken.name } ${ taken.surname }` }*/}
                            {/*            whenWork = { moment(taken_at).format("DD.MM.YYYY HH:mm") }*/}
                            {/*        >*/}
                            {/*            JOB.Text_InWork*/}
                            {/*        </Localize>*/}
                            {/*    </p>*/}
                            {/*}*/}
                            { completor &&
                                <p className="executor">
                                    <Icons.Check type="doubleWithCircle"  />
                                    <Localize
                                        whoExecute  = { `${ completor.name } ${ completor.surname }` }
                                        whenExecute = { moment(completed_at).format("DD.MM.YYYY HH:mm")  }
                                    >
                                        JOB.Text_Executor
                                    </Localize>
                                </p>
                            }
                        </div>
                        <div className="actions-wrap">
                            <div className={ `group ${ attachment ? "grid-two-col" : "" }` }>
                                { attachment &&
                                    <PhotoModalStandard
                                        modalProps = {{
                                            wrapClass : "job-photo-modal-wrap",
                                            title     : <Localize wrap>JOB.Modal_Title_Photo</Localize>,
                                            className : "job-photo-modal"
                                        }}
                                        buttonProps = {{
                                            className : "dark",
                                            icon : "Photo"
                                        }}
                                        photo = { attachment }
                                        title = { exhibitor }
                                    />
                                }
                                <Link
                                    className="link-button-ghost dark"
                                    to={() => {
                                        return {
                                            pathname: `/stands/${ standId }`,
                                            state: {
                                                from : location.pathname,
                                                returnPagePosition : window.scrollY
                                            }
                                        }
                                    }}
                                >
                                    <Icons.Stand />
                                    <Localize>JOB.Button_Text_ToTheStand</Localize>
                                </Link>

                            </div>
                            { type !== "damages" && permission().has({ value : "change_job_status" }) &&
                                <JobButtons.ChangeStatus
                                    id={ id }
                                    status={ cStatus }
                                />
                            }
                        </div>
                    </Panel>
                </Collapse>

            </div>
        </div>
    );
}

export default JobGridItem;