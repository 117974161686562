import { mergeDeep } from "@apollo/client/utilities";

const filterOptions = [
    {
        label : "FORMS.Select_StandTypeValue_CraftInProgress",
        value : { status : { column : "STATUS", operator : "EQ", value : "in_progress"}}
    },
    {
        label : "FORMS.Select_StandTypeValue_ControlInProgress",
        value : {
            type    : { column : "STATUS", operator : "IN", value : ["in_progress", "overdue"] },
            status  : { column : "TYPE", operator : "EQ", value : "mp_control" }

        },
    },
    {
        label : "FORMS.Select_StandTypeValue_ProtocolInProgress",
        value : {
            status : { column: "STATUS", operator: "IN", value: ["in_progress", "overdue"] },
            type   : { column: "TYPE", operator: "EQ", value: "protocol" }
        }
    },
    {
        label : "FORMS.Select_StandTypeValue_OverdueCrafts",
        value : { status : { column : "STATUS", operator : "EQ", value : "overdue"  }}
    },
    {
        label : "FORMS.Select_StandTypeValue_AdditionalJobs",
        value : {
            type : { column : "TYPE", operator : "IN", value : [ "additional", "additional_2", "additional_3" ] }
        }
    },
    {
        value : {
            subcontractor : { column : "SUBCONTRACTOR_ID", operator : "EQ", value : undefined }
        }
    },
    {
        label : "FORMS.Select_StandTypeValue_CraftCompleted",
        value : { status : { column : "STATUS", operator : "EQ", value : "completed"} }
    },

]

const getSubcontractorRule = () => {

    const { subcontractor } = valueByIndex(5);

    return subcontractor;
}

const mergeRules = (...rules) => {
    return mergeDeep( ...rules );
}

const valueByIndex = (idx) => {
    return filterOptions[idx].value;
}

const labelByIndex = (idx) => {
    return filterOptions[idx].label;
}

const standTypeFilterHelper = {
    valueByIndex,
    labelByIndex,
    getSubcontractorRule,
    mergeRules,
    options : filterOptions,
}

export default standTypeFilterHelper;