import React from "react";
import { useQuery } from "@apollo/client";

import { GET_STAND_LOG } from "graphql/query/stand-gql";

import StandLogGridItem from "./stand-log-grid-item";
import { Loader, NoData } from "components/request-result";
import {useMe, useVarParam} from "components/use-hooks";
import { Localize, OptimizedList } from "components/service";


const StandLogGrid = ({ standId }) => {

    const { me : { language_slug : lang } } = useMe(),
          perPage = 30;
    const { data, loading, fetchMore } = useQuery(GET_STAND_LOG, {
        variables: {
            where: {
                column: "STAND_ID",
                operator: "EQ",
                value : standId
            },
            orderBy: [{ column: "ID", order : "DESC" }],
            first: perPage,
        },
        fetchPolicy: "cache-and-network",
        notifyOnNetworkStatusChange: true
    });

    const {
        logs : {
            edges  = [],
            pageInfo = {}
        } = {}
    } = data ? data : {};

    const { moment } = useVarParam().get();

    return(
        <>
            { !edges.length && !loading &&
                <NoData
                    text={{
                        after :  <Localize br={ <br /> }>NO_DATA.Stands</Localize>
                    }}
                    icon="job"
                />
            }
            { !!edges.length &&
                <OptimizedList
                    listClass = "stand-log-grid"
                    itemClass = "stand-log-grid-item-wrap"
                    listItems = { edges }
                    pageInfo  = { pageInfo }
                    fetchMore = { fetchMore }
                    perPage   = { perPage }
                    model     = "logs"

                >
                    <StandLogGridItem
                        moment = { moment }
                        lang   = { lang }
                    />
                </OptimizedList>
            }
            { loading && <Loader type="block" /> }
        </>
    );
}

export default StandLogGrid;