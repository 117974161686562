import React from "react";

import Stand from "components/stand";
import { useVarParam } from "components/use-hooks";
import { Wraps, Buttons } from "components/layout";

import "./top-menu-bar.scss";


const TopMenuBar = () => {

    const appParams = useVarParam(),
        LeftControl = () => appParams.get("leftHeaderControl"),
        RightControl = () => appParams.get("rightHeaderControl");

    return (
        <header
            className={`top-menu-bar ${ appParams.get().headerClass.join(" ") }`}
        >
            <Wraps.Container>
                <div className="left-part">
                    { !appParams.get("leftHeaderControl") &&
                        <Buttons.Back
                            logo={ appParams.get("logoOn") }
                        />
                    }
                    <LeftControl />
                </div>
                <div className="center-part color-primary-op60">
                    { appParams.get("headerTitle") }
                </div>
                <div className="right-part">
                    { appParams.get("searchOn") &&
                        <Stand.Modals.Search/>
                    }
                    <RightControl/>
                </div>
            </Wraps.Container>
        </header>
    );
};


export default TopMenuBar;