import React from "react";
import { useQuery } from "@apollo/client";
import { Link, useLocation } from "react-router-dom";

import { GET_HALLS } from "graphql/query/hall-gql";

import { Icons } from "components/layout";
import { Loader } from "components/request-result";
import { Localize } from "components/service";


const HallList = ({ eeId }) => {

    const { data, loading } = useQuery(GET_HALLS, {
        variables: {
            ee_id : eeId
        },
        fetchPolicy: 'cache-first'
    });

    const location = useLocation();

    const {
        halls : {
            data : hallList = []
        } = {}
    } = data ? data : {};

    return(
        <div className="hall-list-wrap">
            { loading && <Loader type="block" /> }
            { data &&
                <ul className="hall-list">
                    { hallList.map(
                        ( { id, title, hall_plan } ) => {
                            return(
                                <li key={ id }>
                                    { hall_plan ?
                                        <Link to={{
                                            pathname: `/more/expo-plan/${ id }`,
                                            state: { from : location.pathname }
                                        }}>
                                            <Localize hallName={ title.toUpperCase() }>HALL.Link_Text_HallPlan</Localize>
                                            <Icons.Eye/>
                                        </Link> :
                                        <Localize wrap hallName={ title.toUpperCase() }>HALL.Link_Text_HallPlan</Localize>
                                    }
                                </li>
                            )
                        }
                    )}
                </ul>
            }
        </div>
    );
}

export default HallList;