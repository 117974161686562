import React from "react";
import { useQuery } from "@apollo/client";

import { GET_STAND } from "graphql/query/stand-gql";

import Stand from "components/stand";
import { useVarParam } from "components/use-hooks";
import { Wraps } from "components/layout";
import Localize, { localizeIt } from "components/service/localize";
import { Loader, NoData } from "components/request-result";


const StandKeysPage = ({ standId }) => {

    const { data, loading } = useQuery( GET_STAND, {
        variables: {
            id: standId
        },
        fetchPolicy: 'cache-first'
    });

    const appParams = useVarParam();

    appParams.set({
        headerTitle: localizeIt("PAGE.Title_StandKeys"),
        bottomBar: true
    });

    const {
        stand
    } = data ? data : {};


    return (
        <Wraps.Container className={`page stand-keys-page ${ !data  ? "empty" : "" }`}>
            { stand &&
                <>
                    <h3>{ stand.exhibitor }</h3>
                    <div className={`keys-list-wrap ${ !stand.keys.length  ? "empty" : "" }`}>
                        { stand && !!stand.keys.length ?
                            <Stand.Lists.Keys
                                standId={ stand.id }
                                keys={ stand.keys }
                            /> :
                            <NoData
                                text = {{
                                    before : <Localize>NO_DATA.Stand_NoKeys</Localize>
                                }}
                                icon = "keys"
                            />
                        }

                    </div>
                    <div className="page-actions">
                        <Stand.Modals.EditKey
                            standId = { stand.id }
                        />
                    </div>
                </>
            }
            { !loading && !stand &&
                <NoData
                    text = {{
                        before : <Localize>NO_DATA.Stand_DoesNotExist</Localize>
                    }}
                    icon = "stand"
                    className = "block"
                />
            }
            { loading && <Loader type="block" /> }
        </Wraps.Container>
    );
}

export default StandKeysPage;