import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { mergeDeep } from "@apollo/client/utilities";

import Craft from "components/craft";
import { useVarParam } from "components/use-hooks";
import { Wraps } from "components/layout";
import { localizeIt } from "components/service/localize";



const CraftsOverviewPage = () => {

    const appParams = useVarParam();
    const { state : {
        queryParams
    } = {} } = useLocation();

    const { filter } = Craft.Hooks.useFilter(
        appParams.get("rememberQueryParams").craftOverview,
    );

    useEffect( () => {
        if(filter && queryParams){
            filter.set(mergeDeep(filter.data,  queryParams.filter));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParams])

    appParams.set({
        headerTitle: localizeIt("PAGE.Title_CraftsOverview"),
        bottomBar: true
    });

    return (
        <Wraps.Container className="page crafts-overview-page">
            <Craft.Blocks.Filter filter={ filter }/>
            <Craft.Grids.Overview filter={ filter }/>
        </Wraps.Container>
    );
}

export default CraftsOverviewPage;