import ExhibitionSelectField from "./exhibition-select-field";
import ExhibitionSubcontractorSelectField from "./exhibition-subcontractor-select-field";

import "./exhibition-fields.scss";


const ExhibitionFields = {
    Select : ExhibitionSelectField,
    SubcontractorSelect : ExhibitionSubcontractorSelectField
}

export default ExhibitionFields;