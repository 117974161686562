import React from 'react';
import Icon from '@ant-design/icons';

const mainColor = { fill: "#FFFFFF" };

const LogoSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 38 38" strokeWidth="1" >
        <path d="M30.5,38H7.5C3.4,38,0,34.6,0,30.5V7.5C0,3.4,3.4,0,7.5,0h22.9C34.6,0,38,3.4,38,7.5v22.9
		C38,34.6,34.6,38,30.5,38z"/>
        <g>
            <path style={ mainColor } d="M19.8,10.6c0.3,0,0.8,0,1.1,0.1l0.4-3.2c-0.5-0.1-1-0.1-1.5-0.1c-0.5,0-0.9,0.1-1.4,0.1l0.3,3.2
			C19.1,10.6,19.4,10.6,19.8,10.6z"/>
            <path style={ mainColor } d="M11.3,19c0-0.6,0.1-1.2,0.2-1.8l-3.1-0.7c-0.2,0.8-0.3,1.7-0.3,2.6c0,0.1,0,0.2,0,0.3l3.2-0.1
			C11.3,19.2,11.3,19.1,11.3,19z"/>
            <path style={ mainColor } d="M14.9,12.1l-1.8-2.6c-0.8,0.6-1.5,1.2-2.1,1.9l2.4,2.1C13.8,13,14.4,12.5,14.9,12.1z"/>
            <path style={ mainColor } d="M23.4,26.7l1.4,2.8c0.8-0.4,1.7-0.9,2.3-1.5l-2-2.4C24.5,26,24,26.4,23.4,26.7z"/>
            <path style={ mainColor } d="M23.8,11.5l1.5-2.8c-0.8-0.4-1.7-0.8-2.7-1l-0.8,3.1C22.5,11,23.2,11.3,23.8,11.5z"/>
            <path style={ mainColor } d="M12.7,14.3l-2.6-1.8c-0.6,0.8-1,1.7-1.3,2.6l3,1.1C12,15.5,12.4,14.9,12.7,14.3z"/>
            <path style={ mainColor } d="M17.3,27.2l-1,3c0.9,0.3,1.8,0.4,2.8,0.5l0.2-3.2C18.6,27.4,18,27.3,17.3,27.2z"/>
            <path style={ mainColor } d="M17.8,10.8L17,7.7c-1,0.2-1.9,0.6-2.7,1l1.5,2.8C16.4,11.2,17.1,11,17.8,10.8z"/>
            <path style={ mainColor } d="M20.4,27.5l0.2,3.2c1-0.1,1.9-0.2,2.8-0.6l-1-3C21.8,27.3,21.1,27.4,20.4,27.5z"/>
            <path style={ mainColor } d="M12.5,23.3l-2.8,1.6c0.5,0.8,1,1.6,1.7,2.3l2.3-2.2C13.3,24.5,12.9,23.9,12.5,23.3z"/>
            <path style={ mainColor } d="M11.4,20.4l-3.1,0.4c0.1,1,0.4,1.9,0.8,2.8l3-1.2C11.7,21.7,11.5,21.1,11.4,20.4z"/>
            <path style={ mainColor } d="M14.5,25.6l-2,2.5c0.8,0.6,1.5,1.1,2.4,1.5l1.3-2.9C15.7,26.5,15.1,26.1,14.5,25.6z"/>
            <path style={ mainColor }
                  d="M25.9,24.9l2.3,2.1c0.6-0.7,1.2-1.4,1.7-2.2l-2.8-1.5C26.7,23.8,26.4,24.4,25.9,24.9z"/>
            <g>
                <path style={ mainColor } d="M15.8,22.4l12.9-10.9c-0.6-0.8-1.3-1.4-2.1-1.9L15.4,22L15.8,22.4z"/>
            </g>
        </g>
    </svg>
);

const LogoIcon = props => {
    return <Icon component={ LogoSvg } className={ props.className } />;

};


export default LogoIcon;