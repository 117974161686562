import React from "react";

import StandModals from "../../modals";


const StandKeysList = ({ keys, standId }) => {

    return(
        <ul className="stand-keys-list">
            { keys.map(
                ({ id, title, number }, index) => {
                    return(
                        <li className="stand-keys-list-item bg-light-bordered" key={ id }>
                            <div>{ index+1 }</div>
                            <div className="data">
                                <h4>{ title }</h4>
                                <p>{ number }</p>
                            </div>
                            <div className="actions">
                                <StandModals.EditKey
                                    keyId   = { id }
                                    standId = { standId }
                                    title   = { title }
                                    number  = { number }

                                />
                                <StandModals.DeleteKey
                                    keyId   = { id }
                                    standId = { standId }
                                    title   = { title }
                                />
                            </div>
                        </li>
                    )
                }
            ) }
        </ul>
    );
}

export default StandKeysList;