import React from 'react';
import Icon from '@ant-design/icons';

import { Loader } from "components/request-result";


const InProgressGearSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M33.3,25.9c0.3,0,0.6-0.3,0.6-0.6v-3c0-0.3-0.3-0.6-0.6-0.6h-1.6c-0.2-0.7-0.5-1.4-0.9-2.1l1.2-1.2c0.2-0.2,0.2-0.6,0-0.9
		l-2.1-2.1c-0.2-0.2-0.6-0.2-0.9,0l-1.2,1.2c-0.6-0.4-1.3-0.6-2-0.8v-1.7c0-0.3-0.3-0.6-0.6-0.6h-3c-0.3,0-0.6,0.3-0.6,0.6v1.7
		c-0.7,0.2-1.4,0.5-2,0.8l-1.2-1.2c-0.2-0.2-0.6-0.2-0.9,0l-2.1,2.1c-0.2,0.2-0.2,0.6,0,0.9l1.2,1.2c-0.4,0.6-0.7,1.3-0.9,2.1h-1.6
		c-0.3,0-0.6,0.3-0.6,0.6v3c0,0.3,0.3,0.6,0.6,0.6h1.5c0.2,0.8,0.5,1.5,0.9,2.2l-1,1c-0.2,0.2-0.2,0.6,0,0.9l2.1,2.1
		c0.2,0.2,0.6,0.2,0.9,0l1-1c0.7,0.4,1.4,0.7,2.2,0.9v1.4c0,0.3,0.3,0.6,0.6,0.6h3c0.3,0,0.6-0.3,0.6-0.6v-1.4
		c0.8-0.2,1.5-0.5,2.2-0.9l1,1c0.2,0.2,0.6,0.2,0.9,0l2.1-2.1c0.2-0.2,0.2-0.6,0-0.9l-1-1c0.4-0.7,0.7-1.4,0.9-2.2H33.3z M23.6,28.9
		c-2.7,0-5-2.2-5-5s2.2-5,5-5s5,2.2,5,5S26.3,28.9,23.6,28.9z"/>
        <path d="M44.4,23.7l-2.1-0.2c-0.1-4.7-2-9.2-5.3-12.7C33.5,7.2,28.7,5.1,23.6,5c-5.1-0.1-10,1.8-13.7,5.3
		c-3.7,3.5-5.8,8.3-5.9,13.4C3.8,34.3,12.3,43,22.8,43.3c0.1,0,0.3,0,0.4,0c4.8,0,9.3-1.7,12.8-4.9c0.7-0.6,0.8-1.8,0.1-2.5
		c-0.6-0.7-1.8-0.8-2.5-0.1c-3,2.7-6.8,4.1-10.8,4c-4.2-0.1-8.1-1.8-11-4.8s-4.4-7-4.3-11.2c0.1-4.2,1.8-8.1,4.8-11
		c3-2.9,7-4.4,11.2-4.3c4.2,0.1,8.1,1.8,11,4.8c2.6,2.7,4.1,6.2,4.3,9.9L36.7,23c-0.5,0-0.8,0.5-0.5,0.9l3.5,4.5
		c0.2,0.2,0.6,0.3,0.8,0.1l4.2-3.9C45.1,24.3,44.9,23.7,44.4,23.7z"/>
    </svg>
);

const InProgressSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M44.7,23.2l-3-0.4c-0.6-9.5-8.5-17.1-18.2-17.1C13.4,5.7,5.2,13.9,5.2,24s8.2,18.3,18.3,18.3c2.6,0,5.2-0.6,7.6-1.6
	c1-0.5,1.4-1.6,1-2.6c-0.5-1-1.6-1.4-2.6-1c-1.9,0.8-3.8,1.3-5.9,1.3c-7.9,0-14.3-6.4-14.3-14.3S15.6,9.7,23.5,9.7
	c7.3,0,13.3,5.5,14.2,12.5l-3.3-0.4c-0.9-0.1-1.5,0.8-1,1.6l1.9,3.3l2.3,4c0.3,0.6,1.2,0.7,1.6,0.2l3.3-3.2l2.7-2.7
	C45.9,24.4,45.5,23.3,44.7,23.2z"/>
    </svg>
);

const InProgressIcon = props => {

    if(props.loading){
        return <Loader />;
    }

    if(props.type === "gear"){
        return <Icon component={ InProgressGearSvg } className={ props.className } />;
    }

    return <Icon component={ InProgressSvg } className={ props.className } />;

};


export default InProgressIcon;