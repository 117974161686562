import React from 'react';
import Icon from '@ant-design/icons';


const StandSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M42.7,14.3L26,4.5c-0.4-0.2-0.9-0.2-1.4,0l-17,9.7C7.3,14.4,7,14.8,7,15.3v18.5c0,0.5,0.3,0.9,0.7,1.2l17,10.1
	c0.2,0.1,0.5,0.2,0.7,0.2c0.2,0,0.4-0.1,0.6-0.2c0,0,0,0,0,0c0,0,0,0,0.1,0l16.7-9.8c0.4-0.2,0.7-0.7,0.7-1.2V15.5
	C43.3,15,43.1,14.5,42.7,14.3z M9.7,17.6L24,25.9v2.3l-10.4-6.1c-0.1-0.1-0.3-0.2-0.4-0.2l-3.4-2V17.6z M24,31.4v10.2l-10-6V25.5
	L24,31.4z M26.7,31.4l10.2-5.9v10l-10.2,6V31.4z M26.7,28.2v-2.4l13.9-8.1v2.4L26.7,28.2z M25.3,7.2l14,8.3l-14,8.1l-14.2-8.2
	L25.3,7.2z M9.7,23l1.6,0.9V34L9.7,33V23z M39.6,33.9V24l1-0.6v10L39.6,33.9z"/>
    </svg>
);

const StandIcon = props => {
    return <Icon component={ StandSvg } className={ props.className } />;

};


export default StandIcon;