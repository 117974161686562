import React from "react";

import FurnishingCustomListItem from "./furnishing-custom-list-item";
import { OptimizedList } from "components/service";


const FurnishingCustomList = ({
        data,
        className = "",
        itemClassName = "",
        ActionComponent,
        ...props
    }) => {

    const {
        furnishings = [],
        pageInfo,
        fetchMore,
        perPage,
        model
    } = data;

    return(
        <OptimizedList
            listClass = { `furnishing-custom-list ${ className }` }
            itemClass = "furnishing-custom-list-item-wrap"
            listItems = { furnishings }
            pageInfo  = { pageInfo }
            fetchMore = { fetchMore }
            perPage   = { perPage }
            model     = { model }
            increaseViewportBy={
                {
                    top: 450,
                    bottom: 0
                }
            }
        >
            <FurnishingCustomListItem
                ActionComponent = { ActionComponent }
                className       = { itemClassName }
                { ...props }
            />
        </OptimizedList>
    );
}


export default FurnishingCustomList;