import React, { useState } from "react";
import {  useHistory } from "react-router-dom";
import { Input } from "antd";

import StandGrids from "../grids";
import { Icons } from "components/layout";
import Localize, { localizeIt } from "components/service/localize";


const StandSearchBlock = ({ callback }) => {

    const [ searchText, setSearchText ] = useState("");
    const history = useHistory();

    const requestLabel = localizeIt("STAND.Filter_Params_Exhibitor");

    return(
        <div className="stand-search-block">
            <div className="stand-search-block-head">
                <div className="search-field-wrap">
                    <Input
                        allowClear  = {
                            { clearIcon : <Icons.Cross /> }
                        }
                        placeholder = { localizeIt("STAND.Input_Placeholder_Search") }
                        prefix      = { <Icons.Search /> }
                        value       = { searchText }
                        onChange    = { ( { target } ) => setSearchText(target.value)  }
                    />
                </div>
                { callback &&
                    <div className="close-wrap">
                        <button onClick={ callback }>
                            <Localize>GLOBAL.Button_Text_Cancel</Localize>
                        </button>
                    </div>
                }
            </div>
            <div className="stand-search-block-body">
                { searchText.length >= 2 &&
                    <StandGrids.SimpleAll
                        searchText={ searchText }
                        itemAction={ ({ id, exhibitor }) => {

                            history.push("/stands/", {
                                ...history.location.state,
                                queryParams : {
                                    filter : {
                                        where: {
                                            standId: {
                                                label: requestLabel.replace(`{ exhibitorName }`, exhibitor),
                                                column: "ID",
                                                operator: "EQ",
                                                value: id,
                                            },
                                        }
                                    }
                                }
                            })

                            callback();
                        }}
                    />
                }
            </div>
        </div>
    );
};

export default StandSearchBlock;