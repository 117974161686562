import React from 'react';
import { useQuery } from "@apollo/client";
import moment from "moment";
import "moment/locale/de";
import "moment/locale/fr";

import { GET_APP_PARAMS } from "graphql/query/local-store-gql";

import { Menus } from "components/layout";
import { AppRoutes }   from "components/routes";
import { Suspense }    from "components/request-result";
import { useMe, useVarParam } from "components/use-hooks";

import './app.scss';


const localeMoment = moment;

const App = () => {

    const { loading, me, error } = useMe("cache-and-network", true),
          { data : { appParams } } = useQuery( GET_APP_PARAMS );

    const extraClass = `${ appParams.appBlockClass.join(" ") }${ appParams.bottomBar ? " with-bottom-bar": "" }`;

    localeMoment.locale( me ? me.language_slug : "en");

    useVarParam().set({
        "moment" : localeMoment,
        "me" : me
    });

    return (
        <div className={ `app ${ extraClass }` }>
            <Suspense type="block" state={ { loading, error } }>
                <Menus.TopBar key="top-bar" />
                <main key="main-body">
                    <AppRoutes />
                </main>
                <Menus.BottomBar key="bottom-bar" />
            </Suspense>
        </div>
    );
};

export default App;