import React from 'react';
import Icon from '@ant-design/icons';


const InfoSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M37.6,4.7H10.7c-3.5,0-6.3,2.8-6.3,6.3v25.8c0,3.5,2.8,6.3,6.3,6.3h26.8c3.5,0,6.3-2.8,6.3-6.3V11
		C43.9,7.5,41,4.7,37.6,4.7z M41.4,36.8c0,2.1-1.7,3.8-3.8,3.8H10.7c-2.1,0-3.8-1.7-3.8-3.8V11c0-2.1,1.7-3.8,3.8-3.8h26.8
		c2.1,0,3.8,1.7,3.8,3.8V36.8z"/>
        <path d="M28.1,21.2c0.7,0,1.2-0.6,1.2-1.2s-0.6-1.2-1.2-1.2h-7.9c-0.7,0-1.2,0.6-1.2,1.2s0.6,1.2,1.2,1.2h2.7v11.6h-2.7
		c-0.7,0-1.2,0.6-1.2,1.2s0.6,1.2,1.2,1.2h7.9c0.7,0,1.2-0.6,1.2-1.2s-0.6-1.2-1.2-1.2h-2.7V21.2H28.1z"/>
        <path d="M24.1,15.9c1,0,1.8-0.8,1.8-1.8c0-1-0.8-1.8-1.8-1.8c-1,0-1.8,0.8-1.8,1.8C22.3,15.1,23.1,15.9,24.1,15.9z"/>
    </svg>
);

const InfoIcon = props => {
    return <Icon component={ InfoSvg } className={ props.className } />;

};


export default InfoIcon;