import React from 'react';
import Icon from '@ant-design/icons';

import { Loader } from "components/request-result";


const PhotoSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M40.2,11.6h-6.4l-0.3-1.5c-0.4-2-2-3.4-3.9-3.4h-11c-1.9,0-3.5,1.4-3.9,3.4l-0.3,1.5H8.1c-2,0-3.6,1.6-3.6,3.6v23.4
		c0,2,1.6,3.6,3.6,3.6h32.2c2,0,3.6-1.6,3.6-3.6V15.2C43.8,13.2,42.2,11.6,40.2,11.6z M41.3,38.6c0,0.6-0.5,1.1-1.1,1.1H8.1
		c-0.6,0-1.1-0.5-1.1-1.1V15.2c0-0.6,0.5-1.1,1.1-1.1h7.4c0.6,0,1.1-0.4,1.2-1l0.5-2.5c0.2-0.8,0.8-1.4,1.4-1.4h11
		c0.7,0,1.3,0.6,1.4,1.4l0.5,2.5c0.1,0.6,0.6,1,1.2,1h7.4c0.6,0,1.1,0.5,1.1,1.1V38.6z"/>
        <path d="M24.2,16.4c-5.5,0-10,4.5-10,10s4.5,10,10,10s10-4.5,10-10S29.7,16.4,24.2,16.4z M24.2,33.9c-4.1,0-7.5-3.4-7.5-7.5
		s3.4-7.5,7.5-7.5s7.5,3.4,7.5,7.5S28.3,33.9,24.2,33.9z"/>
        <path d="M37.3,15.4c-1.2,0-2.2,1-2.2,2.2c0,1.2,1,2.2,2.2,2.2c1.2,0,2.2-1,2.2-2.2C39.5,16.4,38.5,15.4,37.3,15.4z"/>
    </svg>
);

const PhotoIcon = props => {
    if(props.loading){
        return <Loader />;
    }

    return <Icon component={ PhotoSvg } className={ props.className } />;

};


export default PhotoIcon;