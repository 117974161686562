import React from "react";

import { Localize } from "components/service";


const ExhibitionTimeBlock = ({ ee, moment }) => {

    let timer = <Localize timeLeft={ moment(ee.start_at).fromNow(true) }>GLOBAL.TimeLeft</Localize> ;

    if(moment().isBetween(ee.start_at, ee.end_at)){
        timer = <Localize wrap>EXHIBITION.Timer_State_Underway</Localize>;
    } else if(moment().isAfter(ee.end_at)){
        timer = <Localize wrap>EXHIBITION.Timer_State_Ended</Localize>;
    }

    return(
        <div className="exhibition-time-block">
            <div className="date">
                { moment(ee.start_at).format("DD MMMM | HH:mm") }
            </div>
            <div className="timer">
                { timer }
            </div>
        </div>
    )
};


export default ExhibitionTimeBlock;