import React from "react";
import { Switch, Route } from 'react-router-dom';

import { Pages } from "components/pages";
import { UserHelpers } from "components/user";


const { permission } = UserHelpers;
const { More } = Pages;

const MoreRoutes = () => {

    return(
        <Switch>
            <Route path="/more/" exact>
                <More.Single />
            </Route>
            { permission().has({value: "view_damages"}) &&
                <Route path="/more/view-damages" exact>
                    <More.ViewDamages/>
                </Route>
            }
            <Route path="/more/halls-overview" exact>
                <More.HallsOverview />
            </Route>
            <Route path="/more/expo-plan/" exact>
                <Pages.Halls.Collection />
            </Route>
            <Route path="/more/expo-plan/:id" exact>
                <Pages.Halls.Single />
            </Route>
            <Route path="/more/furnishing" exact>
                <Pages.More.Furnishing />
            </Route>
            <Route path="/more/documents" exact>
                <Pages.More.Documents />
            </Route>
            <Route path="/more/help-and-imprint" exact>
                <More.HelpImprint />
            </Route>
            <Route path="*" >
                <Pages.Page404 />
            </Route>
        </Switch>
    );
};

export default MoreRoutes;