import React, { useState } from "react";
import {Button, Form, Input, Radio} from "antd";
import { SyncOutlined } from "@ant-design/icons";

import { JOB_UPDATE, JOB_PHOTO_UPLOAD } from "graphql/mutation/job-gql";

import Furnishing from "components/furnishing";
import { PreUploadImage } from "components/service";
import { useMutation } from "@apollo/client";
import { useNotification } from "components/use-hooks";
import { Icons } from "components/layout";
import Localize, { localizeIt } from "components/service/localize";


const JobUpdateForm = ({ job, eeId, callback }) => {

    const {
        id,
        description,
        status,
        attachment,
        furnishingOrder = []
    } = job;

    const { nError, nSuccess } = useNotification(),
          [ fileChanged, setFileChanged] = useState(false),
          [ selectedFurnishing, setSelectedFurnishing ] = useState(
              furnishingOrder.length ?
                  furnishingOrder.map(({ furnishing, ...item }) => {
                      return {
                          orderId      : item.id,
                          furnishingId : item.furnishing_id,
                          qty          : item.qty,
                          inOrderQty   : item.qty,
                          storeQty     : furnishing.qty + item.qty,
                          eeId         : item.ee_id,
                          image        : furnishing.image,
                          title        : furnishing.title,
                          sku          : furnishing.sku
                      }
                  }) : []
          ),
          [ contentType, setContentType ] =  useState(furnishingOrder.length ? 'furnishing' : 'description');

    const [ jobUpdate, { loading }] = useMutation( JOB_UPDATE,
        {
            update(cache, { data }) {

                const {
                    craftJobUpdate : {
                        label,
                        message
                    }
                } = data;

                callback();

                nSuccess( label, message );
            }
        }
    );

    const [ jobPhotoAction, { loading: photoLoading }] = useMutation( JOB_PHOTO_UPLOAD,
        {
            update(cache, { data }) {

                const {
                    fileUpload : {
                        label,
                        message,
                    }
                } = data;

                nSuccess( label, message );
            },
            ignoreResults : true
        }
    );

    return(
        <Form
            layout="vertical"
            className="job-create-form"
            onFinish={ ({ description, file }) => {

                jobUpdate({
                    variables: {
                        input: {
                            id,
                            description : description || "",
                            status : status === "completed" ? "waiting" : status
                        },
                        furnishings : selectedFurnishing.filter( ( item ) => item.type !== "deleted").map(
                            ( fOrder ) => {
                                return {
                                    id: fOrder.orderId ? fOrder.orderId : undefined,
                                    furnishing_id: fOrder.furnishingId,
                                    type: job.type === "claims" ? 'lost' : "order",
                                    ee_id: eeId,
                                    qty: fOrder.qty
                                }
                            }
                        )
                    }
                }).then(
                    () => {
                        if((fileChanged)){
                            jobPhotoAction({
                                variables: {
                                    input:  {
                                        model: "CraftJob",
                                        model_id: id,
                                        model_field: "attachment",
                                        file : file ? file.originFileObj : null
                                    }
                                }
                            }).catch((error) => nError( error ))
                        }
                    }
                ).catch((error) => nError( error ))


            } }
        >
            { (job.type === "last_minutes" || job.type === "claims") &&
                <Form.Item
                    name="additionalType"
                    initialValue={ contentType }
                >
                    <Radio.Group
                        onChange={ (e) => {
                            setContentType(e.target.value)
                        }}
                    >
                        <Radio value={ 'description' }>
                            <Localize>FORMS.RadioInput_Text_Description</Localize>
                        </Radio>
                        <Radio value={ 'furnishing' }>
                            { job.type === "claims" ?
                                <Localize>FORMS.RadioInput_Text_FurnishingClaim</Localize> :
                                <Localize>FORMS.RadioInput_Text_Furnishing</Localize>
                            }
                        </Radio>
                    </Radio.Group>
                </Form.Item>
            }
            <Form.Item
                style={ contentType !== "description" ? { display : "none" } : {} }
                name="description"
                className="dark"
                    initialValue={ description }
                rules={[
                    { required: !selectedFurnishing.length, message: localizeIt("FORM_RULES.Required_JobDescription") }
                ]}
            >
                <Input.TextArea
                    placeholder={ localizeIt("FORM.Textarea_Placeholder_JobDescription") }
                    name="description"
                    rows={ 6 }
                />
            </Form.Item>
            { (job.type === "last_minutes" || job.type === "claims") &&
                <Furnishing.Blocks.Select
                    eeId={ eeId }
                    jobId={ id }
                    visible = { contentType === "furnishing" }
                    furnishings = { selectedFurnishing }
                    callback    = { setSelectedFurnishing }
                />
            }
            <Form.Item
                name="file"
                valuePropName="file"
                getValueFromEvent={ (e) => {
                    if (Array.isArray(e)) {
                        return e;
                    }
                    setFileChanged(true);
                    return e?.file;
                }}
            >
                <PreUploadImage
                    photo={ attachment }
                    onDelete={ (callback) => {
                        setFileChanged(true)
                        callback();
                    }}
                >
                    <Button
                        type = { "ghost" }
                        className = { "add-photo dark" }
                    >
                        <Icons.Photo />
                        <Localize wrap>FORM.Button_AddJobPhoto</Localize>
                    </Button>
                    <Button className="icon rounded" >
                        <SyncOutlined />
                    </Button>
                </PreUploadImage>
            </Form.Item>
            <div className="form-actions">
                <Button
                    className = "dark"
                    type = "default"
                    onClick = { callback }
                >
                    <Localize wrap>GLOBAL.Button_Text_Cancel</Localize>
                </Button>
                <Button
                    type="primary"
                    disabled={ photoLoading }
                    htmlType="submit"
                    loading={ loading }
                >
                    <Localize wrap>FORM.Button_Text_SaveJob</Localize>
                </Button>
            </div>
        </Form>
    )
}

export default JobUpdateForm;