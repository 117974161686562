import { gql } from "@apollo/client";


export const GET_DAMAGE_REPORT = gql`
    query GetDamageReport(
        $reportId: ID!
    ) {
        damageReport(
            id: $reportId
        ){
            id
            status
            total_files
            damageFiles {
                id
                title
                description
                path
                order
                number
                user{
                    id
                    name
                    surname
                }
                created_at
            }
        }
    }
`;

export const GET_DAMAGE_REPORTS = gql`
    query GetDamageReports(
        $text        : String
        $where       : QueryDamageReportsCursorWhereWhereConditions
        $orderBy     : [QueryDamageReportsCursorOrderByOrderByClause!]
        $first       : Int
        $after       : String
    ) {
        damageReportsCursor(
            text         : $text
            where        : $where
            orderBy      : $orderBy
            first        : $first
            after        : $after
        ){
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
                total
                currentPage
                lastPage
            }
            edges {
                cursor
                node {
                    id
                    status
                    total_files
                    stand {
                        id
                        exhibitor
                        block
                        stand_number
                        corridor
                        hall_name
                    }
                }
            }
        }
    }
`;