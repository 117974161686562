import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { GET_STANDS } from "graphql/query/stand-gql";

import StandGridItem from "./stand-grid-item";
import { Loader, NoData } from "components/request-result";
import { useMe, useVarParam } from "components/use-hooks";
import { Localize, OptimizedGrid } from "components/service";
import { whereConditionBuilder, restorePosition } from "utils";


const StandsGrid = ({ sort, filter }) => {

    const { me } = useMe(),
          { state = {}, pathname } = useLocation(),
          history = useHistory();

    const perPage = 30;
    const { data, loading, fetchMore } = useQuery(GET_STANDS, {
        variables: {
            where : whereConditionBuilder( filter.where ),
            whereCrafts : whereConditionBuilder( filter.whereCrafts ),
            orderBy: sort.length ? sort : [{ column: "ID", order : "DESC" }],
            first: perPage,
        },
        fetchPolicy: state.pagePosition ? "cache-first" : "cache-and-network",
        notifyOnNetworkStatusChange: true
    });

    const {
        standsCursor : {
            edges : stands = [],
            pageInfo = {}
        } = {}
    } = data ? data : {};

    const { moment } = useVarParam().get();
    const { exhibitionEvent, eeCurrentId } = me;

    if( data && state.pagePosition ){
        restorePosition(history, pathname, state);
    }

    return(
        <>
            { !stands.length && !loading &&
                <NoData
                    text={{
                        after :  <Localize br={ <br /> }>NO_DATA.Stands</Localize>
                    }}
                    icon="stand"
                />
            }
            { !!stands.length &&
                <OptimizedGrid
                    listClass = "stand-grid"
                    itemClass = "stand-grid-item-wrap"
                    gridItems = { stands }
                    pageInfo  = { pageInfo }
                    fetchMore = { fetchMore }
                    perPage   = { perPage }
                    model     = "standsCursor"

                >
                    <StandGridItem
                        moment = { moment }
                        me     = { me }
                        uniqueCraftCount = {
                            exhibitionEvent.find(
                                ({ id }) => id === eeCurrentId
                            ).craftCountUniq
                        }
                    />
                </OptimizedGrid>
            }
            { loading && <Loader type="block" /> }
        </>
    );
}

export default StandsGrid;