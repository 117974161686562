import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { mergeDeep } from "@apollo/client/utilities";

import Job from "components/job";
import { Wraps } from "components/layout";
import { localizeIt } from "components/service/localize";
import { useMe, useVarParam } from "components/use-hooks";


const JobsPage = () => {

    const appParams = useVarParam(),
        { me } = useMe();

    const { state : {
        queryParams
    } = {} } = useLocation();

    const { filter, sort } = Job.Hooks.useFilter(
        queryParams ?
            queryParams : appParams.get("rememberQueryParams").job,
    );

    useEffect( () => {
        if(filter && queryParams){
            filter.set(mergeDeep(queryParams.filter,  filter.data));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParams]);

    appParams.set({
        headerTitle: localizeIt("PAGE.Title_Jobs"),
        bottomBar: true
    });


    return (
        <Wraps.Container className="page jobs-page">
            <Job.Blocks.Filter
                filter = { filter }
                sort = { sort }
            />
            <Job.Grids.All
                filter = { mergeDeep(filter.default, filter.data) }
                sort = { sort.data }
                eeId = { me.eeCurrentId }
            />
        </Wraps.Container>
    );

}

export default JobsPage;