import { useState } from "react";
import { mergeDeep } from "@apollo/client/utilities";

import { UserHelpers } from "components/user";
import { useMe, useFilterStorage } from "components/use-hooks";


const { permission } = UserHelpers;

const useJobFilterHook = ({
        sort : sortParams = [],
        filter : filterParams = {}
    }) => {

    const { me } = useMe(),
          filterStorage = useFilterStorage();

    const defaultFilter = {
            where : {
                status  : {},
                type      : {
                    column: "TYPE",
                    operator : "NEQ",
                    value : "damages"
                },
                flag      : {},
                craftType : {}
            },
            whereCraft : {
                standId         : {},
                subcontractorId :
                    permission().has({ type: "sub_view" }) ? {
                        column: "SUBCONTRACTOR_ID",
                        operator : "EQ",
                        value : me.subcontractor.id
                    } : {},
                hallId          : {},
            },
            activeFilters : 0
        },
        defaultSort = [{ column: "CREATED_AT", order : "DESC" }];

    const [ filter, setFilter ] = useState(
            mergeDeep(defaultFilter, filterParams)
        ),
        [ sort, setSort ] = useState( [...mergeDeep(defaultSort, sortParams)] );

    filterStorage.store("job", {
        filter, sort
    })

    return {
        filter : {
            data  : filter,
            set   : setFilter,
            reset : () => setFilter(defaultFilter),
            default : defaultFilter
        },
        sort : {
            data  : sort,
            set   : setSort,
            reset : () => setSort(defaultSort),
            default : defaultSort
        }
    };
}

export default useJobFilterHook;