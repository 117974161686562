import CraftContactList from "./craft-contact-list";
import CraftOverviewFilterParamsList from "./craft-overview-filter-params-list";

import "./craft-lists.scss";


const CraftLists = {
    Contact : CraftContactList,
    OverviewFilterParams : CraftOverviewFilterParamsList
}

export default CraftLists;