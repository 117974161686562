import StandsPage from "./stands-page";
import StandPage from "./stand-page";
import StandKeysPage from "./stand-keys-page";
import StandLogPage from "./stand-log-page";


const StandPages = {
    Collection : StandsPage,
    Single     : StandPage,
    Keys       : StandKeysPage,
    Log        : StandLogPage
};

export default StandPages;
