import React from "react";
import { Switch, Route } from 'react-router-dom';


import StandRoutes from "./stand-routes";
import MoreRoutes from "./more-routes";
import { Pages } from "components/pages";
import { useMe, useFilterStorage } from "components/use-hooks";
import { UserHelpers } from "components/user";
import { checkUser } from "utils";


const { permission } = UserHelpers;

const AppRoutes = () => {

    const { me } = useMe(),
          filterStorage = useFilterStorage(),
          { hasActiveEvent } = checkUser;

    if(hasActiveEvent){
        filterStorage.restore("all");
    }

    return(
        <Switch>
            <Route path={ !hasActiveEvent(me) ? "*" : "/exhibitions" } exact>
                <Pages.Exhibitions />
            </Route>
            <Route path="/" exact>
                <Pages.Home />
            </Route>
            <Route path="/stands/">
                <StandRoutes />
            </Route>
            {permission().has({type: "craft_job", value: "view_job"}) &&
                <Route path="/jobs/" exact>
                    <Pages.Jobs.Collection />
                </Route>
            }
            <Route path="/crafts-overview" exact>
                <Pages.More.CraftsOverview />
            </Route>

            <Route path="/more/">
                <MoreRoutes />
            </Route>
            <Route path="*" >
                <Pages.Page404 />
            </Route>
        </Switch>
    );
};

export default AppRoutes;