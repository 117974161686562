import StandSearchModal from "./stand-search-modal";
import StandFilterModal from "./stand-filter-modal";
import StandSortModal   from "./stand-sort-modal";
import StandPhotoModal  from "./stand-photo-modal";
import StandKeyEditModal from "./stand-key-edit-modal";
import StandDeleteKeyModal from "./stand-delete-key-modal";

import "./stand-modals.scss";


const StandModals = {
    Search     : StandSearchModal,
    Filter     : StandFilterModal,
    Sort       : StandSortModal,
    Photo      : StandPhotoModal,
    EditKey    : StandKeyEditModal,
    DeleteKey  : StandDeleteKeyModal
}

export default StandModals;