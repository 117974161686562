import React from "react";
import { useMutation } from "@apollo/client";
import { Button } from "antd";

import { STAND_KEY_DELETE } from "graphql/mutation/stand-gql";

import { ModalStandard, Localize } from "components/service";
import { Icons } from "components/layout";
import { useNotification } from "components/use-hooks";


const ModalContent = ({ keyId, standId, title, callback }) => {

    const { nError, nSuccess } = useNotification();

    const [ standKeyDelete, { loading }] = useMutation( STAND_KEY_DELETE,
        {
            update(cache, { data }) {

                const {
                    standKeyDelete : {
                        label,
                        message
                    }
                } = data;

                callback();

                nSuccess( label, message );
            }
        }
    );

    return(
        <div className="delete-modal-content">
            <Localize
                wrap="div"
                wrapClass="text"
                keyName={ <b>{ title }</b> }
            >
                STAND.Text_ModalDelete_Question
            </Localize>
            <div className="actions grid-two-col">
                <Button
                    className = "dark"
                    type = "default"
                    onClick = { callback }
                >
                    <Localize>GLOBAL.Button_Text_Cancel</Localize>
                </Button>
                <Button
                    type = "primary"
                    onClick = { () => {
                        standKeyDelete({
                            variables: {
                                id: keyId,
                                stand_id: standId
                            }
                        }).catch((error) => nError( error ))
                    }}
                    loading={ loading }
                >
                    <Localize>STAND.Button_Text_DeleteKey</Localize>
                </Button>
            </div>
        </div>
    )
}

const StandDeleteKeyModal = (props) => {

    return(
        <div className="stand-keys-delete-modal-wrap">
            <ModalStandard
                extraClass="stand-keys-delete-modal"
                centered={ true }
                modalButton={
                    <Button type="default" className="icon round">
                        <Icons.Delete />
                    </Button>
                }
            >
                <ModalContent
                    { ...props }
                />
            </ModalStandard>
        </div>
    );
}
export default StandDeleteKeyModal;