import React from "react";

import JobInfoBlock from "./job-info-block";
import JobForms from "../forms";
import JobHelpers from "../helpers";
import Craft from "components/craft";
import { Localize } from "components/service";


const JobEditBlock = ({
        stand,
        craft,
        jobType,
        job,
        callback
    }) => {

    const {
        type : craftType,
        title : craftName,
        subcontractor
    } = craft;

    return(
        <div className="job-edit-block bg-light-bordered">
            <div className="job-edit-block-hero grid-item-hero">
                <div className={ `title` }>
                    <Localize wrap="h3">{ JobHelpers.NameByType.single({ jobType }) }</Localize>
                </div>
                <JobInfoBlock
                    { ...stand }
                />
            </div>
            <div className="job-edit-block-body">
                <div className="craft-info">
                    <Craft.Grids.Single
                        extraClass = { jobType === "damages" ? "completed" : "in_progress" }
                        type = { craftType }
                        hasJobType={ [ jobType ] }
                        title={ craftName }
                    />
                    <div>
                        <h4>{ stand.exhibitor }</h4>
                        { subcontractor
                            && <p className="subcontractor">{ subcontractor.title }</p>
                        }
                    </div>
                </div>
                { job ?
                    <JobForms.Update
                        job      = { job }
                        eeId     = { craft.ee_id }
                        callback = { callback }
                    />:
                    <JobForms.Create
                        craftId  = { craft.id }
                        eeId     = { stand.ee_id }
                        type     = { jobType }
                        callback = { callback }
                    />
                }

            </div>
        </div>

    );
}

export default JobEditBlock;