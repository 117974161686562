import React from "react";

import StandForms from "../forms";


const StandPhotoBlock = ({ stand, callback }) => {

    const {
        id,
        exhibitor  : title,
        file_photo : standPhoto
    } = stand;

    return(
        <div className="stand-photo-block">
            <h3>{ title }</h3>
            <StandForms.Photo
                id = { id }
                callback = { callback }
                standPhoto = { standPhoto }
            />
        </div>
    );
};

export default StandPhotoBlock;