import React from "react";
import { useMutation } from "@apollo/client";
import { Button } from "antd";

import { JOB_CHANGE_STATUS } from "graphql/mutation/job-gql";

import { Icons } from "components/layout";
import { useNotification } from "components/use-hooks";
import Localize from "components/service/localize";


const statuses = {
    "waiting" : "completed",
    "in_progress" : "completed",
    "completed" : "waiting"
}

const JobChangeStatusBtn = ({
        id,
        status,
        nextStatus = statuses[status],
        callback = () => {}
    }) => {

    const { nError, nSuccess } = useNotification();

    const [ jobChangeStatus, { loading }] = useMutation( JOB_CHANGE_STATUS,
        {
            update(cache, { data }) {

                const {
                    craftJobUpdateStatus : {
                        label,
                        message
                    }
                } = data;

                callback();

                nSuccess( label, message );
            }
        }
    );

    return(
        <Button
            onClick = {
                () => {
                    if(!loading){
                        jobChangeStatus({
                            variables: {
                                id,
                                status : nextStatus
                            }
                        }).catch((error) => nError( error ))
                    }
                }
            }
            // className = { `job-change-status-btn status ${ nextStatus === "waiting" ? status : nextStatus }` }
            className = { `job-change-status-btn status ${ nextStatus }` }
            type="primary"
        >
            { nextStatus === "completed" ?
                <>
                    <Icons.Check type="doubleWithCircle" loading={ loading } />
                    <Localize>JOB.Button_ChangeStatus_Done</Localize>
                </> :
                <>
                    <Icons.InProgress loading={ loading } />
                    <Localize>JOB.Button_ChangeStatus_InProgress</Localize>
                </>
            }

            {/*{ status === "waiting" ?*/}
            {/*    <>*/}
            {/*        <Icons.Check type="circle" loading={ loading } />*/}
            {/*        <Localize>JOB.Button_ChangeStatus_Waiting</Localize>*/}
            {/*    </> :*/}
            {/*    <>*/}
            {/*        { nextStatus === "in_progress" &&*/}
            {/*            <>*/}
            {/*                <Icons.InProgress loading={ loading } />*/}
            {/*                <Localize>JOB.Button_ChangeStatus_InProgress</Localize>*/}
            {/*            </>*/}
            {/*        }*/}
            {/*        { nextStatus === "completed" &&*/}
            {/*            <>*/}
            {/*                <Icons.Check type="doubleWithCircle" loading={ loading } />*/}
            {/*                <Localize>JOB.Button_ChangeStatus_Done</Localize>*/}
            {/*            </>*/}
            {/*        }*/}
            {/*    </>*/}
            {/*}*/}

        </Button>
    )
}

export default JobChangeStatusBtn