import React from 'react';
import Icon from '@ant-design/icons';


const ThreeDotSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <circle cx="6" cy="24.1" r="4.3"/>
        <circle cx="23.9" cy="24.1" r="4.3"/>
        <circle cx="41.8" cy="24.1" r="4.3"/>
    </svg>
);

const ThreeDotIcon = props => {
    return <Icon component={ ThreeDotSvg } className={ props.className } />;

};


export default ThreeDotIcon;