import React from 'react';
import Icon from '@ant-design/icons';


const MagnifyingGlassSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 176 176" strokeWidth="1" >
        <circle style={ {  opacity: 0.08 } }  cx="76.4" cy="59.7" r="46.6"/>
        <path d="M76.4,106.8c-15.5,0-30.7-7.7-39.7-21.7c-14-21.9-7.6-51,14.2-65c21.9-14,51-7.6,65,14.2
		c6.8,10.6,9,23.2,6.4,35.5c-2.7,12.3-10,22.8-20.6,29.6C93.9,104.4,85.1,106.8,76.4,106.8z M76.3,13.6c-8.5,0-17.2,2.4-24.8,7.3
		C30,34.6,23.7,63.2,37.5,84.6c13.7,21.4,42.4,27.7,63.8,14c10.4-6.7,17.6-17,20.2-29c2.6-12,0.4-24.4-6.2-34.8l0,0
		C106.4,21.1,91.5,13.6,76.3,13.6z"/>
        <path d="M154.2,161.7l-22-34.4c-1.3-2-3.2-3.3-5.6-3.9c-1.2-0.3-2.4-0.3-3.5-0.1L111.9,106
		c10.9-8.4,18.5-20.2,21.4-33.8c3.3-15.2,0.5-30.8-7.9-44C108.1,1.2,72-6.8,44.9,10.6C17.8,28,9.9,64.1,27.2,91.2
		c11.1,17.4,30,26.8,49.2,26.8c10.3,0,20.8-2.8,30.2-8.5l11.1,17.2c-0.7,1-1.3,2.2-1.6,3.4c-0.5,2.3-0.1,4.7,1.2,6.7l22,34.4
		c1.7,2.6,4.5,4.1,7.5,4.1c1.6,0,3.3-0.5,4.8-1.4C155.7,171.3,156.9,165.8,154.2,161.7z M28,90.7c-17.1-26.7-9.3-62.3,17.4-79.4
		c9.6-6.1,20.3-9.1,30.9-9.1c18.9,0,37.5,9.3,48.5,26.4c17.1,26.7,9.3,62.3-17.4,79.4C80.7,125.2,45.1,117.4,28,90.7z M107.4,109.1
		c0.1-0.1,0.3-0.2,0.4-0.2c1.2-0.7,2.3-1.5,3.4-2.3l11,17.2c-0.8,0.2-1.5,0.6-2.2,1c-0.6,0.4-1.2,0.9-1.7,1.4L107.4,109.1z
		 M151.1,173.1c-3.7,2.4-8.6,1.3-11-2.4l-22-34.4c-1.1-1.8-1.5-3.9-1.1-6c0.3-1.2,0.8-2.3,1.6-3.3c0,0,0.1-0.1,0.1-0.1
		c0.5-0.6,1.1-1.2,1.8-1.6c1.3-0.8,2.8-1.3,4.3-1.3c2.6,0,5.2,1.3,6.7,3.6l22,34.4C155.8,165.9,154.8,170.8,151.1,173.1z"/>
        <path d="M38.7,56C38.7,56,38.7,56,38.7,56c-0.3,0-0.5-0.3-0.4-0.5c1.3-11.5,7.6-21.7,17.4-28c0.2-0.1,0.5-0.1,0.6,0.1
		c0.1,0.2,0.1,0.5-0.1,0.6c-9.5,6.1-15.7,16.1-17,27.3C39.2,55.8,39,56,38.7,56z"/>
    </svg>
);

const MagnifyingGlassIcon = props => {
    return <Icon component={ MagnifyingGlassSvg } className={ props.className } />;

};


export default MagnifyingGlassIcon;