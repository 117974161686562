import React from "react";

import StandHelpers from "../../helpers";
import { FilterTag } from "components/service";
import { Localize } from "components/service";


const StandFilterParamsList = ({ filter }) => {

    let { data, set } = filter;
    const { typeFilter } = StandHelpers;

    const tagArr = [];

    for (let [key, item] of Object.entries(data.where)) {
        if(item.label){
            tagArr.push(
                <FilterTag
                    key={ key }
                    action={ () => {
                        data.where[key] = {};
                        set({ ...data, ...filter })
                    } }
                >
                    { item.label }
                </FilterTag>
            )
        }
    }

    if(data.whereCraftIdx !== undefined){

        tagArr.push(
            <FilterTag
                key={ "whereCraftIdx" }
                action={ () => {
                    data.whereCraftIdx = undefined;
                    set({ ...data, ...filter })
                } }
            >
                <Localize withFilter={ <Localize wrap>{ typeFilter.options[data.whereCraftIdx].label }</Localize> }>
                    STAND.Filter_Params_Stand
                </Localize>
            </FilterTag>
        )
    }

    if(!tagArr.length){
        return null;
    }

    return(
        <div className="filter-params-list">
            { tagArr }
        </div>
    );
}

export default StandFilterParamsList;