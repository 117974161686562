import StandsGrid from "./stands-grid";
import StandsSimpleGrid from "./stands-simple-grid";
import StandLogGrid from "./stand-log-grid";

import "./stand-grids.scss";


const StandGrids = {
    All       : StandsGrid,
    SimpleAll : StandsSimpleGrid,
    Log       : StandLogGrid
};

export default StandGrids;