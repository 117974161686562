import { gql } from '@apollo/client';


export const GET_SUBCONTRACTORS = gql`
    query SubcontractorsCursor(
        $eeId        : ID!
        $first       : Int
        $after       : String
    ) {
        subcontractorsCursor(
            ee_id      : $eeId
            first      : $first
            after      : $after
        ) {
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
                total
                currentPage
                lastPage
            }
            edges{
                node{
                    id
                    title
                    number
                }
            }
        }
    }
`;