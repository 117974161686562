import React from "react";

import CraftGrids from "../../grids";
import { useVarParam, useMe } from "components/use-hooks";
import { Icons } from "components/layout";
import { Localize } from "components/service";


const CraftContactListItem = ({
        id,
        title,
        type,
        status,
        complete_at,
        subcontractor,
        hasPermission,
        setCraft,
        ...props
    }) => {

    const moment = useVarParam().get("moment"),
          { me } = useMe(),
          { users = [] } = subcontractor ? subcontractor : {} ;


    let user = {};

    if(users.length >= 1){
        [ user = {} ] = users.filter(
            ( user ) => {
                const { exhibitionEvent : ee } =  user;

                return ee.find( event => event.id === me.eeCurrentId );
            }
        )
    }

    return(
        <li className="craft-contact-list-item bg-light-bordered">
            <div className="icon">
                <CraftGrids.Single
                    extraClass = { status }
                    wrapped={ hasPermission }
                    type = { type }
                    title={ title }
                    action = {
                        () => {
                            setCraft({id, status, title, type, ...props });
                        }
                    }
                    { ...props }
                />
            </div>
            <div className="complete-at">
                <p className="time">{ moment(complete_at).format("HH:mm") }</p>
                <p className="date">{ moment(complete_at).format("DD.MM.YYYY") }</p>
            </div>
            <div className="subcontractor">
                { subcontractor ?
                <>
                    <h5 className="text-label">{ subcontractor.title }</h5>
                    {  !!users.length &&
                    <div className="group">
                        { user.phone &&
                            <a
                                href={ `tel:${ user.phone.indexOf('+') > -1 ? user.phone : "+49" + user.phone }` }
                                className="link-button icon rounded-primary"
                            >
                                <Icons.Phone />
                            </a>
                        }
                        { user.email &&
                            <a
                                href={ `mailto:${user.email}` }
                                className="link-button icon rounded-primary"
                            >
                                <Icons.Email/>
                            </a>
                        }
                    </div>
                    }
                </> : <Localize wrap="h4" wrapClass="text-error">NO_DATA.Subcontractor_Unsigned</Localize>
                }
            </div>
        </li>
    )
}

export default CraftContactListItem;