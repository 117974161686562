import { useHistory } from "react-router-dom";

import useVarParam from "./use-var-param";
import { checkUser } from "utils";


const useAuthRedirect = () => {

    const history = useHistory(),
        guestParams = useVarParam("guest"),
        { hasActiveEvent } = checkUser;

    return ({ user }) => {

        if(!hasActiveEvent( user )){
            return history.replace("/exhibitions");
        }

        if( guestParams.get("comeFrom") ){
            return  history.replace(guestParams.get("comeFrom"));
        }

        return history.replace("/");
    };

}

export default useAuthRedirect;