import React, { useState } from "react";
import { Button, Upload } from "antd";

import { Icons } from "../layout";
import ImageLoader from "./image-loader";


const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};


const PreUploadImage = ({
        extraClass = "",
        accept = 'image/*',
        children,
        disabled,
        onDelete = () => {},
        noData,
        photo = undefined,
        extraAction,
        ...props
    }) => {

    const [ imageUrl, setImageUrl ] = useState(photo);

    const uploadProps = {
        ...props,
        className: `${extraClass}${ !imageUrl ? " empty" : "" }`,
        disabled,
        accept: accept,
        customRequest: () => {
            return false;
        },
        showUploadList: false,
        multiple: false,
        onChange : (e) => {

            getBase64(e.file.originFileObj, (url) => {
                setImageUrl(url);
            });

            props.onChange(e);
        }
    };

    const BtnBeforeChosen = () => children[0],
          BtnAfterChosen = () => children[1];

    return(
        <div className="pre-upload-image">
            { !imageUrl && noData &&
                <>{ noData }</>
            }
            { imageUrl &&
                <ImageLoader
                    image     = { imageUrl }
                    altText   = "pre-uploaded-img"
                    wrapClass = "image-preview"
                />
            }
            <div className="actions">
                <div className="group">
                    <Upload {...uploadProps}>
                        { !imageUrl ? <BtnBeforeChosen/> : <BtnAfterChosen /> }
                    </Upload>
                    { !!imageUrl &&
                        <Button
                            className="icon"
                            onClick = { () => {
                                onDelete(
                                    () => {
                                        setImageUrl(undefined)
                                        props.onChange(undefined)
                                    }
                                );
                            } }
                        >
                            <Icons.Delete />
                        </Button>
                    }
                </div>
                { (extraAction && imageUrl) && extraAction }
            </div>
        </div>
    );
};


export default PreUploadImage;