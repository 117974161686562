import DamageStatusSegmentedBtn from "./damage-status-segmented-btn";
import DamageAddPhotoBtn from "./damage-add-photo-btn";
import DamageUpdateDescriptionBtn from "./damage-update-description-btn";
import DamageDeletePhotoBtn from "./damage-delete-photo-btn";

import "./damage-buttons.scss";


const DamageButtons = {
    SegmentedStatus   : DamageStatusSegmentedBtn,
    AddPhoto          : DamageAddPhotoBtn,
    DeletePhoto       : DamageDeletePhotoBtn,
    UpdateDescription : DamageUpdateDescriptionBtn
};

export default DamageButtons;