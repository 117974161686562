import React from "react";
import { Link, useLocation } from "react-router-dom";

import DamageModals from "../modals";
import DamageHelpers from "../helpers";
import { Icons } from "components/layout";
import { Localize } from "components/service";


const DamagePreviewBlock = ({
        reportId,
        stand,
        count,
        status,
        withStandLink = false
    }) => {

    const location = useLocation(),
          label = DamageHelpers.Status.Label(status);

    return(
        <div className={ `damage-preview-block ${ status }` }>
            <div className="status-wrap">
                <button>
                    <DamageHelpers.Status.Icon
                        status={ status }
                    />
                    <Localize wrapClass="status" wrap>
                        { label }
                    </Localize>
                </button>
            </div>
            <div className="count">
                { count }
            </div>
            <div className="action">
                { withStandLink &&
                    <Link
                        className="link-button-ghost dark"
                        to={() => {
                            return {
                                pathname: `/stands/${ stand.id }`,
                                state: {
                                    from : location.pathname,
                                    returnPagePosition : window.scrollY
                                }
                            }
                        }}
                    >
                        <Icons.Stand />
                        <Localize>DAMAGE.Button_Text_Stand</Localize>
                    </Link>
                }
                <DamageModals.Photo
                    reportId = { reportId }
                    stand    = { stand }
                />
            </div>
        </div>
    );
}

export default DamagePreviewBlock;