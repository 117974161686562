import { gql } from '@apollo/client';


export const GET_UNIQ_TYPE = gql`
    query CraftUniqType(
        $eeId : ID!
    ) {
        craftUniqType(
            ee_id : $eeId
        ) {
            type
        }
    }
`;


export const GET_CRAFTS_OVERVIEW = gql`
    query GetCraftsOverview(
        $eeId: ID!
        $hallId: ID
    ) {
        craftsOverview(
            ee_id: $eeId
            hall_id: $hallId
        ){
            id
            type
        }
    }
`;