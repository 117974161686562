import React from "react";
import { Button } from "antd";

import FurnishingLists from "../../lists";
import { ModalStandard, Localize } from "components/service";

import { useWindowDimensions } from "components/use-hooks";


const FurnishingSelectModal = (props) => {

    const { width } = useWindowDimensions();

    return(
        <div className="furnishing-select-modal-wrap">
            <ModalStandard
                extraClass={ `furnishing-select-modal grey ${ width <= 768 ? "full" : "" }` }
                title={
                    <Localize>FURNISHING.Modal_Title_FurnishingStore</Localize>
                }
                centered={ !(width <= 768) }
                modalButton={
                    <Button type="ghost" className="furnishing-select-button dark">
                        <Localize>FURNISHING.Button_Text_ChooseFurnishing</Localize>
                    </Button>
                }
            >
                <FurnishingLists.Simple
                    loaderType="simple"
                    { ...props }
                />
            </ModalStandard>
        </div>
    );
};

export default FurnishingSelectModal;