import React from 'react';
import Icon from '@ant-design/icons';


const FurnishingSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M42.7,16.7h-2.7v-4.7c0-2.9-2.4-5.2-5.2-5.2H13.2C10.3,6.9,8,9.2,8,12.1v4.7H5.4c-1.9,0-3.5,1.6-3.5,3.6
	c0,1.9,1.6,3.5,3.5,3.5h0.5v12.3c0,2,1.6,3.6,3.6,3.6h0.1c0.1,0,0.3,0,0.4,0h0.1v2.4c0,0.7,0.6,1.2,1.2,1.2s1.2-0.6,1.2-1.2v-2.4H36
	v2.4c0,0.7,0.6,1.2,1.2,1.2s1.2-0.6,1.2-1.2v-2.4c0.9-0.1,1.8-0.5,2.4-1.2c0.8-0.8,1.2-1.8,1.2-2.9V23.8h0.5c1.9,0,3.5-1.6,3.5-3.6
	C46.2,18.3,44.6,16.7,42.7,16.7z M13.2,9.4h21.6c1.5,0,2.7,1.2,2.7,2.7v4.7c-1.8,0.1-3.3,1.6-3.3,3.6c0,0,0,0,0,0s0,0,0,0v4.6H13.9
	v-4.5c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.2c0-1.9-1.5-3.4-3.4-3.5v-4.7C10.5,10.6,11.7,9.4,13.2,9.4z M13.9,27.4h20.3v4.4H13.9V27.4z
	 M42.7,21.3H41c-0.7,0-1.2,0.6-1.2,1.2v13.1c0,0.4-0.2,0.8-0.5,1.1c-0.3,0.3-0.7,0.5-1.1,0.5h-28c0,0-0.1,0-0.2,0
	c-0.1,0-0.1,0-0.2,0H9.6c-0.6,0-1.1-0.5-1.1-1.1V22.5c0-0.7-0.6-1.2-1.2-1.2H5.4c-0.5,0-1-0.4-1-1.1c0-0.5,0.4-1,1-1h5
	c0.5,0,0.9,0.4,1,1c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1V33c0,0.7,0.6,1.2,1.2,1.2h22.8c0.7,0,1.2-0.6,1.2-1.2V20.4
	c0,0,0,0,0-0.1c0-0.1,0-0.2,0-0.3c0,0.1,0,0.2,0,0.2c0-0.5,0.4-1,1-1h5c0.5,0,1,0.4,1,1.1C43.7,20.9,43.3,21.3,42.7,21.3z"/>
    </svg>
);

const FurnishingIcon = props => {
    return <Icon component={ FurnishingSvg } className={ props.className } />;

};

export default FurnishingIcon;