import React from "react";
import { Select } from "antd";

import { Icons } from "components/layout";
import Localize, { localizeIt } from "components/service/localize";


const { Option } = Select;

const CraftStatusSelectField = ({ ...props }) => {

    return(
        <Select
            className={ `craft-status-select-field` }
            suffixIcon={
                <Icons.Arrow type="filled"/>
            }
            defaultValue={ null }
            { ...props }
        >
            <Option value={ null }>
                <Localize>FORMS.Select_DefaultValue_All</Localize>
            </Option>
            <Option value={ `in_progress:${ localizeIt( "CRAFT.Status_Text_InProgress" ) }` }>
                <Localize>CRAFT.Status_Text_InProgress</Localize>
            </Option>
            <Option value={ `completed:${ localizeIt( "CRAFT.Status_Text_Completed" ) }` }>
                <Localize>CRAFT.Status_Text_Completed</Localize>
            </Option>
            <Option value={ `overdue:${ localizeIt( "CRAFT.Status_Text_Overdue" ) }` }>
                <Localize>CRAFT.Status_Text_Overdue</Localize>
            </Option>
        </Select>
    );
};

export default CraftStatusSelectField;