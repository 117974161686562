import React from "react";
import { Select } from "antd";

import { Icons } from "components/layout";
import { Localize } from "components/service";


const { Option } = Select;

const FurnishingOrderTypeSelectField = ({ ...props }) => {


    return(
        <Select
            className={ `furnishing-action-type-select-field` }
            suffixIcon={
                <Icons.Arrow type="filled"/>
            }
            { ...props }
        >
            <Option value={ "refund" }>
                <Localize>FORMS.Select_FurnishingOrder_Type_Return</Localize>
            </Option>
            <Option value={ "correction" }>
                <Localize>FORMS.Select_FurnishingOrder_Type_Correction</Localize>
            </Option>
        </Select>
    );
}

export default FurnishingOrderTypeSelectField;