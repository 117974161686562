import React from "react";
import { useMutation } from "@apollo/client";
import { Button } from "antd";

import { DAMAGE_FILE_UPLOAD } from "graphql/mutation/damages-gql";

import { Icons } from "components/layout";
import { Localize, UploadFile } from "components/service";
import { useNotification } from "components/use-hooks";


const DamageAddPhotoBtn = ({ reportId }) => {

    const { nError, nSuccess } = useNotification();

    const [ damageFileUpload, { loading }] = useMutation( DAMAGE_FILE_UPLOAD,
        {
            update(cache, { data }) {

                const {
                    damageReportFileUpload : {
                        label,
                        message
                    }
                } = data;


                nSuccess( label, message );
            },
            onError(error) {
                nError(error);
            },
        }
    );


    return(
        <UploadFile
            loading={ loading }
            uploadMutation={ damageFileUpload }
            variables={ {
                input : {
                    damage_report_id : reportId
                }
            }}
        >
            <Button
                type      = "primary"
                className = { "damage-add-photo-btn" }
            >
                <Icons.Photo loading={ loading } />
                <Localize wrap>DAMAGE.Modal_Button_AddPhoto</Localize>
            </Button>
        </UploadFile>

    )
};


export default DamageAddPhotoBtn;