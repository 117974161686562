import React from "react";

import CraftForms from "../../forms";
import { FilterModalStandard } from "components/service";


const CraftOverviewFilterModal = (props) => {

    return(
        <FilterModalStandard
            wrapClass="craft-overview-filter-modal-wrap"
            modalClass="craft-overview-filter-modal"
        >
            <CraftForms.OverviewFilter
                { ...props }
            />
        </FilterModalStandard>
    );
};

export default CraftOverviewFilterModal;