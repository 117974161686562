import React from 'react';
import Icon from '@ant-design/icons';


const ClaimsSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M44.8,36.6L27.4,6.5c-0.7-1.2-1.9-1.9-3.3-1.9h0c-1.4,0-2.6,0.7-3.3,1.9L3.4,36.6c-0.7,1.2-0.7,2.6,0,3.9
		c0.7,1.2,1.9,1.9,3.3,1.9h34.8c1.4,0,2.6-0.7,3.3-1.9C45.5,39.3,45.5,37.8,44.8,36.6z M7,38.4L24.1,8.7l17.1,29.7H7z"/>
        <path d="M21.9,17.7v9.8c0,1.1,0.9,2,2,2s2-0.9,2-2v-9.8c0-1.1-0.9-2-2-2S21.9,16.6,21.9,17.7z"/>
        <path d="M23.9,31.3c-1.1,0-2,0.9-2,2s0.9,2,2,2c1.1,0,2-0.9,2-2S25,31.3,23.9,31.3z"/>
    </svg>
);

const MPClaimsSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M22.5,23.6v9.7c0,0.9,0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6v-9.7c0-0.9-0.7-1.6-1.6-1.6
		C23.2,22,22.5,22.7,22.5,23.6z"/>
        <path d="M24,36.1c-0.9,0-1.6,0.7-1.6,1.6v0.6c0,0.9,0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6v-0.6
		C25.6,36.8,24.9,36.1,24,36.1z"/>
        <path d="M41.7,42L25.4,13.7c-0.3-0.5-0.8-0.8-1.4-0.8c-0.6,0-1.1,0.3-1.4,0.8L6.3,42c-0.3,0.5-0.3,1.1,0,1.6
		c0.3,0.5,0.8,0.8,1.4,0.8h32.7c0.6,0,1.1-0.3,1.4-0.8C42,43.1,42,42.5,41.7,42z M10.4,41.2L24,17.6l13.6,23.6H10.4z"/>
        <path d="M6.9,5.3v4.9h3.4V5.3c0-0.6,0.6-0.6,0.6-0.6h0.9c0.6,0,0.6,0.6,0.6,0.6v4.9h1.1h0.6H16l0-5
		c0-0.9-0.7-1.7-1.7-1.7v0h-13v6.7h3.4V5.3c0-0.6,0.6-0.6,0.6-0.6h0.9C6.9,4.6,6.9,5.3,6.9,5.3z"/>
        <path d="M20.5,10.2h4v0c0.9,0,1.6-0.7,1.6-1.6l0-3.4c0-0.9-0.7-1.7-1.6-1.7v0h-7.3v6v0.7v3.4h3.3V10.2z M20.5,5.3
		c0-0.6,0.6-0.6,0.6-0.6H22c0.6,0,0.6,0.6,0.6,0.6v3.2c0,0.6-0.6,0.6-0.6,0.6h-0.9c-0.6,0-0.6-0.6-0.6-0.6V5.3z"/>
    </svg>
);

const CustomerClaimsSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M12.5,16.9h0.1c0.5,0,1-0.1,1.5-0.2c1.1-0.3,2.5-0.9,2.5-2c0-0.3-0.1-1-1.1-1.2l-0.5,0l-0.1,0.1c-1.3,0.8-2.7,1-4,0.3
		c-1.3-0.8-1.9-1.9-1.9-3.3c0-0.7,0.2-1.4,0.6-2.1c0.9-1.1,1.9-1.6,3-1.6c0.4,0,0.8,0.1,1.4,0.3L15,7.7l0.3,0
		c0.8-0.1,1.3-0.6,1.3-1.4c0-0.2-0.1-0.4-0.2-0.7l-0.1-0.1l-0.1-0.1c-2.1-1.5-4.7-1.7-7-0.2c-2,1.4-3,3.2-3,5.3c0,1.2,0.3,2.3,1,3.5
		C8.6,15.9,10.4,16.9,12.5,16.9z"/>
        <path d="M23.1,25.4v9.7c0,0.9,0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6v-9.7c0-0.9-0.7-1.6-1.6-1.6C23.8,23.9,23.1,24.6,23.1,25.4z"
        />
        <path d="M24.7,37.9c-0.9,0-1.6,0.7-1.6,1.6v0.6c0,0.9,0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6v-0.6C26.2,38.7,25.5,37.9,24.7,37.9z"
        />
        <path d="M42.3,43.9L26,15.5c-0.3-0.5-0.8-0.8-1.4-0.8c-0.6,0-1.1,0.3-1.4,0.8L6.9,43.9c-0.3,0.5-0.3,1.1,0,1.6
		c0.3,0.5,0.8,0.8,1.4,0.8H41c0.6,0,1.1-0.3,1.4-0.8C42.6,45,42.6,44.3,42.3,43.9z M11,43.1l13.6-23.6l13.6,23.6H11z"/>
    </svg>
);


const ClaimsIcon = props => {

    let icon = "";

    switch (props.type){
        case "mpClaims"       : icon = MPClaimsSvg; break;
        case "customerClaims" : icon = CustomerClaimsSvg; break;
        default                : icon = ClaimsSvg;
    }

    return <Icon component={ icon } className={ props.className } />;
};


export default ClaimsIcon;