import FurnishingTabs from "./tabs";
import FurnishingButtons from "./buttons";
import FurnishingLists from "./lists";
import FurnishingModals from "./modals";
import FurnishingForms from "./forms";
import FurnishingBlocks from "./blocks";
import FurnishingFields from "./fields";


const Furnishing = {
    Tabs    : FurnishingTabs,
    Fields  : FurnishingFields,
    Buttons : FurnishingButtons,
    Lists   : FurnishingLists,
    Modals  : FurnishingModals,
    Forms   : FurnishingForms,
    Blocks  : FurnishingBlocks
}

export default Furnishing;