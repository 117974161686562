import React, { useState } from "react";

import Job from "components/job";
import Exhibition from "components/exhibition";
import Damage from "components/damage";
import { Wraps } from "components/layout";
import { localizeIt } from "components/service/localize";
import { useMe, useVarParam } from "components/use-hooks";


const { Helpers : { EE } } = Exhibition;

const ViewDamagesPage = () => {

    const appParams = useVarParam(),
        { me } = useMe(),
        [ filter ] = useState(
            EE.damageOldOrNew(appParams) === "new" ? {
                where : {
                    status : {
                        column: "STATUS",
                        operator : "NOT_IN",
                        value : ["delete"]
                    },
                    eeId : {
                        column: "EE_ID",
                        operator : "EQ",
                        value : me.eeCurrentId
                    }
                }
            } : {
            where : {
                status  : {},
                type      : {
                    column: "TYPE",
                    operator : "IN",
                    value : ["damages"]
                },
                craftType : {}
            },
            whereCraft: {}
        });

    appParams.set({
        headerTitle: localizeIt("PAGE.Title_ViewDamages"),
        bottomBar: true
    });


    return (
        <Wraps.Container className="page view-damages-page">
            { EE.damageOldOrNew(appParams) === "new"  ?
                <Damage.Grids.All
                    filter = { filter }
                />:
                <Job.Grids.All
                    filter = { filter }
                    eeId = { me.eeCurrentId }
                />
            }

        </Wraps.Container>
    );

}

export default ViewDamagesPage;