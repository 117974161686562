import React, { useState } from "react";

import CraftContactListItem from "./craft-contact-list-item";
import CraftModals from "../../modals";
import { UserHelpers } from "components/user";


const { permission } = UserHelpers;

const CraftContactList = ({ crafts, stand }) => {

    const [ craftData, setCraftData ] = useState();

    if(!crafts){
        return null;
    }

    return(
        <>
            <ul className="craft-contact-list">
                { crafts.map(
                    (  craft ) => {

                        let hasPermission = permission().hasOneOf( [
                            { value: "claims" },
                            { value: "customer_claims" },
                            { value: "last_minutes" },
                            { value: "damages" },
                        ]);

                        if(craft.type === "protocol"){
                            hasPermission = permission().hasOneOf( [
                                { value: "protocol_defects" }
                            ])
                        }

                        if(permission().has({ type: "sub_view" })){
                            hasPermission = permission().equalSubId( craft.subcontractor_id )
                        }

                        return(
                            <CraftContactListItem
                                key={ craft.id }
                                setCraft = { setCraftData }
                                hasPermission={ hasPermission }
                                { ...craft }
                            />
                        )
                    })
                }
            </ul>
            { !!craftData &&
                <CraftModals.Actions
                    stand = { stand }
                    craftData = { craftData }
                    changeStatusOn = { true }
                />
            }
        </>
    );
}

export default CraftContactList;