import LogoIcon from "./logo-icon";
import CrossIcon from "./cross-icon";
import ArrowIcon from "./arrow-icon";
import CraftIcon from "./crafts-icon";
import HomeIcon from "./home-icon";
import StandsIcon from "./stands-icon";
import JobsIcon from "./jobs-icon";
import PlanIcon from "./plan-icon";
import MenuIcon from "./menu-icon";
import ClaimsIcon from "./claims-icon";
import WarningIcon from "./warning-icon";
import SmileIcon from "./smile-icon";
import LastMinuteIcon from "./last-minute-icon";
import MangelIcon from "./mangel-icon";
import InProgressIcon from "./in-progress-icon";
import ClockIcon from "./clock-icon";
import ProtocolIcon from "./protocol-icon";
import ControlIcon from "./control-icon";
import MagnifyingGlassIcon from "./magnifying-glass-icon";
import LogoutIcon from "./logout-icon";
import ThreeDotIcon from "./three-dot-icon";
import FilterIcon from "./filter-icon";
import SearchIcon from "./search-icon";
import PhotoIcon from "./photo-icon";
import SortIcon from "./sort-icon";
import DamagesIcon from "./damages-icon";
import InfoIcon from "./info-icon";
import ListIcon from "./list-icon";
import DownloadIcon from "./download-icon";
import ToolsIcon from "./tools-icon";
import EyeIcon from "./eye-icon";
import TurnIcon from "./turn-icon";
import EmailIcon from "./email-icon";
import PhoneIcon from "./phone-icon";
import DocumentsIcon from "./documents-icon";
import KeyIcon from "./key-icon";
import PictureIcon from "./picture-icon";
import NoContentIcon from "./no-content-icon";
import DeleteIcon from "./delete-icon";
import EditIcon from "./edit-icon";
import DocumentIcon from "./document-icon";
import CheckIcon from "./check-icon";
import StandIcon from "./stand-icon";
import FlagIcon from "./flag-icon";
import FurnishingIcon from "./furnishing-icon";
import QuestionIcon from "./question-icon";


const Icons = {
    Logo       : LogoIcon,
    Check      : CheckIcon,
    Cross      : CrossIcon,
    Arrow      : ArrowIcon,
    Craft      : CraftIcon,
    Home       : HomeIcon,
    Stand      : StandIcon,
    Stands     : StandsIcon,
    Jobs       : JobsIcon,
    Plan       : PlanIcon,
    Menu       : MenuIcon,
    Claims     : ClaimsIcon,
    Warning    : WarningIcon,
    Smile      : SmileIcon,
    LastMinute : LastMinuteIcon,
    Mangel     : MangelIcon,
    InProgress : InProgressIcon,
    Clock      : ClockIcon,
    Protocol   : ProtocolIcon,
    Control    : ControlIcon,
    Logout     : LogoutIcon,
    ThreeDot   : ThreeDotIcon,
    Filter     : FilterIcon,
    Search     : SearchIcon,
    Photo      : PhotoIcon,
    Sort       : SortIcon,
    Damages    : DamagesIcon,
    Info       : InfoIcon,
    List       : ListIcon,
    Download   : DownloadIcon,
    Tools      : ToolsIcon,
    Eye        : EyeIcon,
    Turn       : TurnIcon,
    NoContent  : NoContentIcon,
    Email      : EmailIcon,
    Phone      : PhoneIcon,
    Documents  : DocumentsIcon,
    Document   : DocumentIcon,
    Key        : KeyIcon,
    Picture    : PictureIcon,
    Edit       : EditIcon,
    Delete     : DeleteIcon,
    Flag       : FlagIcon,
    Question   : QuestionIcon,
    Furnishing : FurnishingIcon,
    MagnifyingGlass : MagnifyingGlassIcon
}

export default Icons;