import React from 'react';
import Icon from '@ant-design/icons';


const CrossSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M35,11.6L24.4,22.2L13.8,11.6c-0.8-0.8-2-0.8-2.8,0c-0.8,0.8-0.8,2,0,2.8L21.6,25L11,35.7c-0.8,0.8-0.8,2,0,2.8
	c0.8,0.8,2,0.8,2.8,0l10.6-10.6L35,38.5c0.8,0.8,2,0.8,2.8,0s0.8-2,0-2.8L27.2,25l10.6-10.6c0.8-0.8,0.8-2,0-2.8
	C37.1,10.8,35.8,10.8,35,11.6z"/>
    </svg>
);

const CrossIcon = props => {
    return <Icon component={ CrossSvg } className={ props.className } />;

};


export default CrossIcon;