import ContainerWrap from "./container-wrap";
import WrapColumn from "./wrap-column";
import TwoColWrap from "./two-col-wrap";

const Wraps = {
    Container : ContainerWrap,
    TwoCol    : TwoColWrap,
    WrapColumn,
}

export default Wraps;