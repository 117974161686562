import React from "react";
import { InputNumber } from "antd";


const InputNumberWithControl = ({ value = 0, onChange, ...props }) => {

    return(
        <div className="input-with-control">
            <InputNumber
                { ...props }
                value={ value }
                onChange = {
                    (val) => {
                        onChange(val);
                    }
                }
            />
            <div className="control">
                <button
                    type='button'
                    className="minus"
                    onClick={  () => {
                        if(value > props.min){
                            onChange(value - 1);
                        }
                    } }
                ><span>-</span></button>
                <button
                    type='button'
                    className="plus"
                    onClick={  () => {
                        if(value < props.max){
                            onChange(value + 1);
                        }
                    } }
                ><span>+</span></button>
            </div>
        </div>
    )
}

export default InputNumberWithControl;