import React, { useState } from "react";
import { Button, Form } from "antd";

import Hall from "components/hall";
import Localize from "components/service/localize";


const formFinish = (values, data, set, callback, changedFieldsCount) => {

    const [ hallId, hallName ] = values.hallId ?  values.hallId.split(":") : [];

    set( {
        ...data,
        hallId : values.hallId ? {
            label    : hallName,
            column   : "HALL_ID",
            operator : "EQ",
            value    : hallId,
        } : {},
        activeFilters : changedFieldsCount
    })

    callback();
}

const CraftOverviewFilterForm = ({ filter, callback }) => {

    const { data, set, reset } = filter,
        {
            hallId,
            activeFilters
        } = data;


    const [ changedFieldsCount, setChangedFieldsCount ] = useState( activeFilters );


    return(
        <Form
            key="craft-overview-filter-form"
            layout="vertical"
            className="craft-overview-form"
            onFinish={ (values) => {
                formFinish( values, data, set, callback, changedFieldsCount );
            } }
            onValuesChange={ (changedValues, allValues) => {
                const activeFiltersCount = Object.values(allValues).filter( (item) => item !== null).length;

                setChangedFieldsCount(activeFiltersCount);
            }}
        >
            <Form.Item
                name="hallId"
                label={ <Localize>FORMS.Input_Label_Hall</Localize> }
                className="dark"
                initialValue={ hallId && hallId.value  ? `${hallId.value}:${hallId.label}` : null }
            >
                <Hall.Fields.Select />
            </Form.Item>
            <div className="form-actions">
                { (!changedFieldsCount ) ?
                    <Button
                        className = "dark"
                        type = "default"
                        onClick = { callback }
                    >
                        <Localize wrap>GLOBAL.Button_Text_Cancel</Localize>
                    </Button> :
                    <>
                        <Button
                            className = "dark"
                            type = "default"
                            onClick = { () => {
                                reset();
                                callback();
                            }}
                        >
                            <Localize wrap>FORMS.Button_Text_ResetFilters</Localize>
                        </Button>
                        <Button
                            type="primary"
                            htmlType="submit"
                        >
                            <Localize wrap>FORMS.Button_Text_ApplyFilters</Localize>
                        </Button>
                    </>
                }
            </div>
        </Form>
    );
}

export default CraftOverviewFilterForm;