import React from "react";

import CraftButtons from "../../buttons";
import Damage from "components/damage";
import Job from "components/job";
import { UserHelpers } from "components/user";
import Exhibition from "components/exhibition";
import { useVarParam } from "components/use-hooks";

const { permission }       = UserHelpers;
const { Helpers : { EE } } = Exhibition;

const CraftActionsModalList = ( props ) => {

    const varParams = useVarParam();

    return(
        <ul className="craft-actions-modal-list">
            { props.changeStatusOn && permission().has({ value: "change_craft_status" }) &&
                <li>
                    { props.craftData.type !== "damage" &&
                        <CraftButtons.ChangeStatus
                            { ...props.craftData }
                            callback={ props.callback }
                        />
                    }

                </li>
            }
            { props.craftData.type !== "protocol" && props.craftData.type !== "damage"&&
                <>
                    { permission().has({ value: "claims" }) &&
                        <li>
                            <Job.Modals.Edit
                                buttonText = "JOB.Button_Text_AddClaim"
                                jobType = "claims"
                                { ...props }
                            />
                        </li>
                    }
                    { permission().has({ value: "claims" }) &&
                        <li>
                            <Job.Modals.Edit
                                buttonText = "JOB.Button_Text_AddCustomerClaim"
                                jobType = "customer_claims"
                                { ...props }
                            />
                        </li>
                    }
                    { permission().has({ value: "last_minutes" }) &&
                        <li>
                            <Job.Modals.Edit
                                buttonText = "JOB.Button_Text_AddLastMinute"
                                jobType = "last_minutes"
                                { ...props }
                            />
                        </li>
                    }
                    { permission().has({ value: "damages" }) && EE.damageOldOrNew(varParams) === "old"  &&
                        <li>
                            <Job.Modals.Edit
                                buttonText = "JOB.Button_Text_AddDamage"
                                jobType = "damages"
                                { ...props }
                            />
                        </li>
                    }
                </>
            }
            { props.craftData.type === "damage"  &&
                <>
                    <li>
                        <Damage.Modals.Photo
                            buttonType={ "edit" }
                            { ...props }
                        />
                    </li>
                </>

            }
            { props.craftData.type === "protocol"  &&
                <li>
                    <Job.Modals.Edit
                        buttonText = "JOB.Button_Text_AddProtocolDefect"
                        iconClass = "overdue"
                        jobType = "protocol_defects"
                        { ...props }
                    />
                </li>
            }
        </ul>
    );
}

export default CraftActionsModalList;