import React from "react";
import { useMutation } from "@apollo/client";
import { Button } from "antd";

import { DAMAGE_FILE_DELETE } from "graphql/mutation/damages-gql";

import { Icons } from "components/layout";
import { useNotification } from "components/use-hooks";


const DamageDeletePhotoBtn = ({ id, file }) => {

    const { nError, nSuccess } = useNotification();

    const [ damageFileDelete, { loading }] = useMutation( DAMAGE_FILE_DELETE,
        {
            update(cache, { data }) {

                const {
                    damageFileDelete : {
                        label,
                        message
                    }
                } = data;

                // cache.evict({
                //     id: cache.identify( file )
                // })

                nSuccess( label, message );
            }
        }
    );


    return(
        <Button
            type      = "default"
            className = "icon rounded"
            onClick   = { () => {
                if(!loading){
                    damageFileDelete({ variables : { id }}).catch( nError )
                }
            }}
        >
            <Icons.Delete loading = { loading } />
        </Button>
    );
};

export default DamageDeletePhotoBtn;