import React from 'react';
import Icon from '@ant-design/icons';


const EyeSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M46,23.1c-7.9-8.1-16-12-24.1-11.4C9.8,12.6,2.3,22.8,2,23.3c-0.3,0.5-0.3,1.1,0.1,1.6c6.7,7.7,14,11.6,21.6,11.6
		c0.2,0,0.3,0,0.5,0C36.8,36.1,45.7,25.2,46,24.8C46.4,24.3,46.4,23.6,46,23.1z M24,33.4c-5.3,0-9.5-4.3-9.5-9.5
		c0-5.3,4.3-9.5,9.5-9.5s9.5,4.3,9.5,9.5C33.6,29.1,29.3,33.4,24,33.4z M4.6,24c1.4-1.6,5-5.5,10.2-7.8c-1.8,2.1-2.8,4.8-2.8,7.7
		c0,3.2,1.2,6,3.2,8.2C11.6,30.4,8.1,27.7,4.6,24z M33.3,31.6c1.7-2.1,2.8-4.8,2.8-7.7c0-2.6-0.8-5-2.2-7c3.2,1.6,6.4,4,9.5,7.2
		C41.9,25.6,38.2,29.2,33.3,31.6z"/>
        <path d="M28.6,22.4c0,1-0.8,1.7-1.7,1.7c-1,0-1.7-0.8-1.7-1.7s0.8-1.7,1.7-1.7c0.5,0,0.9,0.2,1.2,0.5c-0.9-1.3-2.3-2.2-4-2.2
		c-2.7,0-4.8,2.2-4.8,4.8c0,2.7,2.2,4.8,4.8,4.8c2.7,0,4.8-2.2,4.8-4.8c0-0.7-0.1-1.3-0.4-1.9C28.5,22.1,28.6,22.3,28.6,22.4z"/>
    </svg>
);

const CrossEyeSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M46,23.1c-3.6-3.7-7.2-6.5-10.9-8.4L41,8.8c0.5-0.5,0.5-1.3,0-1.8s-1.3-0.5-1.8,0l-6.6,6.6c-3.6-1.5-7.2-2.1-10.8-1.8
	C9.8,12.6,2.3,22.8,2,23.3c-0.3,0.5-0.3,1.1,0.1,1.6c3.4,3.9,7,6.8,10.6,8.7l-5.8,5.8c-0.5,0.5-0.5,1.3,0,1.8
	c0.2,0.2,0.6,0.4,0.9,0.4c0.3,0,0.6-0.1,0.9-0.4l6.5-6.5c2.8,1.1,5.6,1.7,8.5,1.7c0.2,0,0.3,0,0.5,0C36.8,36.1,45.7,25.2,46,24.8
	C46.4,24.3,46.4,23.6,46,23.1z M24,14.3c2.2,0,4.2,0.8,5.9,2l-3.4,3.4C25.8,19.3,25,19,24.1,19c-2.7,0-4.8,2.2-4.8,4.8
	c0,0.9,0.3,1.7,0.7,2.4l-3.4,3.4c-1.3-1.6-2-3.6-2-5.9C14.5,18.6,18.8,14.3,24,14.3z M24,33.4c-2.1,0-4.1-0.7-5.7-1.9l3.4-3.4
	c0.7,0.4,1.5,0.6,2.4,0.6c2.7,0,4.8-2.2,4.8-4.8c0-0.9-0.2-1.7-0.6-2.4l3.4-3.4c1.2,1.6,1.9,3.6,1.9,5.7
	C33.6,29.1,29.3,33.4,24,33.4z M4.6,24c1.4-1.6,5-5.5,10.2-7.8c-1.8,2.1-2.8,4.8-2.8,7.7c0,2.9,1,5.6,2.7,7.6l-0.2,0.2
	C11.2,30,7.9,27.5,4.6,24z M33.3,31.6c1.7-2.1,2.8-4.8,2.8-7.7c0-2.6-0.8-5-2.2-7c3.2,1.6,6.4,4,9.5,7.2
	C41.9,25.6,38.2,29.2,33.3,31.6z"/>
    </svg>
);

const EyeIcon = props => {
    if(props.type === "crossed"){
        return <Icon component={ CrossEyeSvg } className={ props.className } />;
    }

    return <Icon component={ EyeSvg } className={ props.className } />;

};

export default EyeIcon;