import DamageInfoBlock from "./damage-info-block";
import DamagePreviewBlock from "./damage-preview-block";
import DamageReportBlock from "./damage-report-block";
import DamagePhotoViewerBlock from "./damage-photo-viewer-block";

import "./damage-blocks.scss";


const DamageBlocks = {
    //Filter      : ,
    Viewer     : DamagePhotoViewerBlock,
    Report     : DamageReportBlock,
    Info       : DamageInfoBlock,
    Preview    : DamagePreviewBlock
};

export default DamageBlocks;