import React from "react";
import { useQuery } from "@apollo/client";

import { GET_DOCUMENTS } from "graphql/query/exhibition-gql";

import ExhibitionDocumentItem from "./exhibition-document-item";
import { Localize, OptimizedList } from "components/service";
import { Loader, NoData } from "components/request-result";


const ExhibitionDocumentList = ({ eeId }) => {

    const perPage = 30;
    const { data, loading, fetchMore } = useQuery(GET_DOCUMENTS, {
        variables: {
            eeId,
            first: perPage,
        },
        fetchPolicy: 'cache-first',
        notifyOnNetworkStatusChange: true
    });

    const {
        attachmentsCursor : {
            edges: documents = [],
            pageInfo = {}
        } = {}
    } = data ? data : {};


    return(
        <>
            { !documents.length && !loading &&
                <NoData
                    text={{
                        after :  <Localize br={ <br /> }>NO_DATA.Documents</Localize>
                    }}
                    icon="hall"
                />
            }
            { !!documents.length &&
                <OptimizedList
                    listClass = "exhibition-document-list"
                    itemClass = "exhibition-document-item-wrap"
                    listItems = { documents }
                    pageInfo  = { pageInfo }
                    fetchMore = { fetchMore }
                    perPage   = { perPage }
                    model     = "attachmentsCursor"

                >
                    <ExhibitionDocumentItem />
                </OptimizedList>
            }
            { loading && <Loader type="block" /> }
        </>
    );
}


export default ExhibitionDocumentList;