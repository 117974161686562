import JobCreateForm from "./job-create-form";
import JobUpdateForm from "./job-update-form";
import JobFilterForm from "./job-filter-form";

import "./job-forms.scss";


const JobForms = {
    Create : JobCreateForm,
    Update : JobUpdateForm,
    Filter : JobFilterForm
}

export default JobForms;