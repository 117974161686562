import React from "react";
import { Switch, Route } from 'react-router-dom';

import { GuestPages } from "components/pages";


const GuestRoutes = () => {

    return(
        <Switch>
            <Route path="*" exact>
                <GuestPages.Login />
            </Route>
        </Switch>
    );
};

export default GuestRoutes;