import React, { useState, useEffect, useRef } from "react";

import cache from "graphql/cache";

import FurnishingLists from "../lists";
import FurnishingModals from "../modals";
import FurnishingButtons from "../buttons";


const FurnishingSelectBlock = ({
        eeId,
        jobId,
        furnishings = [],
        visible = false,
        callback = () => {}
    }) => {

    const [ selectedFurnishing, setSelectedFurnishing ] = useState(furnishings);

    const willUnmount = useRef(false);

    useEffect(() => {
        return () => {
            willUnmount.current = true
        }
    }, [])

    useEffect(
        () => {
            callback(selectedFurnishing);

            return () => {
                if( willUnmount.current === true ) {
                    selectedFurnishing
                        .filter((item) => item.type === "deleted")
                        .forEach((item) => {
                                cache.evict({
                                    id: cache.identify(
                                        { id: item.orderId, __typename: "FurnishingOrder" }
                                    )
                                })
                            }
                        );
                }
            }
        }, [ selectedFurnishing, callback ]
    )

    if(!visible) return null;



    return(
        <div className="furnishing-select-wrap grey" >
            <FurnishingModals.Select
                eeId = { eeId }
                selected = { selectedFurnishing.filter( item => item.type !== "deleted") }
                fetchPolicy = 'no-cache'
                perPage = { 100 }
                optimized = { false }
                ActionComponent = {
                    <FurnishingButtons.Add
                        selected    = { selectedFurnishing }
                        setSelected = { setSelectedFurnishing }
                        eeId        = { eeId }
                    />
                }
            />
            <div className="furnishing-selected">
                { selectedFurnishing.filter( item => item.type !== "deleted").map(
                    ( item ) => {

                        return(
                            <FurnishingLists.Single
                                key = { item.furnishingId }
                                { ...item }
                                ActionComponent = {
                                    <FurnishingButtons.Delete
                                        selected    = { selectedFurnishing }
                                        setSelected = { setSelectedFurnishing }
                                        eeId        = { eeId }
                                        jobId       = { jobId }
                                    />
                                }
                                changeQty = {
                                    (value, id) => {

                                        setSelectedFurnishing(
                                            selectedFurnishing.map(
                                                ( item ) => {
                                                    if(item.furnishingId === id){
                                                        return { ...item, qty : value }
                                                    }

                                                    return item;
                                                }
                                            )
                                        )
                                    }
                                }
                            />
                        );
                    })
                }
            </div>
        </div>
    )
}

export default FurnishingSelectBlock;