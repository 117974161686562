import React from "react";
import { Button } from "antd";

import StandBlocks from "../../blocks";
import { ModalStandard, Localize } from "components/service";
import { Icons } from "components/layout";


const StandPhotoModal = ({ stand }) => {

    return(
        <div className="stand-photo-modal-wrap">
            <ModalStandard
                extraClass="stand-photo-modal dark full"
                title={ <Localize>STAND.Modal_Title_StandPhoto</Localize> }
                centered={ false }
                modalButton={
                    <Button
                        type="default"
                        className={ stand.file_photo ? "with-photo" : "" }
                    >
                        <Icons.Photo />
                        <Localize wrap>STAND.Modal_Button_StandPhoto</Localize>
                    </Button>
                }
            >
                <StandBlocks.Photo
                    stand={ stand }
                />
            </ModalStandard>
        </div>
    );
};

export default StandPhotoModal;