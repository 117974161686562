import { localizeIt } from "components/service/localize";


const nameByNumber = ({ flagNum }) => {

    let key = "FORMS.Select_JobFlag_WithoutFlag";

    if(flagNum === 1){
        key = "FORMS.Select_JobFlag_WithFlag";
    }

    return localizeIt(key);
}


const JobFlagByNumberHelper = {
    name    : nameByNumber
}

export default JobFlagByNumberHelper;