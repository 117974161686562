import React from "react";
import { useQuery } from "@apollo/client";

import { GET_STANDS } from "graphql/query/stand-gql";

import StandsSimpleGridItem from "./stands-simple-grid-item";
import { Loader } from "components/request-result";
import { OptimizedGrid } from "components/service";
import { UserHelpers } from "components/user";
import { useMe } from "components/use-hooks";
import { whereConditionBuilder } from "utils";


const { permission } = UserHelpers;

const StandsSimpleGrid = ({ searchText, itemAction }) => {

    const { me } = useMe();
    const perPage = 30;
    const { data, loading, fetchMore } = useQuery(GET_STANDS, {
        variables: {
            text : searchText,
            where: {
                column   : "EE_ID",
                operator : "EQ",
                value    : me.eeCurrentId
            },
            whereCrafts : whereConditionBuilder(
                {
                    craftNotDisabled : {
                        column: "STATUS",
                        operator : "NEQ",
                        value : "disabled"
                    },
                    subcontractorId :
                        permission().has({ type: "sub_view" }) ? {
                            column: "SUBCONTRACTOR_ID",
                            operator : "EQ",
                            value : me.subcontractor.id
                        } : {}
                }
            ),
            orderBy: [{ column: "ID", order : "DESC" }],
            first: perPage,
        },
        fetchPolicy: "cache-and-network",
        notifyOnNetworkStatusChange: true
    });

    const {
        standsCursor : {
            edges : stands = [],
            pageInfo = {}
        } = {}
    } = data ? data : {};

    return(
        <>
            { !!stands.length &&
                <OptimizedGrid
                    useWindowScroll = { false }
                    listClass = "stands-simple-grid"
                    itemClass = "stands-simple-grid-item-wrap"
                    gridItems = { stands }
                    pageInfo  = { pageInfo }
                    fetchMore = { fetchMore }
                    perPage   = { perPage }
                    model     = "standsCursor"
                >
                    <StandsSimpleGridItem
                        action={ (props) => itemAction(props) }
                    />
                </OptimizedGrid>
            }
            <div className={`loader-wrap ${ loading ? "loading" : ""}`}>
                { loading && <Loader /> }
            </div>
        </>
    );
}

export default StandsSimpleGrid;