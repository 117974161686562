import React, { useState } from "react";
import { Button, Radio } from "antd";

import Localize from "./localize";


const SortListStandard = ({ sortValues, setQuerySort, defaultSort, callback /*, relSort*/ }) => {

    const [ sort, setSort ] = useState( defaultSort );

    return(
        <div className="sort-list-wrap">
            <Radio.Group
                onChange={
                    ({ target: { value } }) => {
                        setSort( value )
                    }
                }
                value={
                    sortValues.find(
                        ( { value } ) => {
                            if(Array.isArray(value)) {
                                return JSON.stringify(value) === JSON.stringify(sort)

                            }

                            return value.column === sort.column && value.order === sort.order
                        }
                    ).value
                }
                className={ "sort-list-group" }
            >
                <ul className="sort-list radio-list">
                    { sortValues.map(
                        ({ value, label }, index ) => {

                            return (
                                <li key={ index }>
                                    <Radio value={ value } >
                                        <Localize>{ label }</Localize>
                                    </Radio>
                                </li>
                            )
                        })
                    }
                </ul>
            </Radio.Group>
            <div className="actions">
                <Button
                    onClick = { () => {
                        setQuerySort( Array.isArray(sort) ? sort : [ sort ]);
                        callback();
                    }}
                    type="primary"
                >
                    <Localize wrap>FORMS.Button_Text_ApplySort</Localize>
                </Button>
            </div>
        </div>
    )
};


export default SortListStandard;