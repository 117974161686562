import React from 'react';
import Icon from '@ant-design/icons';


const LogoutSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M27.8,34.9c0.2,0.2,0.6,0.4,0.9,0.4c0.3,0,0.6-0.1,0.9-0.4l10-9.8c0.2-0.2,0.4-0.6,0.4-0.9s-0.1-0.7-0.4-0.9l-10-9.8
		c-0.5-0.5-1.3-0.5-1.8,0c-0.5,0.5-0.5,1.3,0,1.8l7.8,7.7H18.8h-3c-0.7,0-1.2,0.6-1.2,1.2s0.6,1.2,1.2,1.2h3h16.9l-7.8,7.7
		C27.4,33.6,27.4,34.4,27.8,34.9z"/>
        <path d="M40.7,14.9v-3.2c0-4-3.2-7.2-7.2-7.2H15.2c-4,0-7.2,3.2-7.2,7.2v25c0,4,3.2,7.2,7.2,7.2h18.3c4,0,7.2-3.2,7.2-7.2v-3.1
		c0-0.7-0.6-1.2-1.2-1.2s-1.2,0.6-1.2,1.2v3.1c0,2.6-2.1,4.7-4.7,4.7H15.2c-2.6,0-4.7-2.1-4.7-4.7v-25c0-2.6,2.1-4.7,4.7-4.7h18.3
		c2.6,0,4.7,2.1,4.7,4.7v3.2c0,0.7,0.6,1.2,1.2,1.2S40.7,15.6,40.7,14.9z"/>
    </svg>
);

const LogoutIcon = props => {
    return <Icon component={ LogoutSvg } className={ props.className } />;

};


export default LogoutIcon;