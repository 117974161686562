import { gql } from "@apollo/client";


export const STAND_KEY_CREATE = gql`
    mutation StandKeyCreate($input: StandKeyFieldsInput!) {
        standKeyCreate(input: $input) {
            label
            message
            stand {
                id
                keys {
                    id
                    title
                    number
                }
            }
        }
    }
`;


export const STAND_KEY_UPDATE = gql`
    mutation StandKeyUpdate($input: StandKeyFieldsInput!) {
        standKeyUpdate(input: $input) {
            label
            message
            stand {
                id
                keys {
                    id
                    title
                    number
                }
            }
        }
    }
`;


export const STAND_KEY_DELETE = gql`
    mutation StandKeyDelete(
        $id: ID!
        $stand_id: ID!
    ) {
        standKeyDelete(
            id: $id
            stand_id: $stand_id
        ) {
            label
            message
            stand {
                id
                keys {
                    id
                    title
                    number
                }
            }
        }
    }
`;

export const STAND_PHOTO_UPLOAD = gql`
    mutation StandPhotoUpload(
        $input: FileUpload!
    ) {
        fileUpload(
            input : $input
        ) {
            label
            message
            model{
                ... on Stand {
                    id
                    ee_id
                    hall_id
                    hall_name
                    exhibitor
                    aquise_number
                    proauf_number
                    block
                    corridor
                    stand_number
                    delivery_time_at
                    file_stand_plan
                    file_info
                    file_graphic
                    file_photo
                    crafts {
                        id
                        title
                        subcontractor_id
                        stand_id
                        type
                        status
                        subcontractor{
                            id
                            title
                        }
                        craft_jobs{
                            id
                            type
                            status
                        }
                    }
                    created_at
                    updated_at
                }
            }
        }
    }
`;