import React from "react";
import { Button } from "antd";

import StandForms from "../../forms";
import { ModalStandard, Localize } from "components/service";
import { Icons } from "components/layout";
import { useWindowDimensions } from "components/use-hooks";


const StandKeyEditModal = ({ keyId, ...props }) => {

    const { width } = useWindowDimensions();

    return(
        <div className="stand-keys-modal-wrap">
            <ModalStandard
                extraClass="stand-keys-modal grey"
                title={
                    !keyId ?
                        <Localize>STAND.Modal_Title_AddKey</Localize> :
                        <Localize>STAND.Modal_Title_EditKey</Localize>
                }
                centered={ !(width <= 768) }
                modalButton={
                     keyId ?
                        <Button type="default" className="icon round">
                            <Icons.Edit />
                        </Button>:
                        <Button type="primary" >
                            <Icons.Key />
                            <Localize wrap>STAND.Modal_Button_AddKey</Localize>
                        </Button>
                }
            >
                <StandForms.KeyEdit
                    keyId={ keyId }
                    { ...props }
                />
            </ModalStandard>
        </div>
    );
};

export default StandKeyEditModal;