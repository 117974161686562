import React from "react";

import { Localize } from "components/service";


const JobInfoBlock = ({
        jobNumber,
        hall_name : hallName,
        block,
        stand_number : standNum,
        corridor
    }) => {

    return(
        <ul className={`info ${ jobNumber ? "grid-four-col" : "grid-three-col" } `}>
            { jobNumber &&
                <li>
                    <Localize wrap="h5">JOB.Card_Hero_Number</Localize>
                    <p>{ jobNumber }</p>
                </li>
            }
            <li>
                <Localize wrap="h5">JOB.Card_Hero_Hall</Localize>
                <p>{ hallName }</p>
            </li>
            <li>
                <Localize wrap="h5">JOB.Card_Hero_Block</Localize>
                <p>{ block ? block : "—" }</p>
            </li>
            <li>
                <Localize wrap="h5">JOB.Card_Hero_Stand</Localize>
                <p>
                    { (corridor || standNum) ?
                        `${ corridor ? corridor : "" }${ standNum }` : "—"
                    }
                </p>
            </li>
        </ul>
    );
};

export default JobInfoBlock;