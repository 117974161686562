import { localizeIt } from "components/service/localize";


const jobLabelByStatus = ({ jobStatus }) => {

    switch (jobStatus){
        case "waiting": return "FORMS.Select_JobStatusValue_JobsOpen";
        case "in_progress": return "FORMS.Select_JobStatusValue_JobsInProgress";
        case "completed": return "FORMS.Select_JobStatusValue_FinishedJobs";

        default: return null;
    }
}

const statusArrToLabelStr = ({ jobStatuses }) => {

    return jobStatuses.reduce( (statuses, status) => {
        if(!!status){
            statuses.push(localizeIt(jobLabelByStatus({ jobStatus: status })));
        }
        return statuses;
    }, []).join(", ");
}

const JobStatusHelper = {
    getLabel : jobLabelByStatus,
    arrToLabelStr : statusArrToLabelStr
}

export default JobStatusHelper;