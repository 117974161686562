import React, { useState } from "react";
import { Button, Form } from "antd";

import JobFields from "../../fields";
import JobHelpers from "../../helpers";
import Hall from "components/hall";
import Craft from "components/craft";
import Localize, {localizeIt} from "components/service/localize";
import Exhibition from "components/exhibition";
import { UserHelpers } from "components/user";


const { permission } = UserHelpers;

const formFinish = (values, data, set, callback, changedFieldsCount) => {

    const [ hallId, hallName ] = values.hallId ?  values.hallId.split(":") : [],
          [ craftType, craftLabel ] = values.craftType ?  values.craftType.split(":") : [],
          jobStatuses = values.status ? values.status.filter( status => status !== null ) : [],
          jobTypes =  values.type ? values.type.filter( type => type !== null ) : [],
          [ subcontractorId, subcontractorLabel ] = values.subcontractor ?  values.subcontractor.split(":") : [];

    set( {
        ...data,
        where : {
            ...data.where,
            status : !!jobStatuses.length ? {
                label    : JobHelpers.Status.arrToLabelStr({ jobStatuses }),
                column   : "STATUS",
                operator : "IN",
                value    : jobStatuses,
            } : {},
            type : !!jobTypes.length ? {
                label    :
                        JobHelpers.NameByType.localized({ jobTypes }),
                column   : "TYPE",
                operator : "IN",
                value    : jobTypes,
            } : {},
            flag: values.flag ? {
                label    :
                    JobHelpers.FlagByNum.name({ flagNum : values.flag - 1 }),
                column   : "FLAG",
                operator : "EQ",
                value    : values.flag - 1,
            } : {},
            craftType : values.craftType ? {
                label    :
                    localizeIt(
                        "JOB.Filter_Params_CraftType",
                        { withFilter : craftLabel }
                    ),
                column   : "CRAFT_TYPE",
                operator : "EQ",
                value    : craftType,
            } : {}
        },
        whereCraft : {
            ...data.whereCraft,
            hallId : values.hallId ? {
                label    : hallName,
                column   : "HALL_ID",
                operator : "EQ",
                value    : hallId,
            } : {},
            subcontractorId : values.subcontractor ? {
                label: subcontractorLabel,
                column: "SUBCONTRACTOR_ID",
                operator : "EQ",
                value : subcontractorId
            } : {}

        },
        activeFilters : changedFieldsCount
    })

    callback();
}

const JobFilterForm = ({ filter, callback }) => {

    const { data, set, reset } = filter,
        {
            where : {
                status,
                type,
                flag,
                craftType
            },
            whereCraft : {
                hallId,
                subcontractorId,
                //standId,
            },
             activeFilters
        } = data;


    const [ changedFieldsCount, setChangedFieldsCount ] = useState( activeFilters ),
          isSubcontractor = permission().has({ type: "sub_view" });

    return(
        <Form
            key="job-filter-form"
            layout="vertical"
            className="job-filter-form"
            onFinish={ (values) => {
                formFinish( values, data, set, callback, changedFieldsCount );
            } }
            onValuesChange={ (changedValues, allValues) => {
                const activeFiltersCount = Object.values(allValues).filter( (item) => {
                    if(Array.isArray(item)){
                        return !!item.filter( status => status !== null ).length;
                    } else {
                        return item !== null;
                    }
                } ).length;

                setChangedFieldsCount(activeFiltersCount);
            }}
        >
            <Form.Item
                name="status"
                label={ <Localize>FORMS.Input_Label_JobStatus</Localize> }
                className="dark"
                initialValue={ status && status.value !== undefined  ? status.value : [ null ] }
            >
                <JobFields.StatusSelect />
            </Form.Item>
            <Form.Item
                name="type"
                label={ <Localize>FORMS.Input_Label_JobType</Localize> }
                className="dark"
                initialValue={ type && type.value && type.label !== undefined  ? type.value : [ null ] }
            >
                <JobFields.TypeSelect />
            </Form.Item>
            <Form.Item
                name="flag"
                label={ <Localize>FORMS.Input_Label_JobFlag</Localize> }
                className="dark"
                initialValue={ flag && flag.value ? flag.value + 1 : null }
            >
                <JobFields.FlagSelect />
            </Form.Item>
            <Form.Item
                name="craftType"
                label={ <Localize>FORMS.Input_Label_CraftType</Localize> }
                className="dark"
                initialValue={ craftType && craftType.value ? `${ craftType.value }:${ Craft.Helpers.Types.list( craftType.value ) }` : null }
            >
                <Craft.Fields.TypeSelect />
            </Form.Item>
            <Form.Item
                name="hallId"
                label={ <Localize>FORMS.Input_Label_Hall</Localize> }
                className="dark"
                initialValue={ hallId && hallId.value  ? `${hallId.value}:${hallId.label}` : null }
            >
                <Hall.Fields.Select />
            </Form.Item>
            { !isSubcontractor &&
                <Form.Item
                    name="subcontractor"
                    label={ <Localize>FORMS.Input_Label_Subcontractor</Localize> }
                    className="dark"
                    initialValue={subcontractorId && subcontractorId.value ? `${subcontractorId.value}:${subcontractorId.label}` : null}
                >
                    <Exhibition.Fields.SubcontractorSelect/>
                </Form.Item>
            }
            <div className="form-actions">
                { (!changedFieldsCount ) ?
                    <Button
                        className = "dark"
                        type = "default"
                        onClick = { callback }
                    >
                        <Localize wrap>GLOBAL.Button_Text_Cancel</Localize>
                    </Button> :
                    <>
                        <Button
                            className = "dark"
                            type = "default"
                            onClick = { () => {
                                reset();
                                callback();
                            }}
                        >
                            <Localize wrap>FORMS.Button_Text_ResetFilters</Localize>
                        </Button>
                        <Button
                            type="primary"
                            htmlType="submit"
                        >
                            <Localize wrap>FORMS.Button_Text_ApplyFilters</Localize>
                        </Button>
                    </>
                }
            </div>
        </Form>
    );
}

export default JobFilterForm;