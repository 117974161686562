import React, { useState, useCallback } from "react";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { Carousel } from "antd";

import { cloneChildren } from "utils";
import ImageLoader from "./image-loader";
import { Icons } from "components/layout";


const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <button
            className={className}
            style={{ ...style }}
            onClick={onClick}
        >
            <Icons.Arrow />
        </button>
    );
};

const PrevArrow = (props) => {
    const { className, style, onClick } = props;

    return (
        <button
            className={className}
            style={{ ...style }}
            onClick={onClick}
        >
            <Icons.Arrow circle />
        </button>
    );
};

const ImagePanPinchViewer = ({
        image,
        title,
        extraClass,
        withDots = false,
        imgWrapClass = "rotation-wrapper",
        beforeChange = () => {},
        initialSlide = 0,
        ...props
    }) => {

    const [ rotation, setRotation ] = useState( 0 );

    const safariTranslate3DHack = useCallback(transform => {
        if (navigator.userAgent.indexOf("Safari") > -1) {
            setTimeout(() => {
                const container = transform?.instance?.contentComponent;
                if (container) {
                    container.style.transform = container.style.transform
                        .replace("translate3d", "translate")
                        .replace(", 0px)", ")");
                }
            }, 400);
        }
    }, []);

    const images = Array.isArray(image) ? image : [ image ];

    const dots = withDots ? {
        dots: {
            className : 'slider-pages'
        },
        customPaging: (i) => {
            return  <>{ ( i + 1 ) + '/' + images.length }</>;
        }
    } : { dots : false };

    return(
        <>
            <Carousel
                arrows = { true }
                dotsClass = { "slider-pages" }
                nextArrow = { <NextArrow /> }
                prevArrow = { <PrevArrow /> }
                beforeChange = { beforeChange }
                initialSlide={ initialSlide }
                { ...dots }
            >
                { images.map(
                    (img, index) => {
                        return(
                            <div className={ `pan-pinch-wrap${ extraClass ? ` ${extraClass}` : "" }` } key={ index }>
                                <TransformWrapper
                                    centerOnInit
                                    maxScale={ 20 }
                                    onPanningStop={ (ref) => {
                                        safariTranslate3DHack(ref)
                                    } }
                                    onPinchingStop={ (ref) => {
                                        safariTranslate3DHack(ref)
                                    } }
                                    onZoomStop={ (ref) => {
                                        safariTranslate3DHack(ref)
                                    } }
                                >
                                    <TransformComponent>
                                        <ImageLoader
                                            image     = { img }
                                            altText   = { title }
                                            wrapClass = { imgWrapClass }
                                            imgClass  = { `r${ rotation }` }
                                        />
                                    </TransformComponent>
                                </TransformWrapper>
                            </div>
                        )
                    }
                )}
            </Carousel>
            { cloneChildren(
                props.children,
                {
                    rotate : () =>  setRotation( rotation < 3 ? rotation+1 : 0 ),
                    rotation
                }
            )}
        </>


    )
}


export default React.memo(ImagePanPinchViewer);