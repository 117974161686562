import React from "react";
import { useMutation } from "@apollo/client";
import { Button } from "antd";

import { CRAFT_CHANGE_STATUS } from "graphql/mutation/craft-gql";

import CraftGrids from "../grids";
import { useNotification } from "components/use-hooks";
import { Localize } from "components/service";
import { Icons } from "components/layout";


const CraftChangeStatusBtn = ({ id, status, type, justButton = false, callback = () => {} }) => {

    const { nError, nSuccess } = useNotification();
    const [ craftChangeStatus, { loading }] = useMutation( CRAFT_CHANGE_STATUS,
        {
            update(cache, { data }) {

                const {
                    craftUpdateStatus : {
                        label,
                        message
                    }
                } = data;

                callback();

                nSuccess( label, message );
            }
        }
    );

    const Wrap = ( { children, ...props } ) => {
        if(justButton){
            return(
                <Button
                    {...props}
                    type="primary"
                >
                    { children }
                </Button>
            )
        }

        return(
            <button {...props}>{ children }</button>
        )
    }


    return(
        <Wrap
            onClick = {
                () => {
                    if(!loading){
                        craftChangeStatus({
                            variables: {
                                id,
                                status : status !== "completed" ? "completed" : "in_progress"
                            }
                        }).catch((error) => nError( error ))
                    }
                }
            }
            className = { `craft-change-status-btn status ${ status !== "completed" ? "completed" : "in-progress" }` }
        >
            { !justButton ?
                <>
                    <CraftGrids.Single
                        extraClass = { status !== "completed" ? "completed" : "in_progress" }
                        type = { type }
                        loading = { loading }
                    />
                    { status !== "completed" ?
                        <Localize>CRAFT.Button_Text_MarkDone</Localize> :
                        <Localize>CRAFT.Button_Text_MarkInProgress</Localize>
                    }
                </> :
                <>
                    { status !== "completed" ?
                        <>
                            <Icons.Control loading = { loading } />
                            <Localize>CRAFT.Button_ChangeStatus_Done</Localize>
                        </> :
                        <>
                            <Icons.InProgress loading = { loading }/>
                            <Localize>CRAFT.Button_ChangeStatus_InProgress</Localize>
                        </>
                    }
                </>
            }

        </Wrap>
    )
}

export default CraftChangeStatusBtn