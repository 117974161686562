import React from "react";
import { Button } from "antd";

import StandBlocks from "../../blocks";
import { ModalStandard } from "components/service";
import { Icons } from "components/layout";
import { Localize } from "components/service";

const StandSearchModal = () => {

    return(
        <div className="stand-search-modal-wrap">
            <ModalStandard
                extraClass="stand-search-modal dark full"
                title={ <Localize>STAND.Modal_Title_Search</Localize> }
                centered={ false }
                modalButton={
                    <Button className="icon rounded" >
                        <Icons.Search />
                    </Button>
                }
            >
                <StandBlocks.Search />
            </ModalStandard>
        </div>
    );
};

export default StandSearchModal;