import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { GET_EE_STATISTIC } from "graphql/query/exhibition-gql";

import { Loader } from "components/request-result";
import { Localize } from "components/service";
import ExhibitionHelpers from "../helpers";
import { UserHelpers } from "components/user";
import { useVarParam } from "components/use-hooks";


const { permission } = UserHelpers;
const { Statistic, EE } = ExhibitionHelpers;

const Wrap = ({ route, getFilter, className, count, children }) => {

    const location = useLocation();

    const routeExist = route && route.includes("jobs") ? permission().has({type: "craft_job", value: "view_job"}) : true;

    if(count > 0 && route && routeExist){
        return(
            <Link
                className={ className }
                to={{
                    pathname: route,
                    state: {
                        from : location.pathname,
                        queryParams : getFilter()
                    }
                }}
            >
                { children }
            </Link>
        )
    }

    return (
        <div className = { className }>
            { children }
        </div>
    )
}


const ExhibitionStatisticGrid = ( { eeId } ) => {

    const { data, loading } = useQuery(GET_EE_STATISTIC, {
        variables: {
            id: eeId
        },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first'
    });

    const appParams = useVarParam();

    const {
        eeStatistic : {
            statistic : {
                crafts = [],
                ...statistic
            } = {}
        } = {}
    } = data ? data : {};

    const isSubcontractor = permission().has({ type: "sub_view" })

    const isNewDamages = EE.damageOldOrNew(appParams) === "new";

    return(
        <div className="exhibition-statistic-grid">
            { loading && <Loader type="block"/> }
            { !loading && data &&
                <>
                    { Statistic.sort(statistic).map(
                        ( { total, in_progress, not_approved, type, label, icon, ...props} ) => {

                            const twoCol = !( in_progress >= 0 || not_approved >= 0);
                            let totalCount = type === "jobs_damages" ? total : 0 ;

                            if([ "protocol" , "mp_control"].indexOf(type) !== -1 && isSubcontractor){

                                if(type === "protocol"  && !permission().has({ value: "protocol_defects" })){
                                    return null;
                                }

                                if(type === "mp_control"  && !permission().has({ value: "mp_control" })){
                                    return null;
                                }
                            }

                            if( isNewDamages ){
                                totalCount = 0;
                            }

                            return(
                                <div className={`item ${ twoCol ? "two-col" : "" }`} key={ type }>
                                    <div className="icon-wrap">
                                        { icon }
                                        <Localize wrap="p" wrapClass="label">
                                            { label }
                                        </Localize>
                                    </div>
                                    { total >= 0 &&
                                        <Wrap
                                            { ...props }
                                            className="total"
                                            count={ totalCount }
                                        >
                                            <h3>{ total }</h3>
                                            <Localize wrap="p" wrapClass="label">
                                                EXHIBITION.Statistic_Status_InTotal
                                            </Localize>
                                        </Wrap>
                                    }
                                    { in_progress >= 0  && !(type === "jobs_damages" && !isNewDamages ) &&
                                        <Wrap
                                            className={`${in_progress > 0 ? "progress" : "finished"}`}
                                            { ...props }
                                            count={ type !== "protocol" ? in_progress : 0 }
                                        >
                                            <h3>{ in_progress }</h3>
                                            <Localize wrap="p" wrapClass="label">
                                                EXHIBITION.Statistic_Status_InProgress
                                            </Localize>
                                        </Wrap>
                                    }
                                    { not_approved >= 0 &&
                                        <Wrap
                                            { ...props }
                                            count={ not_approved }
                                            className={`${not_approved > 0 ? "overdue" : "finished"}`}
                                        >
                                            <h3>{ not_approved }</h3>
                                            <Localize wrap="p" wrapClass="label">
                                                EXHIBITION.Statistic_Status_NotApproved
                                            </Localize>
                                        </Wrap>
                                    }
                                </div>
                            )
                        })
                    }
                    <div className="crafts item">
                        <Localize wrap="h5">
                            EXHIBITION.Title_CraftTotal
                        </Localize>
                        <div className="grid-four-col">
                            <div>
                                <h4>{ crafts.total}</h4>
                                <Localize wrap="p" wrapClass="label">
                                    EXHIBITION.Statistic_Status_InTotal
                                </Localize>
                            </div>
                            <div className="progress">
                                <h4>{ crafts.in_progress}</h4>
                                <Localize wrap="p" wrapClass="label">
                                    EXHIBITION.Statistic_Status_InProgress
                                </Localize>
                            </div>
                            <div className="finished">
                                <h4>{ crafts.completed}</h4>
                                <Localize wrap="p" wrapClass="label">
                                    EXHIBITION.Statistic_Status_Done
                                </Localize>
                            </div>
                            <div className="overdue">
                                <h4>{ crafts.overdue }</h4>
                                <Localize wrap="p" wrapClass="label">
                                    EXHIBITION.Statistic_Status_Overdue
                                </Localize>
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    );
}


export default ExhibitionStatisticGrid;