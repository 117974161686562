import React from "react";
import { Button } from "antd";

import {  useLogout, useVarParam } from "components/use-hooks";
import { Menus, Icons, Wraps } from "components/layout";
import Localize, { localizeIt } from "components/service/localize";


const MorePage = () => {

    const appParams = useVarParam(),
        { logout } = useLogout();

    appParams.set({
        headerTitle : localizeIt("PAGE.Title_AdditionalMenu"),
        bottomBar: true,
        searchOn: true
    });

    return(
        <Wraps.Container className="page more-page">
            <Menus.More />
            <div className="action-wrap">
                <Button
                    type="primary"
                    onClick={ () => logout({ redirectRoute: "/" }) }
                >
                    <Icons.Logout />
                    <Localize wrap>GLOBAL.Button_Text_Logout</Localize>
                </Button>
            </div>
        </Wraps.Container>
    )
};

export default MorePage;