import React from "react";
import { useMutation } from "@apollo/client";
import { Button } from "antd";

import { FURNISHING_ORDER_DELETE } from "graphql/mutation/furnishing-gql";

import { useNotification } from "components/use-hooks";
import { Icons } from "components/layout";



const FurnishingDeleteOrderButton = ({ selected = [], setSelected, orderId, eeId, furnishingId}) => {

    const { nError, nSuccess } = useNotification();

    const [ furnishingOrderDelete, { loading }] = useMutation( FURNISHING_ORDER_DELETE,
        {
            update(cache, { data }) {

                const {
                    furnishingOrderDelete : {
                        label,
                        message
                    }
                } = data;

                nSuccess( label, message );
            }
        }
    );

    return (
        <Button
            type="ghost"
            className="icon round"
            onClick = {
                () => {

                    if(orderId){
                        if(!loading){

                            furnishingOrderDelete({
                                variables: {
                                    id : orderId,
                                    eeId : eeId
                                }
                            }).then(
                                () => {

                                    setSelected(
                                        selected.map( (item) => {
                                            if(item.furnishingId === furnishingId) {
                                                return { ...item, type : "deleted"  }
                                            }

                                            return item;
                                        })
                                    )

                                }
                            ).catch((error) => nError( error ))

                        }
                    } else {
                        setSelected(
                            selected.filter(
                                (item) => item.furnishingId !== furnishingId
                            )
                        )
                    }
                }
            }
        >
            <Icons.Delete loading={ loading } />
        </Button>
    )
}


export default FurnishingDeleteOrderButton;