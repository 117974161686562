import React from "react";
import { Button } from "antd";

import { Localize, ModalStandard } from "components/service";
import { Icons } from "components/layout";
import { useWindowDimensions } from "components/use-hooks";


const FilterModalStandard = ({
        wrapClass = "",
        modalClass = "",
        children
    }) => {

    const { width } = useWindowDimensions();

    return(
        <div className={`filter-modal-wrap ${ wrapClass }`}>
            <ModalStandard
                extraClass={ `filter-modal ${ modalClass }`}
                title={ <Localize>GLOBAL.Modal_Title_Filters</Localize> }
                centered={ !(width <= 768) }
                modalButton={
                    <Button type="ghost">
                        <Icons.Filter />
                        <Localize wrap>GLOBAL.Button_Text_Filter</Localize>
                    </Button>
                }
            >
                { children }
            </ModalStandard>
        </div>
    );
};

export default FilterModalStandard;