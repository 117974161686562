import React from "react";
import { useQuery } from "@apollo/client";
import { Select } from "antd";

import { GET_UNIQ_TYPE } from "graphql/query/craft-gql";

import CraftHelpers from "../helpers";
import { Icons } from "components/layout";
import { useMe } from "components/use-hooks";
import { Localize } from "components/service";


const { Option } = Select;

const CraftTypeSelectField = ({ ...props }) => {

    const { me } = useMe();
    const { data, loading } = useQuery(GET_UNIQ_TYPE, {
        variables: {
            eeId : me.eeCurrentId
        },
        fetchPolicy: 'cache-first'
    });

    const {
        craftUniqType = []
    } = data ? data : {};

    const uniqCrafts = CraftHelpers.Types.exist(craftUniqType);

    return(
        <Select
            className={ `craft-type-select-field` }
            suffixIcon={
                <Icons.Arrow type="filled"/>
            }
            loading={ loading }
            defaultValue={ null }
            { ...props }
        >
            <Option value={ null }>
                <Localize>FORMS.Select_DefaultValue_All</Localize>
            </Option>
            { uniqCrafts.map(
                ( { type, label } ) =>
                    <Option
                        key={ type }
                        value={ `${type}:${label}` }>
                            { label }
                    </Option>
            )}
        </Select>
    );
}

export default CraftTypeSelectField;