import React from 'react';
import Icon from '@ant-design/icons';


const TurnSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M43.7,15.4c-1-0.5-2.2-0.2-2.7,0.8l-3.3,6c-1.1-7.9-7.9-14-16.1-14c-9,0-16.3,7.3-16.3,16.3c0,9,7.3,16.3,16.3,16.3
	c1.1,0,2-0.9,2-2s-0.9-2-2-2c-6.8,0-12.3-5.5-12.3-12.3c0-6.8,5.5-12.3,12.3-12.3c6.4,0,11.7,5,12.3,11.3l-6.2-3.8
	c-0.9-0.6-2.2-0.3-2.8,0.7c-0.6,0.9-0.3,2.2,0.7,2.8l10.3,6.3c0.3,0.2,0.7,0.3,1,0.3c0.2,0,0.3,0,0.5-0.1c0.5-0.1,1-0.5,1.2-1
	l5.9-10.5C45,17.1,44.7,15.9,43.7,15.4z"/>
    </svg>
);

const TurnIcon = props => {
    return <Icon component={ TurnSvg } className={ props.className } />;

};


export default TurnIcon;