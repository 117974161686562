import JobHelpers from "./helpers";
import JobModals from "./modals";
import JobBlocks from "./blocks";
import JobGrids from "./grids";
import JobButtons from "./buttons";
import JobForms from "./forms"
import JobLists from "./lists";
import JobHooks from "./hooks";
import JobFields from "./fields";


const Job = {
    Helpers : JobHelpers,
    Modals  : JobModals,
    Blocks  : JobBlocks,
    Grids   : JobGrids,
    Buttons : JobButtons,
    Forms   : JobForms,
    Lists   : JobLists,
    Hooks   : JobHooks,
    Fields  : JobFields
}

export default Job;