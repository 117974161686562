import JobFilterBlock from "./job-filter-block";
import JobPhotoViewerBlock from "./job-photo-viewer-block";
import JobInfoBlock from "./job-info-block";
import JobEditBlock from "./job-edit-block";

import "./job-blocks.scss";


const JobBlocks = {
    Filter      : JobFilterBlock,
    PhotoViewer : JobPhotoViewerBlock,
    Info        : JobInfoBlock,
    Edit        : JobEditBlock
};

export default JobBlocks;