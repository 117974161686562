import MainNavMenu from "./main-nav-menu";
import BottomMenuBar from "./bottom-menu-bar";
import TopMenuBar from "./top-menu-bar";
import MoreMenu from "./more-menu";

const Menus = {
    TopBar    : TopMenuBar,
    BottomBar : BottomMenuBar,
    MainNav   : MainNavMenu,
    More      : MoreMenu
}

export default Menus;