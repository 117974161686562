import React from "react";
import { Virtuoso  } from 'react-virtuoso';

import { fetchQueryMore, cloneChildren } from "utils";


const OptimizedList = ({
       useWindowScroll = true,
       listItems,
       perPage,
       pageInfo,
       model,
       fetchMore,
       listClass,
       itemClass,
       increaseViewportBy = {
           top: 0, bottom: 0
       },
       ...props
    }) => {

    return(
        <Virtuoso
            style={{ height: "400px !important" } }
            useWindowScroll = { useWindowScroll }
            data={ listItems }
            increaseViewportBy = { increaseViewportBy }
            className={ "virtuoso-scroller" }
            overscan={ 100 }
            components={{
                Item: React.forwardRef((props, ref) => {
                    return <div className={ itemClass } {...props} ref={ref} />
                }),
                List: React.forwardRef((props, ref) => {
                    return <div className={ listClass } {...props} ref={ref}  />
                }),
            }}
            endReached={
                () => fetchQueryMore(
                    model,
                    fetchMore,
                    pageInfo,
                    listItems
                )
            }
            itemContent={(index, element) => {
                const { node } = element;
                return cloneChildren( props.children, { ...node, key: node.id });
            }}
        />
    );
}

export default OptimizedList;