import { useState } from "react";
import { mergeDeep } from "@apollo/client/utilities";

import { useFilterStorage, useMe } from "components/use-hooks";


const useCraftFilterHook = ({
        filter : filterParams = {}
    }) => {

    const { me } = useMe(),
          filterStorage = useFilterStorage();

    const defaultFilter = {
            hallId : {},
            eeId   : {
                column   : "EE_ID",
                operator : "EQ",
                value    : me.eeCurrentId,
            },
            activeFilters: 0
        };

    const [ filter, setFilter ] = useState(
            mergeDeep(defaultFilter, filterParams)
        );

    filterStorage.store("craftOverview", {
        filter
    })

    return {
        filter : {
            data  : filter,
            set   : setFilter,
            reset : () => setFilter(defaultFilter)
        }
    };
}

export default useCraftFilterHook;