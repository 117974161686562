import Router from "./router";
import AppRoutes from "./app-routes";
import GuestRoutes from "./guest-routes";


export {
    AppRoutes,
    GuestRoutes,
}


export default Router;