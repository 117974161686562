import React, { useState } from "react";

import CraftGridItem from "./craft-grid-item";
import CraftModals from "../../modals";
import CraftButtons from "../../buttons";
import { UserHelpers } from "components/user";


const { permission } = UserHelpers;

const CraftGrid = ({
        crafts = [],
        stand,
    }) => {

    const [ craftData, setCraftData ] = useState();

    const oneCraft = crafts.length === 1 ? { ...crafts[0] } : false;

    return(
        <div className={`craft-grid ${ !oneCraft ? "grid-five-col" : "" }`}>
            { crafts.map(
                ({ id, status, ...props }) => {

                    let hasPermission = permission().hasOneOf( [
                        { value: "claims" },
                        { value: "customer_claims" },
                        { value: "last_minutes" },
                        { value: "damages" },
                    ]);

                    if(props.type === "protocol"){
                        hasPermission = permission().hasOneOf( [
                            { value: "protocol_defects" }
                        ])
                    }

                    return(
                        <CraftGridItem
                            key        = { id }
                            wrapped    = { hasPermission }
                            extraClass = { `${ !!oneCraft ? "one " : "" }${ status }` }
                            action = {
                                () => {
                                    setCraftData({ id, status, ...props });
                                }
                            }
                            { ...props }
                        />
                    );
                }
            )}

            { !!craftData &&
                <CraftModals.Actions
                    stand          = { stand }
                    craftData      = { craftData }
                    changeStatusOn = { !oneCraft }
                />
            }
            { !!oneCraft && permission().has({ value: "change_craft_status" }) &&
                <div className="craft-one-action">
                    <CraftButtons.ChangeStatus
                        { ...oneCraft }
                        justButton = { true }
                    />
                </div>
            }
        </div>
    )
}

export default CraftGrid;