import React from "react";

import Exhibition from "components/exhibition";
import { Wraps } from "components/layout";
import { useVarParam, useMe } from "components/use-hooks";
import { localizeIt } from "components/service/localize";


const DocumentsPage = () => {

    const { me } = useMe(),
        appParams = useVarParam();

    appParams.set({
        headerTitle: localizeIt("PAGE.Title_Documents"),
        bottomBar: true
    });


    return (
        <Wraps.Container className="page documents-page">
            <Exhibition.Lists.Document
                eeId={ me.eeCurrentId }
            />
        </Wraps.Container>
    );
}

export default DocumentsPage;