
const getCurrentEE = (varParams) => {

    const { exhibitionEvent, eeCurrentId } = varParams.get("me");

    return exhibitionEvent.find((ee) => ee.id === eeCurrentId);
};

const damageReportOldOrNew = (varParams) => {
    const moment = varParams.get("moment"),
          ee = getCurrentEE(varParams);

    return moment(varParams.get("damageJobExistTill")).diff(ee.created_at, "day") > 0 ? "old" : "new";
};


const ExhibitionHelper = {
    current        : getCurrentEE,
    damageOldOrNew : damageReportOldOrNew
};

export default ExhibitionHelper;