import FurnishingChangeTabButton from "./furnishing-change-tab-button";
import FurnishingAddButton from "./furnishing-add-button";
import FurnishingDeleteOrderButton from "./furnishing-delete-order-button";


const FurnishingButtons = {
    ChangeTab : FurnishingChangeTabButton,
    Add       : FurnishingAddButton,
    Delete    : FurnishingDeleteOrderButton
}

export default FurnishingButtons;
