import HallsPage from "./halls-page";
import HallPage from "./hall-page";


const HallPages = {
    Collection : HallsPage,
    Single     : HallPage
};

export default HallPages;
