import FurnishingRefundModal from "./furnishing-refund-modal";
import FurnishingSelectModal from "./furnishing-select-modal";

import "./furnishing-modals.scss";


const FurnishingModals = {
    Refund : FurnishingRefundModal,
    Select : FurnishingSelectModal
}

export default FurnishingModals;