import React from "react";

import ExhibitionModals from "../modals";
import { useMe } from "components/use-hooks";
import { Localize } from "components/service";


const ExhibitionSelectField = ({ currentEEid, ee } ) => {

    const currentEE = ee.find( ({ id }) => currentEEid === id ),
          { me } = useMe();

    return(
        <div className="exhibition-select-wrap">
            <Localize
                wrap="h5"
                wrapClass="label"
            >
                EXHIBITION.Select_Label
            </Localize>
            <div className="exhibition-select">
                <span>{ currentEE.title }</span>
                <ExhibitionModals.Select
                    ee={ ee }
                    currentEE={ currentEE }
                    me={ me }
                />
            </div>
        </div>
    )

}

export default ExhibitionSelectField;