import React from "react";
import { Select } from "antd";

import { Icons } from "components/layout";
import JobHelpers from "../helpers";
import { Localize } from "components/service";


const { Option } = Select;

const JobTypeSelectField = ({ ...props }) => {


    const { NameByType } = JobHelpers;

    return(
        <Select
            className={ `job-type-select-field` }
            suffixIcon={
                <Icons.Arrow type="filled"/>
            }
            mode="multiple"
            defaultValue={ null }
            { ...props }
        >
            <Option value={ null }>
                <Localize>FORMS.Select_DefaultValue_All</Localize>
            </Option>
            <Option value={ "last_minutes" }>
                <Localize>{ NameByType.single({ jobType :"last_minutes" }) }</Localize>
            </Option>
            <Option value={ "claims" }>
                <Localize>{ NameByType.single({ jobType :"all_claims" }) }</Localize>
            </Option>
            <Option value={ "protocol_defects" }>
                <Localize>{ NameByType.single({ jobType :"protocol_defects" }) }</Localize>
            </Option>
        </Select>
    );
}

export default JobTypeSelectField;