import React from "react";
import { Link, useLocation } from "react-router-dom";

import { Icons } from "components/layout";
import { UserHelpers } from "components/user";
import { Localize } from "components/service";


const { permission } = UserHelpers;

const StandKeysLogsBlock = ({ standId }) => {

    const location = useLocation();

    return(
        <div className = { `block-wrap keys-logs-block ${ permission().has({ type : "log" }) ? "grid-two-col" : ""}` }>
            <Link
                className="link-button-default"
                to={{
                    pathname: `/stands/${ standId }/keys`,
                    state: {
                        from : location.pathname,
                    }
                }}
            >
                <Icons.Key />
                <Localize>STAND.Button_Text_ViewKeys</Localize>

            </Link>
            { permission().has({ type : "log" }) &&
                <Link
                    className="link-button-default"
                    to={{
                        pathname: `/stands/${ standId }/log`,
                        state: {
                            from : location.pathname,
                        }
                    }}
                >
                    <Icons.Documents />
                    <Localize>STAND.Button_Text_ViewLogs</Localize>
                </Link>
            }
        </div>
    );

}

export default StandKeysLogsBlock;