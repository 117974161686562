import { gql } from '@apollo/client';


export const GET_FURNISHING = gql`
    query FurnishingsCursor(
        $text        : String
        $where       : QueryFurnishingsCursorWhereWhereConditions
        $orderBy     : [QueryFurnishingsCursorOrderByOrderByClause!]
        $first       : Int
        $after   : String
    ) {
        furnishingsCursor(
            text        : $text
            where       : $where
            orderBy     : $orderBy
            first       : $first
            after       : $after
        ) {
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
                total
                currentPage
                lastPage
            }
            edges {
                node{
                    id
                    title
                    sku
                    qty
                    price
                    ee_id
                    image
                    created_at
                }
            }
        }
    }
`;

export const GET_FURNISHING_ORDERS = gql`
    query FurnishingsOrdersCursor(

        $where       : QueryFurnishingsOrdersCursorWhereWhereConditions
        $orderBy     : [QueryFurnishingsOrdersCursorOrderByOrderByClause!]
        $first       : Int
        $after   : String
    ) {
        furnishingsOrdersCursor(
            where       : $where
            orderBy     : $orderBy
            first       : $first
            after       : $after
        ) {
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
                total
                currentPage
                lastPage
            }
            edges {
                node{
                    id
                    qty
                    furnishing_id
                    type
                    ee_id
                    furnishing {
                        id
                        title
                        sku
                        qty
                        price
                        ee_id
                        image
                        created_at
                    }
                    user {
                        id
                        name
                        surname
                    }
                    created_at
                }
            }
        }
    }
`;

