import { localizeIt } from "components/service/localize";
import { UserHelpers } from "components/user";
import Exhibition from "components/exhibition";
import cache from "graphql/cache";

const { permission } = UserHelpers;



const craftTypes = (type) => {

    const craftTypes = {
        "logistics"    : localizeIt("CRAFT.Type_Logistics", { number: "" }),
        "logistics_2"  : localizeIt("CRAFT.Type_Logistics", { number: "2" }),
        "logistics_3"  : localizeIt("CRAFT.Type_Logistics", { number: "3" }),
        "floor"        : localizeIt("CRAFT.Type_Floor", { number: "" }),
        "floor_2"      : localizeIt("CRAFT.Type_Floor", { number: "2" }),
        "floor_3"      : localizeIt("CRAFT.Type_Floor", { number: "3" }),
        "assembly"     : localizeIt("CRAFT.Type_Assembly", { number: "" }),
        "assembly_2"   : localizeIt("CRAFT.Type_Assembly", { number: "2" }),
        "assembly_3"   : localizeIt("CRAFT.Type_Assembly", { number: "3" }),
        "electrics"    : localizeIt("CRAFT.Type_Electrics", { number: "" }),
        "electrics_2"  : localizeIt("CRAFT.Type_Electrics", { number: "2" }),
        "electrics_3"  : localizeIt("CRAFT.Type_Electrics", { number: "3" }),
        "graphic"      : localizeIt("CRAFT.Type_Graphic", { number: "" }),
        "graphic_2"    : localizeIt("CRAFT.Type_Graphic", { number: "2" }),
        "graphic_3"    : localizeIt("CRAFT.Type_Graphic", { number: "3" }),
        "furniture"    : localizeIt("CRAFT.Type_Furniture", { number: "" }),
        "furniture_2"  : localizeIt("CRAFT.Type_Furniture", { number: "2" }),
        "furniture_3"  : localizeIt("CRAFT.Type_Furniture", { number: "3" }),
        "multimedia"   : localizeIt("CRAFT.Type_Multimedia", { number: "" }),
        "multimedia_2" : localizeIt("CRAFT.Type_Multimedia", { number: "2" }),
        "multimedia_3" : localizeIt("CRAFT.Type_Multimedia", { number: "3" }),
        "cleaning"     : localizeIt("CRAFT.Type_Cleaning", { number: "" }),
        "cleaning_2"   : localizeIt("CRAFT.Type_Cleaning", { number: "2" }),
        "cleaning_3"   : localizeIt("CRAFT.Type_Cleaning", { number: "3" }),
        "additional"   : localizeIt("CRAFT.Type_Additional", { number: "" }),
        "additional_2" : localizeIt("CRAFT.Type_Additional", { number: "2" }),
        "additional_3" : localizeIt("CRAFT.Type_Additional", { number: "3" }),
        "mp_control"   : localizeIt("CRAFT.Type_MPControl"),
        "protocol"     : localizeIt("CRAFT.Type_Protocol"),
        "service"      : localizeIt("CRAFT.Type_Service", { number: "" }),
        "service_2"    : localizeIt("CRAFT.Type_Service", { number: "2" }),
        "service_3"    : localizeIt("CRAFT.Type_Service", { number: "3" }),
    }

    return type ? craftTypes[type] : craftTypes;
}


const existCraftList = (crafts) => {

    const readyCrafts = [];

    for (let [key, value] of Object.entries(craftTypes())) {

        crafts.forEach( ({ type }) => {
            if(type === key){
                readyCrafts.push({
                    "type" : key,
                    "label" : value
                })
            }
        })
    }

    return readyCrafts;
}

const activeCraftList = ({ crafts, stand, varParams }) => {

    const { Helpers : { EE } } = Exhibition;
    const { damage } = stand;

    let activeCrafts = crafts.filter(
        ({ status } ) => [ "disabled" ].indexOf(status) === -1
    );

    if( permission().has({ type: "sub_view" }) ){

        activeCrafts = activeCrafts.filter(
            ({ subcontractor_id } ) =>  permission().equalSubId( subcontractor_id )
        );

    }

    if( EE.damageOldOrNew(varParams) === "new"){
        activeCrafts.push({
            "id"        : damage.id,
            "type"      : "damage",
            "title"     : localizeIt("DAMAGE.AsCraft_label"),
            "status"    : damage.status === "delete" ? "delete" : damage.status,
            "count"     : damage.total_files,
            "craft_jobs": [
                { id: damage.id, type : `damage_${ damage.status }` }
            ],
            "stand_id"  : stand.id
        })


        if(damage.id > 0){
            cache.modify({
                id: cache.identify({ __typename: 'Stand', id: stand.id }),
                fields: {
                    damageReport() {
                        return {...damage};
                    }
                }
            });
        }

    }

    return activeCrafts;
}


const CraftTypeHelper = {
    active : activeCraftList,
    exist  : existCraftList,
    list   : craftTypes
}

export default CraftTypeHelper;