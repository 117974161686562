import HallBlocks from "./blocks";
import HallLists  from "./lists";
import HallFields from "./fields";
import HallGrids  from "./grids";
import HallHooks from "./hooks";
import HallHelpers from "./helpers";
import HallForms from "./forms";
import HallModals from "./modals";


const Hall = {
    Blocks  : HallBlocks,
    Lists   : HallLists,
    Fields  : HallFields,
    Grids   : HallGrids,
    Hooks   : HallHooks,
    Helpers : HallHelpers,
    Forms   : HallForms,
    Modals  : HallModals
}

export default Hall;