import React from "react";

import StandForms from "../../forms";
import { FilterModalStandard } from "components/service";


const JobFilterModal = (props) => {

    return(
        <FilterModalStandard
            wrapClass="stand-filter-modal-wrap"
            modalClass="stand-filter-modal"
        >
            <StandForms.Filter { ...props } />
        </FilterModalStandard>
    );
};

export default JobFilterModal;