import StandFilterBlock from "./stand-filter-block";
import StandOverviewBlock from "./stand-overview-block";
import StandMaterialsBlock from "./stand-materials-block";
import StandKeysLogsBlock from "./stand-keys-logs-block";
import StandCraftsBlock from "./stand-crafts-block";
import StandPhotoBlock from "./stand-photo-block";
import StandSearchBlock from "./stand-search-block";
import StandDamagesBlock from "./stand-damages-block";

import "./stand-blocks.scss";


const StandBlocks = {
    Overview  : StandOverviewBlock,
    Filter    : StandFilterBlock,
    Materials : StandMaterialsBlock,
    KeyLogs   : StandKeysLogsBlock,
    Crafts    : StandCraftsBlock,
    Photo     : StandPhotoBlock,
    Search    : StandSearchBlock,
    Damages   : StandDamagesBlock
}

export default StandBlocks;