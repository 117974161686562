import React from "react";
import { Tag } from "antd";


const FilterTag = ({ action, children }) => {
    return (
        <Tag
            className="filter-tag"
            closable
            onClose={(e) => {
                e.preventDefault();

                action();
            }}
        >
            { children }
        </Tag>
    );
}

export default FilterTag;