import React from 'react';
import Icon from '@ant-design/icons';


const EmailSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M43.1,10.7c0-0.1,0-0.2-0.1-0.2c0-0.1-0.1-0.2-0.1-0.2c0,0,0-0.1-0.1-0.1c0,0,0,0-0.1-0.1c-0.1-0.1-0.1-0.1-0.2-0.2
            c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.2,0-0.3-0.1c0,0-0.1,0-0.1,0H6.2c0,0,0,0-0.1,0c-0.1,0-0.2,0-0.2,0
            c-0.1,0-0.2,0-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.1C5.4,9.9,5.3,9.9,5.3,10c0,0,0,0-0.1,0c0,0,0,0.1-0.1,0.1c0,0.1-0.1,0.1-0.1,0.2
            c0,0.1,0,0.2-0.1,0.2c0,0.1,0,0.1,0,0.2v26.4c0,0.7,0.6,1.2,1.2,1.2h35.7c0.7,0,1.2-0.6,1.2-1.2V10.8C43.2,10.8,43.1,10.7,43.1,10.7
            z M38.2,12.1l-14.3,11l-14.1-11H38.2z M7.5,35.9V13.4l15.7,12.2c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.5-0.1,0.8-0.3l16-12.3v22.6H7.5z"
        />
    </svg>
);

const EmailClosedSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M42.7,11.4c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1-0.1-0.2c0-0.1,0-0.1-0.1-0.2c0,0,0-0.1-0.1-0.1
	c0-0.1-0.1-0.1-0.1-0.2c0,0,0-0.1-0.1-0.1c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1
	c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.2-0.1c-0.1,0-0.1,0-0.2-0.1c0,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0H6.8c0,0,0,0,0,0
	c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0.1c0,0-0.1,0-0.1,0.1C6,10,5.9,10,5.8,10
	c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.1,0.1-0.2,0.1c0,0,0,0,0,0c0,0,0,0.1-0.1,0.1c0,0.1-0.1,0.1-0.1,0.2c0,0-0.1,0.1-0.1,0.1
	c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.1-0.1,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1v25.2c0,1,0.8,1.8,1.8,1.8H41
	c1,0,1.8-0.8,1.8-1.8V11.5C42.7,11.4,42.7,11.4,42.7,11.4z M8.6,15.5l8.2,7.6l-8.2,8.9V15.5z M23.8,24.7L11.3,13.2h25.1L23.8,24.7z
	 M19.4,25.4l3.2,2.9c0.3,0.3,0.8,0.5,1.2,0.5c0.4,0,0.8-0.2,1.2-0.5l3.5-3.1l8.9,9.7H10.6L19.4,25.4z M31,22.9l8.2-7.4v16.4L31,22.9
	z"/>
    </svg>
);


const EmailOpenSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M42.8,18.2c0-0.5-0.2-1-0.6-1.3L25.2,2.3c-0.7-0.6-1.6-0.6-2.3,0L5.8,16.9c-0.4,0.3-0.6,0.8-0.6,1.3c0,0,0,0,0,0.1v25.2
	c0,1,0.8,1.8,1.8,1.8H41c1,0,1.8-0.8,1.8-1.8L42.8,18.2C42.8,18.2,42.8,18.2,42.8,18.2z M8.6,22.3l8.2,7.6l-8.2,8.9V22.3z
	 M19.4,32.2l3.2,2.9c0.3,0.3,0.8,0.5,1.2,0.5c0.4,0,0.8-0.2,1.2-0.5l3.5-3.1l8.9,9.7H10.6L19.4,32.2z M31.1,29.7l8.2-7.4v16.4
	L31.1,29.7z M24.1,5.9l14.3,12.4L23.8,31.5L9.5,18.3L24.1,5.9z"/>
    </svg>
);

const EmailIcon = props => {

    if(props.type === "closed"){
        return <Icon component={ EmailClosedSvg } className={ props.className } />;
    }


    if(props.type === "open"){
        return <Icon component={ EmailOpenSvg } className={ props.className } />;
    }

    return <Icon component={ EmailSvg } className={ props.className } />;

};

export default EmailIcon;