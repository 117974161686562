import React from "react";
import { useQuery } from "@apollo/client";

import { GET_HALLS_OVERVIEW } from "graphql/query/hall-gql";

import { Loader } from "components/request-result";
import HallOverviewGridItem from "./hall-overview-grid-item";



const HallOverviewGrid = React.memo(({ filter }) => {

    const { data, loading } = useQuery(GET_HALLS_OVERVIEW, {
        variables: {
            eeId      : filter.data.where.eeId.value,
            subId     : filter.data.where.subcontractor?.value ,
            craftType : filter.data.where.craftType?.value
        },
        fetchPolicy: "cache-and-network"
    });

    const {
        hallsOverview : {
            halls
        } = {}
    } = data ? data : {};

    return(
        <div className="craft-overview-grid-wrap">
            { halls && !loading &&
                <div className="craft-overview-grid">
                    { halls.map(
                        ( props ) => <HallOverviewGridItem key={ props.id } { ...props } />)
                    }
                </div>
            }
            { loading && <Loader type="block" /> }
        </div>
    );
});

export default HallOverviewGrid;