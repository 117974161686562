import React from "react";

import CraftActionsModalList from "./craft-actions-modal-list";
import { ModalStandard } from "components/service";
import { useWindowDimensions } from "components/use-hooks";


const CraftActionsModal = ({
        stand,
        craftData,
    changeStatusOn
    }) => {

    const { width } = useWindowDimensions();

    return(
        <div className = "craft-actions-modal-wrap">
            <ModalStandard
                extraClass="craft-actions-modal"
                modalVisible={ craftData }
                title={
                    <>
                        <h3>{ craftData.title }</h3>
                        <p> { stand.title } </p>
                    </>
                }
                centered={ !(width <= 768) }
            >
                <CraftActionsModalList
                    stand={ stand }
                    craftData = { craftData }
                    changeStatusOn = { changeStatusOn }
                />
            </ModalStandard>
        </div>
    );
}

export default CraftActionsModal;