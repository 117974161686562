import React from 'react';
import Icon from '@ant-design/icons';


const HomeSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M38.3,42.2H29c-0.7,0-1.2-0.6-1.2-1.2V29.1h-7.2V41c0,0.7-0.6,1.2-1.2,1.2H9.8c-0.7,0-1.2-0.6-1.2-1.2V25.2h-3
            c-0.5,0-1-0.3-1.2-0.8c-0.2-0.5-0.1-1,0.3-1.4L23.2,4.8c0.5-0.5,1.3-0.5,1.8,0L43.4,23c0.4,0.4,0.5,0.9,0.3,1.4
            c-0.2,0.5-0.6,0.8-1.2,0.8h-2.9V41C39.6,41.7,39,42.2,38.3,42.2z M30.2,39.7h6.8V23.9c0-0.7,0.6-1.2,1.2-1.2h1.1L24,7.5L8.6,22.7
            h1.2c0.7,0,1.2,0.6,1.2,1.2v15.8h7V27.8c0-0.7,0.6-1.2,1.2-1.2H29c0.7,0,1.2,0.6,1.2,1.2V39.7z"
        />
    </svg>
);

const HomeIcon = props => {
    return <Icon component={ HomeSvg } className={ props.className } />;

};


export default HomeIcon;