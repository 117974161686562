import { useVarParam } from "components/use-hooks";


const UserPermissionHelper = () => {

    const me = useVarParam().get("me");

    const getPermissions = () => {
        return me.role.permissions;
    }

    const getPermissionByValue = (permissionValue) => {
        return getPermissions().filter( ( { value } ) => value === permissionValue)[0];
    }

    const getPermissionByType = (permissionType) => {
        return getPermissions().filter( ( { type } ) => type === permissionType)[0];
    }

    const hasPermission = ({ type, value }) => {
        if(type && value){
            return !!getPermissionByValue(value) && !!getPermissionByType(type);
        }

        if(value){
            return !!getPermissionByValue(value);
        }

        return !!getPermissionByType(type);
    }

    const hasOneOfPermission = (permissionArr) => {

        let oneOf = false;

        permissionArr.forEach( item => {
            if(hasPermission(item)){
                oneOf = true;
            }
        });

        return oneOf;

    }

    const equalSubcontractorId = ( id ) => {
        return me.subcontractor.id === id;
    }

    const equalUserId = ( id ) => {
        return me.id === id;
    }

    return  {
        getByType   : getPermissionByType,
        getByValue  : getPermissionByValue,
        has         : hasPermission,
        hasOneOf    : hasOneOfPermission,
        equalSubId  : equalSubcontractorId,
        equalUserId : equalUserId,
        all         : getPermissions
    }
};


export default UserPermissionHelper;