import React, {useEffect} from "react";
import { useMutation } from "@apollo/client";
import { Form, Input, InputNumber, Button } from "antd";

import { FURNISHING_ORDER } from "graphql/mutation/furnishing-gql";

//import FurnishingFields from "../../fields";
import { Inputs } from "components/layout";
import { useNotification } from "components/use-hooks";
import Localize, { localizeIt } from "components/service/localize";
import noScroll from "no-scroll";


const FurnishingRefundForm = ({
        furnishingId,
        callback,
        title,
        eeId,
        storeQty
    }) => {

    const { nError, nSuccess } = useNotification();

    const [ furnishingRefundAction, { loading }] = useMutation( FURNISHING_ORDER,
        {
            update(cache, { data }) {

                const {
                    furnishingOrderCreate : {
                        label,
                        message
                    }
                } = data;

                callback();

                nSuccess( label, message );
            }
        }
    );

    useEffect(() => {
        noScroll.on();

        return noScroll.off;
    }, []);

    return(
        <Form
            key="furnishing-refund-form"
            layout="vertical"
            className="furnishing-refund-form"
            onFinish={ (values) => {

                furnishingRefundAction({
                    variables: {
                        input: {
                            furnishing_id : furnishingId,
                            type : "correction",
                            qty  : Number(values.qty),
                            ee_id: eeId
                        }
                    }
                }).catch((error) => nError( error ))
            } }
        >
            <Form.Item
                name="title"
                label={ <Localize>FORMS.Input_Label_FurnishingName</Localize> }
                className="dark"
                initialValue={ title }
            >
                <Input name="title" disabled />
            </Form.Item>
            {/*<Form.Item*/}
            {/*    name="type"*/}
            {/*    label={ <Localize>FORMS.Input_Label_OrderType</Localize> }*/}
            {/*    className="dark"*/}
            {/*    initialValue={ "refund" }*/}
            {/*>*/}
            {/*    <FurnishingFields.OrderTypeSelect />*/}
            {/*</Form.Item>*/}
            <Form.Item
                name="currentQty"
                label={ <Localize>FORMS.Input_Label_CurrentQuantity</Localize> }
                className="dark"
                initialValue={ storeQty }
                rules={ [
                    {
                        type: "number",
                        pattern: /^\d*$/,
                        message: localizeIt("FORM_RULES.Min_Quantity")

                    },
                ] }
            >
                <InputNumber min={ 0 } max={ 1000 } disabled />
            </Form.Item>
            <Form.Item
                name="qty"
                label={ <Localize>FORMS.Input_Label_NewQuantity</Localize> }
                className="dark"
                initialValue={ storeQty }
                rules={ [
                    {
                        type: "number",
                        pattern: /^\d*$/,
                        message: localizeIt("FORM_RULES.Min_Quantity")

                    },
                    { required: true, message: localizeIt("FORM_RULES.Required_Quantity") }
                ] }
            >
                <Inputs.NumberControl min={ 0 } max={ 1000 } name="qty" />
            </Form.Item>
            <div className="form-actions">
                <Button
                    className = "dark"
                    type = "default"
                    onClick = { callback }
                >
                    <Localize wrap>GLOBAL.Button_Text_Cancel</Localize>
                </Button>
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={ loading }
                >
                    <Localize wrap>GLOBAL.Button_Text_Confirm</Localize>
                </Button>
            </div>
        </Form>
    );
}

export default FurnishingRefundForm;