import React from 'react';
import Icon from '@ant-design/icons';


const DamagesSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M38,6.2H10c-2.3,0-4.2,1.9-4.2,4.2v27.3c0,2.3,1.9,4.2,4.2,4.2h28c2.3,0,4.2-1.9,4.2-4.2V10.4C42.2,8.1,40.4,6.2,38,6.2z
	        M10,9.7h15.6l-0.2,6.6L16,19l2.8,8.2l-9.6,2.5V10.4C9.3,10,9.6,9.7,10,9.7z M38.7,37.7c0,0.4-0.3,0.7-0.7,0.7H10
	        c-0.4,0-0.7-0.3-0.7-0.7v-4.5l14.1-3.7l-2.9-8.3l8.3-2.4l0.2-9.3H38c0.4,0,0.7,0.3,0.7,0.7V37.7z"
        />
    </svg>
);

const DamagesIcon = props => {
    return <Icon component={ DamagesSvg } className={ props.className } />;

};


export default DamagesIcon;