import { gql } from '@apollo/client';


export const USER_LOGIN = gql`
    mutation UserLogin($data: UserLoginInput!) {
        userLogin(input: $data){
            access_token
            user{
                id
                name
                surname
                email
                phone
                status
                language_slug
                eeCurrentId @client
                role{
                    id
                    label
                    slug
                    permissions{
                        id
                        label
                        panel
                        type
                        value
                        description
                    }
                }
                exhibitionEvent{
                    id
                    title
                    status
                }
            }
        }
    }
`;

// export const USER_UPDATE = gql`
//     mutation UserUpdate($input: UserUpdateInput!) {
//         userUpdate(input: $input){
//             label
//             message
//             user{
//                 id
//                 first_name
//                 last_name
//                 email
//                 phone
//                 language_slug
//                 status
//                 role
//                 avatar
//                 updated_at
//                 created_at
//             }
//         }
//     }
// `;

export const USER_LOGOUT = gql`
    mutation UserLogout{
        userLogout {
            status
            message
        }
    }
`;