import React from 'react';
import Icon from '@ant-design/icons';


const SmileSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M24.2,5.6c-10,0-18.1,8.1-18.1,18.1c0,10,8.1,18.1,18.1,18.1s18.1-8.1,18.1-18.1C42.3,13.7,34.2,5.6,24.2,5.6z M24.2,37.9
		c-7.9,0-14.3-6.4-14.3-14.3c0-7.9,6.4-14.3,14.3-14.3s14.3,6.4,14.3,14.3C38.5,31.5,32.1,37.9,24.2,37.9z"/>
        <path d="M32.8,24.9c-0.9-0.5-2.1-0.1-2.6,0.8c-1.2,2.2-3.5,3.6-6,3.6c-2.5,0-4.8-1.4-6-3.6c-0.5-0.9-1.6-1.3-2.6-0.8
		c-0.9,0.5-1.3,1.6-0.8,2.6c1.8,3.5,5.4,5.7,9.4,5.7c3.9,0,7.5-2.2,9.4-5.6C34,26.5,33.7,25.4,32.8,24.9z"/>
        <path d="M18.9,21.4c1.3,0,2.3-1,2.3-2.3c0-1.3-1-2.3-2.3-2.3c-1.3,0-2.3,1-2.3,2.3C16.5,20.3,17.6,21.4,18.9,21.4z"/>
        <path d="M29.5,21.4c1.3,0,2.3-1,2.3-2.3c0-1.3-1-2.3-2.3-2.3c-1.3,0-2.3,1-2.3,2.3C27.2,20.3,28.2,21.4,29.5,21.4z"/>
    </svg>
);

const SmileIcon = props => {
    return <Icon component={ SmileSvg } className={ props.className } />;

};


export default SmileIcon;