import React from "react";
import { useQuery } from "@apollo/client";
import { useRouteMatch } from "react-router-dom";

import { GET_STAND } from "graphql/query/stand-gql";

import Stand from "components/stand";
import { useVarParam } from "components/use-hooks";
import { Wraps } from "components/layout";
import Localize, { localizeIt } from "components/service/localize";
import { Loader, NoData } from "components/request-result";


const StandLogPage = () => {

    const match = useRouteMatch();

    const { data, loading } = useQuery( GET_STAND, {
        variables: {
            id: +match.params.standId
        },
        fetchPolicy: 'cache-first'
    });

    const appParams = useVarParam();

    appParams.set({
        headerTitle: localizeIt("PAGE.Title_StandLog"),
        bottomBar: true
    });

    const {
        stand
    } = data ? data : {};

    return (
        <Wraps.Container className="page stand-log-page">
            { stand &&
                <>
                    <h3>{ stand.exhibitor }</h3>
                    <Stand.Grids.Log standId={ stand.id } />
                </>
            }
            { !loading && !stand &&
                <NoData
                    text = {{
                        before : <Localize>NO_DATA.Stand_DoesNotExist</Localize>
                    }}
                    icon = "stand"
                    className = "block"
                />
            }
            { loading && <Loader type="block" /> }
        </Wraps.Container>
    );
}

export default StandLogPage;