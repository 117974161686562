import { localizeIt } from "components/service/localize";


const nameByType = ({ jobType }) => {

    switch (jobType){
        case "all_claims" : return "JOB.Type_AllClaims";
        case "claims": return "JOB.Type_Claim";
        case "customer_claims": return "JOB.Type_CustomerClaim";
        case "last_minutes": return "JOB.Type_LastMinute";
        case "damages": return "JOB.Type_Damage";
        case "protocol_defects": return "JOB.Type_ProtocolDefect";

        default: return null;
    }
}

const localizedNamesByTypes = ({ jobTypes }) => {

    const localizedTypes = jobTypes.map( type => localizeIt( nameByType({ jobType: type }))).join(", ");

    return localizeIt(
        "JOB.Filter_Params_JobType",
        { withFilter : localizedTypes }
    )
}

const JobNameByTypeHelper = {
    single    : nameByType,
    localized : localizedNamesByTypes
}

export default JobNameByTypeHelper;