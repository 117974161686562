
const isAdmin = (role) => {
    return ["admin", "developer"].indexOf(role) !== -1;
}

const hasEvents = ( events ) => {
    return events.length >= 1;
}

const hasActiveEvent = (user) => {
    return hasEvents(user.exhibitionEvent) &&  isEventPermitted( user ) && !!user.eeCurrentId;
}

const isEventPermitted = (user, eeId) => {

    const { exhibitionEvent : ee } = user;
    const checkEEId = eeId ? eeId : user.eeCurrentId;


    return hasEvents(ee) && ee.filter( ({ id }) => id === checkEEId ).length >= 1;
}


const checkUser = {
    isAdmin,
    hasEvents,
    hasActiveEvent,
    isEventPermitted
}


export default checkUser;