import React from "react";
import { Button} from "antd";

import JobBlocks from "../../blocks";
import { Localize, ModalStandard } from "components/service";
import { Icons } from "components/layout";


const JobPhotoModal = (props) => {

    return(
        <div className = "job-photo-modal-wrap">
            <ModalStandard
                extraClass="dark full job-photo-modal"
                title={ <Localize>JOB.Modal_Title_Photo</Localize> }
                modalButton={
                    <Button type="ghost" className="dark">
                        <Icons.Photo />
                        <Localize wrap>JOB.Button_Text_ViewPhoto</Localize>
                    </Button>
                }
                centered={ false }
            >
                <JobBlocks.PhotoViewer
                    { ...props }
                />
            </ModalStandard>
        </div>
    );
}

export default JobPhotoModal;