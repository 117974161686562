import { HomeAppPage } from "./home";
import { LoginPage } from "./authorization";
import { ExhibitionsPage } from "./exhibition";
import StandPages from "./stand";
import HallPages from "./hall";
import JobPages from "./job";
import MorePages from "./more";
import Page404 from "./404/404-page";

import "./pages.scss";



export const Pages = {
    Home        : HomeAppPage,
    Exhibitions : ExhibitionsPage,
    Halls       : HallPages,
    Stands      : StandPages,
    Jobs        : JobPages,
    More        : MorePages,
    Page404
};

export const GuestPages = {
    Login : LoginPage
}