import React from "react";
import { Button } from "antd";

import JobBlocks from "../../blocks";
import JobHelpers from "../../helpers";
import Craft from "components/craft";
import { ModalStandard, Localize } from "components/service";
import { Icons } from "components/layout";


const JobEditModal = ({
        stand,
        craftData,
        buttonText = "",
        buttonType = "craft",
        jobType,
        job,
        iconClass = "in_progress",
    }) => {

    const ModalButton = (props) => {

        if(buttonType === "edit"){
            return (
                <Button {...props} className="job-edit-modal-button">
                    <Icons.Edit />
                </Button>
            )
        }

        return(
            <button {...props}>
                <Craft.Grids.Single
                    extraClass = { iconClass }
                    type = { craftData.type }
                    hasJobType={ [ jobType ] }
                />
                <Localize>{ buttonText }</Localize>
            </button>
        )
    }

    return(
        <div className = "job-edit-modal-wrap">
            <ModalStandard
                extraClass="job-edit-modal dark full"
                title={
                    <Localize jobType={ <Localize>{ JobHelpers.NameByType.single( { jobType } ) }</Localize> }>
                        { job ? "JOB.Modal_Title_EditJob" : "JOB.Modal_Title_NewJob" }
                    </Localize>
                }
                modalButton={ <ModalButton /> }
                centered={ false }
            >
                <JobBlocks.Edit
                    craft   = { craftData }
                    stand   = { stand }
                    jobType = { jobType }
                    job     = { job }
                />
            </ModalStandard>
        </div>
    );
}

export default JobEditModal;