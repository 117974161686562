import JobTagHelper from "./job-tag-helper";
import JobStatusHelper from "./job-status-helper";
import JobNameByTypeHelper from "./job-name-by-type-helper";
import JobCountInCraftsHelper from "./job-count-in-crafts-helper";
import JobFlagByNumberHelper from "./job-flag-by-number";


const JobHelpers = {
    Tag            : JobTagHelper,
    Status         : JobStatusHelper,
    NameByType     : JobNameByTypeHelper,
    CountInCrafts  : JobCountInCraftsHelper,
    FlagByNum      : JobFlagByNumberHelper
};

export default JobHelpers;