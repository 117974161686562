import React from 'react';
import Icon from '@ant-design/icons';


const ListSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M16.9,12.4h23.6c0.8,0,1.5-0.7,1.5-1.5s-0.7-1.5-1.5-1.5H16.9c-0.8,0-1.5,0.7-1.5,1.5S16.1,12.4,16.9,12.4z"/>
        <path d="M40.5,22.9H16.9c-0.8,0-1.5,0.7-1.5,1.5s0.7,1.5,1.5,1.5h23.6c0.8,0,1.5-0.7,1.5-1.5S41.4,22.9,40.5,22.9z"/>
        <path d="M40.5,35.7H16.9c-0.8,0-1.5,0.7-1.5,1.5s0.7,1.5,1.5,1.5h23.6c0.8,0,1.5-0.7,1.5-1.5S41.4,35.7,40.5,35.7z"/>
        <path d="M8.1,8.9c-1.2,0-2.2,1-2.2,2.2s1,2.2,2.2,2.2c1.2,0,2.2-1,2.2-2.2S9.2,8.9,8.1,8.9z"/>
        <path d="M8.1,21.8c-1.2,0-2.2,1-2.2,2.2s1,2.2,2.2,2.2c1.2,0,2.2-1,2.2-2.2S9.2,21.8,8.1,21.8z"/>
        <path d="M8.1,35.2c-1.2,0-2.2,1-2.2,2.2s1,2.2,2.2,2.2c1.2,0,2.2-1,2.2-2.2S9.2,35.2,8.1,35.2z"/>
    </svg>
);

const ListIcon = props => {
    return <Icon component={ ListSvg } className={ props.className } />;

};


export default ListIcon;