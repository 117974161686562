import React from 'react';
import Icon from '@ant-design/icons';


const SearchSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M41.5,40.6L30.7,29.8c2.4-2.5,3.9-5.9,3.9-9.7c0-7.8-6.3-14.1-14.1-14.1S6.3,12.3,6.3,20.1s6.3,14.1,14.1,14.1
	c3.1,0,6-1,8.4-2.8l11,11c0.2,0.2,0.6,0.4,0.9,0.4s0.6-0.1,0.9-0.4C42,41.9,42,41.1,41.5,40.6z M8.8,20.1c0-6.4,5.2-11.6,11.6-11.6
	s11.6,5.2,11.6,11.6s-5.2,11.6-11.6,11.6S8.8,26.5,8.8,20.1z"/>
    </svg>
);

const SearchIcon = props => {
    return <Icon component={ SearchSvg } className={ props.className } />;

};


export default SearchIcon;