import React from "react";

import HallModals from "../../modals";
import { FilterParamsList } from "components/service";


const HallFilterBlock = ({ filter } ) => {

    return(
        <div className="filter-block hall-filter-block">
            <div className="modals">
                <HallModals.FilterModal filter = { filter } />
            </div>
            <FilterParamsList
                filter = { filter }
                fieldsArr={ [ "where" ] }
            />
        </div>
    )
}

export default HallFilterBlock;