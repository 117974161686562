import useVarParam from "./use-var-param";
import { errorNotification, successNotification } from "../request-result";


const useNotification = () => {

    const { errorOn, successOn }  = useVarParam().get("notifications");

    return{
        "nError"   : (error, forced = false) => {
            if(errorOn || forced){
                errorNotification(error)
            }

        },
        "nSuccess" : (title, description, duration, forced = false) => {
            if(successOn || forced){
                successNotification({ title, description, duration })
            }
        }
    }
};

export default useNotification;