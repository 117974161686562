

const JobCountInCraftsHelper = ({ crafts }) => {

    let jobCount = {
        open : 0,
        inProgress : 0,
        completed : 0,
        mustBeDone : 0,
        other : 0,
        total : 0
    };

    crafts.forEach(
        ( { craft_jobs } ) => {

            craft_jobs.reduce((count, job) => {
                if( job.type !== "damages" ){
                    switch(job.status){
                        case "waiting": count.open++; break;
                        case "in_progress": count.inProgress++; break;
                        case "completed": count.completed++; break;
                        default : count.other++;
                    }
                }
                return count;

            } , jobCount);

        }
    );

    jobCount.mustBeDone = jobCount.open + jobCount.inProgress;
    jobCount.total = jobCount.open + jobCount.inProgress + jobCount.completed;

    return jobCount;
}

export default JobCountInCraftsHelper;