import React from "react";

import Exhibition from "components/exhibition";
import { useMe, useVarParam } from "components/use-hooks";
import { Wraps } from "components/layout";
import { localizeIt }  from "components/service/localize";


const HomeAppPage = () => {

    const { me } = useMe(),
          appParams = useVarParam();

    appParams.set({
        headerTitle: localizeIt("PAGE.Title_Overview"),
        bottomBar: true,
        searchOn: true
    });

    const { exhibitionEvent, eeCurrentId } = me;

    return(
        <Wraps.Container className="page home-page">
            <Exhibition.Fields.Select
                currentEEid = { eeCurrentId }
                ee = { exhibitionEvent }
            />
            <Exhibition.Blocks.Time
                moment = { appParams.get("moment") }
                ee = {
                    exhibitionEvent.find(
                        ({ id }) => id === eeCurrentId
                    )
                }
            />
            <Exhibition.Grids.Statistic
                eeId = { eeCurrentId }
            />
        </Wraps.Container>
    )
};

export default HomeAppPage;