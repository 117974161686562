import React from 'react';
import Icon from '@ant-design/icons';


const PhoneSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M33.3,42c-0.8,0-1.5-0.1-2.3-0.3c-3.2-0.8-6.1-2.2-8.8-3.9c-2.5-1.6-4.8-3.4-6.8-5.5c-1.9-1.9-3.6-4-5.1-6.2
            c-1.6-2.4-3-5.1-3.9-7.8c-1.2-3.6-0.2-7.5,2.5-10.2l1.9-1.9c1-1,2.7-1,3.7,0c0,0,0.1,0.1,0.1,0.1l6.1,6.1c0.5,0.5,0.8,1.2,0.8,1.9
            c0,0.7-0.2,1.3-0.7,1.8c0,0-0.1,0.1-0.1,0.1L17,19.6c-0.6,0.6-0.7,1.5-0.2,2.2c1.3,1.7,2.7,3.4,4.2,4.9c1.7,1.7,3.5,3.3,5.5,4.6
            c0.7,0.5,1.6,0.4,2.2-0.2l3.5-3.5c1-1,2.7-1,3.7,0l6.1,6.2c0.5,0.5,0.8,1.2,0.8,1.9c0,0.7-0.3,1.4-0.8,1.9l0,0l-1.7,1.6
            C38.5,41,35.9,42,33.3,42z M12.7,7.9C12.6,7.9,12.6,7.9,12.7,7.9l-2,2c-2,2-2.8,4.9-1.9,7.6c0.8,2.5,2,5,3.6,7.2
            c1.4,2.1,3,4.1,4.8,5.8c1.9,2,4.1,3.7,6.4,5.2c2.5,1.6,5.2,2.8,8.1,3.6c2.5,0.6,5.2-0.2,7-2l1.7-1.6c0,0,0-0.1,0-0.2l-6.1-6.2
            c-0.1-0.1-0.1-0.1-0.2,0l-3.5,3.5c-1.5,1.4-3.7,1.6-5.4,0.5c-2.1-1.4-4-3.1-5.8-4.9c-1.6-1.6-3.1-3.3-4.4-5.2c-1.2-1.7-1-4,0.4-5.4
            l3.5-3.5c0,0,0,0,0.1-0.1c0,0,0-0.1,0-0.2L12.7,7.9C12.7,7.9,12.7,7.9,12.7,7.9C12.7,7.9,12.7,7.9,12.7,7.9z M41.2,36.6L41.2,36.6
            L41.2,36.6z"
        />
    </svg>
);

const PhoneIcon = props => {
    return <Icon component={ PhoneSvg } className={ props.className } />;

};

export default PhoneIcon;