import React from "react";
import { Link, useLocation } from "react-router-dom";

import CraftHelpers from "../../helpers";
import { UserHelpers } from "components/user";
import { Icons } from "components/layout";


const { permission } = UserHelpers;

const CraftOverviewGridItem = ({ craftLabel, craftType, counters }) => {

    const location = useLocation();

    return(
        <div className="craft-overview-grid-item">
            <h3>
                <Icons.Craft
                    type={ /\d/.test(craftType) ? craftType.replace(/_\d/g, '') : craftType }
                />
                { craftLabel }
            </h3>
            <div className="overview-statistic bg-light-bordered">
                { CraftHelpers.OverviewCountTypes.map(
                    ( { label, type, route, getFilter = () => {}, getColor = () => {} } ) => {

                    const Wrap = ({ children, className = "" }) => {

                        const routeExist = route.includes("jobs") ? permission().has({type: "craft_job", value: "view_job"}) : true;

                        console.log("ROUTE", route ,route.includes("jobs"), routeExist)
                        if(counters[type] > 0 && route && routeExist){
                            return(
                                <Link
                                    className= { `overview-statistic-item ${ className }` }
                                    to={{
                                        pathname: route,
                                        state: {
                                            from : location.pathname,
                                            queryParams : getFilter({ craftType })
                                        }
                                    }}
                                >
                                    { children }
                                </Link>
                            )
                        }

                        return (
                            <div className = { `overview-statistic-item ${ className }` } >
                                { children }
                            </div>
                        )
                    }

                    return(
                        <Wrap
                            key       = { type }
                            className = { getColor( counters[type] ) }
                        >
                            <h4>{ counters[type] }</h4>
                            <p>{ label }</p>
                        </Wrap>
                    )
                }) }
            </div>

        </div>
    )
};

export default CraftOverviewGridItem;