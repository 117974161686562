import { useState } from "react";
import { mergeDeep } from "@apollo/client/utilities";

import { UserHelpers } from "components/user";
import { useMe, useFilterStorage } from "components/use-hooks";


const { permission } = UserHelpers;

const useStandFilterHook = ({
        sort : sortParams = [],
        filter : filterParams = {}
    }) => {

    const { me } = useMe(),
          filterStorage = useFilterStorage();

    const defaultFilter = {
        where: {
            standId : {},
            hallId : {},
            eeId   : {
                column   : "EE_ID",
                operator : "EQ",
                value    : me.eeCurrentId,
            }
        },
        whereCrafts : {
            craftType : {},
            craftStatus : {},
            craftNotDisabled : {
                column: "STATUS",
                operator : "NEQ",
                value : "disabled"
            },
            subcontractorId :
                permission().has({ type: "sub_view" }) ? {
                    column: "SUBCONTRACTOR_ID",
                    operator : "EQ",
                    value : me.subcontractor.id
                } : {},
        },
        activeFilters : 0
    },
    defaultSort = [{ column: "ID", order : "DESC" }];

    const [ filter, setFilter ] = useState(
                mergeDeep(defaultFilter, filterParams)
          ),
          [ sort, setSort ] = useState( [...mergeDeep(defaultSort, sortParams)] );

    filterStorage.store("stand", {
        filter, sort
    })

    return {
        filter : {
            data  : filter,
            set   : setFilter,
            reset : () => setFilter(defaultFilter)
        },
        sort : {
            data  : sort,
            set   : setSort,
            reset : () => setSort(defaultSort)
        }
    };
}

export default useStandFilterHook;