import React from 'react';
import Icon from '@ant-design/icons';


const LastMinuteSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M26,6.1c-5.4,0-10.4,2.4-13.9,6.5c-0.6,0.7-0.5,1.8,0.2,2.5c0.7,0.6,1.8,0.5,2.5-0.2c2.8-3.3,6.9-5.2,11.2-5.2
		c8.1,0,14.7,6.6,14.7,14.7S34.1,39,26,39c-4.5,0-8.7-2-11.5-5.5c-0.6-0.8-1.7-0.9-2.5-0.3c-0.8,0.6-0.9,1.7-0.3,2.5
		c3.5,4.3,8.7,6.8,14.2,6.8c10,0,18.2-8.2,18.2-18.2S36,6.1,26,6.1z"/>
        <path d="M26.3,29.4l9.3-9.3c0.7-0.7,0.7-1.8,0-2.5c-0.7-0.7-1.8-0.7-2.5,0l-7.3,7.3l-4.1-2.7c-0.8-0.5-1.9-0.3-2.4,0.5
		c-0.5,0.8-0.3,1.9,0.5,2.4L26.3,29.4z"/>
        <path d="M17.7,18.7c0-1-0.8-1.8-1.7-1.8H3.5c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8H16C16.9,20.5,17.7,19.7,17.7,18.7z"/>
        <path d="M17.7,24c0-1-0.8-1.8-1.7-1.8H5.2c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8H16C16.9,25.8,17.7,25,17.7,24z"/>
        <path d="M17.7,29.3c0-1-0.8-1.8-1.7-1.8H8.1c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8H16C16.9,31.1,17.7,30.3,17.7,29.3z"/>
    </svg>
);

const LastMinuteIcon = props => {
    return <Icon component={ LastMinuteSvg } className={ props.className } />;

};


export default LastMinuteIcon;