import React from "react";
import { Select } from "antd";

import { Icons } from "components/layout";
import Localize from "components/service/localize";


const { Option } = Select;

const JobStatusSelectField = ({ ...props }) => {

    return(
        <Select
            className={ `job-status-select-field` }
            suffixIcon={
                <Icons.Arrow type="filled"/>
            }
            mode="multiple"
            defaultValue={ null }
            { ...props }
        >
            <Option value={ null }>
                <Localize>FORMS.Select_DefaultValue_All</Localize>
            </Option>
            <Option value={ 'waiting' }>
                <Localize>FORMS.Select_JobStatusValue_JobsOpen</Localize>
            </Option>
            <Option value={ `completed` }>
                <Localize>FORMS.Select_JobStatusValue_FinishedJobs</Localize>
            </Option>
        </Select>
    );
}

export default JobStatusSelectField;