import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { mergeDeep } from "@apollo/client/utilities";

import Hall from "components/hall";
import { useVarParam } from "components/use-hooks";
import { Wraps } from "components/layout";
import { localizeIt } from "components/service/localize";



const HallsOverviewPage = () => {

    const appParams = useVarParam();
    const { state : {
        queryParams
    } = {} } = useLocation();

    const { filter } = Hall.Hooks.useFilter(
        appParams.get("rememberQueryParams").hallOverview,
    );

    useEffect( () => {
        if(filter && queryParams){
            filter.set(mergeDeep(filter.data,  queryParams.filter));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParams])


    appParams.set({
        headerTitle: localizeIt("PAGE.Title_HallsOverview"),
        bottomBar: true
    });

    return (
        <Wraps.Container className="page halls-overview-page">
            <Hall.Blocks.Filter filter={ filter } />
            <Hall.Grids.Overview filter={ filter } />
        </Wraps.Container>
    );
}

export default HallsOverviewPage;