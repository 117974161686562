import React from 'react';
import Icon from '@ant-design/icons';


const FlagSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M41.8,29.8l-9.6-13l9.5-13.6H6.1v4.9v21.7v14c0,1.2,0.9,2.1,2.1,2.1s2.1-1,2.1-2.1v-14H41.8z"/>
    </svg>
);

const FlagIcon = props => {
    return <Icon component={ FlagSvg } className={ props.className } />;

};


export default FlagIcon;