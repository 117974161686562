import { gql } from '@apollo/client';


export const GET_HALL = gql`
    query GetHall(
        $id: ID!
        $ee_id: ID
    ) {
        hall(
            id: $id
            ee_id: $ee_id
        ){
            id
            title
            ee_id
            number
            stands_count
            hall_plan
            hall_plan_img
            finished
            exhibitionEvent {
                id
                title
                status
                path_model
            }

        }
    }
`;


export const GET_HALLS = gql`
    query GetHalls(
        $text: String
        $ee_id: ID
        $first: Int
        $page: Int
        $orderBy: [QueryHallsOrderByOrderByClause!]
        $where: QueryHallsWhereWhereConditions
    ) {
        halls(
            text: $text
            ee_id: $ee_id
            first: $first
            page: $page
            orderBy: $orderBy
            where: $where
        ){
            paginatorInfo{
                total
                count
                currentPage
                perPage
                lastPage
            }
            data{
                id
                title
                ee_id
                number
                stands_count
                hall_plan
                hall_plan_img
                finished
                exhibitionEvent {
                    id
                    title
                    status
                    path_model
                }
            }
        }
    }
`;


export const GET_HALLS_OVERVIEW = gql`
    query GetHallsOverview(
        $eeId      : ID!
        $subId     : ID
        $craftType : String

    ) {
        hallsOverview(
            ee_id      : $eeId
            sub_id     : $subId
            craft_type : $craftType
        ){
            id
            halls{
                id
                title
                statistic
            }
        }
    }
`;


