import React from "react";
import { useQuery } from "@apollo/client";

import { GET_CRAFTS_OVERVIEW } from "graphql/query/craft-gql";

import CraftOverviewGridItem from "./craft-overview-grid-item";
import CraftHelpers from "../../helpers";
import { Loader } from "components/request-result";


const CraftOverviewGrid = React.memo(({ filter }) => {

    const { data, loading } = useQuery(GET_CRAFTS_OVERVIEW, {
        variables: {
            eeId   : filter.data.eeId.value,
            hallId : filter.data.hallId.value
        },
        fetchPolicy: "cache-and-network",
    });

    const {
        craftsOverview : {
            type : crafts = {}
        } = {}
    } = data ? data : {};


    return(
        <div className="craft-overview-grid-wrap">
            { crafts &&
                <div className="craft-overview-grid">
                    { Object.entries(CraftHelpers.Types.list()).map( item => {
                        const [ type, label ] = item;

                        if(!crafts[type]) return null

                        return(
                            <CraftOverviewGridItem
                                key        = { type }
                                craftLabel = { label }
                                counters   = { crafts[type] }
                                craftType  = { type }
                            />
                        )
                    })}
                </div>
            }
            { loading && <Loader type="block" /> }
        </div>
    );
});

export default CraftOverviewGrid;