import React from "react";

import CraftModals from "../../modals";
import CraftLists from "../../lists";


const CraftFilterBlock = ( { filter } ) => {

    return(
        <div className="filter-block craft-filter-block">
            <div className="modals">
                <CraftModals.FilterModal filter = { filter } />
            </div>
            <CraftLists.OverviewFilterParams
                filter = { filter }
            />
        </div>
    )
}

export default CraftFilterBlock;