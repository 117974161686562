import React, { useState} from "react";
import { useQuery } from "@apollo/client";
import { Button } from "antd";

import { GET_DAMAGE_REPORT } from "graphql/query/damages-gql";

import DamageButtons from "../buttons";
import DamageHooks from "../hooks";
import DamagePhotoViewerBlock from "./damage-photo-viewer-block";
import { Localize } from "components/service";
import { Loader, NoData } from "components/request-result";


const DamageReportBlock = ({ callback, stand, reportId }) => {

    const  [ currentReportId, setCurrentReportId ] = useState(reportId);

    const { data, loading } = useQuery(GET_DAMAGE_REPORT, {
        variables: {
            reportId : currentReportId || null
        },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        skip: !currentReportId
    });

    const createLoading = DamageHooks.useCreateDamageReport({
        stand,
        loading,
        data,
        setCurrentReportId
    })

    if(!data || (createLoading || loading) ){
        return <Loader type="block" blockClass={ "color-primary" }  />;
    }

    const { damageReport : {
        id, status, total_files, damageFiles
    } = {} } = data || {};


    return(
        <>
            <DamageButtons.SegmentedStatus
                id       = { id }
                status   = { status }
                disabled = { !id || Number(total_files) === 0 }
            />
            { total_files > 0 ?
                <DamagePhotoViewerBlock
                    damageFiles={ damageFiles }
                /> :
                <NoData
                    className="damage-photo"
                    icon="job"
                    text={{
                        before: <Localize br={ <br /> }>NO_DATA.DamagePhoto</Localize>
                    }}
                />
            }
            <div className="damage-photo-action">
                <DamageButtons.AddPhoto
                    reportId={ id }
                />
                <Button
                    type = "default"
                    onClick = { callback }
                >
                    <Localize wrap>GLOBAL.Button_Text_Cancel</Localize>
                </Button>
            </div>
        </>
    )
};

export default DamageReportBlock;