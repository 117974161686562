import React from 'react';
import Icon from '@ant-design/icons';

import { Loader } from "components/request-result";


const ControlSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M39.1,22.6c-1,0-1.8,0.8-1.8,1.8c0,7.6-6.2,13.7-13.7,13.7S9.8,31.9,9.8,24.4S16,10.7,23.6,10.7c3.2,0,6.3,1.1,8.7,3.1
		c0.8,0.6,1.9,0.5,2.5-0.2c0.6-0.8,0.5-1.9-0.2-2.5c-3.1-2.6-7-4-11-4C14,7,6.2,14.8,6.2,24.4S14,41.7,23.6,41.7s17.3-7.8,17.3-17.3
		C40.9,23.4,40.1,22.6,39.1,22.6z"/>
        <path d="M43.3,11.1c-0.7-0.7-1.9-0.7-2.6,0L24.8,27l-7.2-7.2c-0.7-0.7-1.9-0.7-2.6,0c-0.7,0.7-0.7,1.9,0,2.6l8.4,8.4
		c0.4,0.4,0.8,0.5,1.3,0.5c0.5,0,0.9-0.2,1.3-0.5l17.2-17.2C44,12.9,44,11.8,43.3,11.1z"/>
    </svg>
);

const ControlIcon = props => {

    if(props.loading){
        return <Loader />;
    }

    return <Icon component={ ControlSvg } className={ props.className } />;

};


export default ControlIcon;