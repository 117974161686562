import { errorNotification } from "../components/request-result";


const fetchQueryMore = (queryModel, fetchMore, pageInfo, elements) => {

    if(pageInfo.hasNextPage){
        fetchMore({
            variables: {
                after: pageInfo.endCursor
            },
            updateQuery: (previousResult, {fetchMoreResult}) => {

                const {
                    edges: newEdges,
                    pageInfo,
                    __typename : typename
                } = fetchMoreResult[ queryModel ];

                return newEdges.length ?
                    {
                        [ queryModel ]: {
                            edges: [
                                ...elements,
                                ...newEdges
                            ],
                            __typename: typename,
                            pageInfo
                        }
                    } : previousResult;
            }
        }).catch((error) => {
            errorNotification(error);
        })
    }

}

export default fetchQueryMore;