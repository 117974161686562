import React from "react";

import JobForms from "../../forms";
import { FilterModalStandard } from "components/service";


const JobFilterModal = (props) => {

    return(
        <FilterModalStandard
            wrapClass="job-filter-modal-wrap"
            modalClass="job-filter-modal"
        >
            <JobForms.Filter
                { ...props }
            />
        </FilterModalStandard>
    );
};

export default JobFilterModal;