import React from 'react';
import Icon from '@ant-design/icons';


const ClockSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M24.1,5.8c-10,0-18.1,8.1-18.1,18.1c0,10,8.1,18.1,18.1,18.1c10,0,18.1-8.1,18.1-18.1C42.2,13.9,34.1,5.8,24.1,5.8z
		 M24.1,38.1c-7.9,0-14.3-6.4-14.3-14.3c0-7.9,6.4-14.3,14.3-14.3c7.9,0,14.3,6.4,14.3,14.3C38.4,31.7,32,38.1,24.1,38.1z"/>
        <path d="M34,26.9l-8.5-4v-8.7c0-1-0.9-1.9-1.9-1.9c-1,0-1.9,0.8-1.9,1.9v9.9c0,0.7,0.4,1.4,1.1,1.7l9.6,4.5
		c0.3,0.1,0.5,0.2,0.8,0.2c0.7,0,1.4-0.4,1.7-1.1C35.3,28.4,34.9,27.3,34,26.9z"/>
    </svg>
);

const ClockIcon = props => {
    return <Icon component={ ClockSvg } className={ props.className } />;

};

export default ClockIcon;