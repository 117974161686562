import React from "react";

import DamageBlocks from "../../blocks";
import Localize from "components/service/localize";


const DamageGridItem = ({
        id,
        stand,
        status = "new",
        total_files : count = 69,
    }) => {


    return(
        <div
            className={ `damage-grid-item bg-light-bordered` }
        >
            <div className="damage-grid-item-hero grid-item-hero">
                <div className={ `title` }>
                    <Localize
                        wrap="h3"
                        exhibitor={ stand.exhibitor }
                    >
                        DAMAGE.Card_Title
                    </Localize>
                </div>
                <DamageBlocks.Info { ...stand } />
            </div>
            <div className={ `damage-grid-item-body grey` } >
                <DamageBlocks.Preview
                    reportId = { id }
                    count    = { count }
                    status   = { status }
                    stand    = { {
                        ...stand,
                        damageReport: {
                            id,
                            status,
                            total_files : count
                        }
                    }}
                    withStandLink
                />
            </div>
        </div>
    );
}

export default React.memo(DamageGridItem);