import React from "react";
import { Localize } from "components/service";
import { Icons } from "components/layout";


const JobTagHelper = ({ jobType }) => {

    switch (jobType){
        case "claims":
            return <Localize wrapClass={ "job-tag claim" }  wrap="span">JOB.Tag_Claim</Localize>

        case "customer_claims":
            return <Localize wrapClass={ "job-tag claim" }  wrap="span">JOB.Tag_CustomerClaim</Localize>

        case "mp_and_customer_claims":
            return <Localize wrapClass={ "job-tag claim" }  wrap="span">JOB.Tag_MPAndCustomerClaim</Localize>

        case "last_minutes":
            return <Localize wrapClass={ "job-tag last" }  wrap="span">JOB.Tag_LastMinute</Localize>;

        case "damages":
            return <Localize wrapClass={ "job-tag damage" }  wrap="span">JOB.Tag_Damage</Localize>;
        case "damage_check":
            return <span className="job-tag damage-status"><Icons.Check /></span>;
        case "damage_double_check":
            return <span className="job-tag damage-status"><Icons.Check type="double" /></span>;

        case "protocol_defects":
            return <Localize wrapClass={ "job-tag protocol" }  wrap="span">JOB.Tag_ProtocolDefect</Localize>;

        default: return null;
    }
}

export default JobTagHelper;