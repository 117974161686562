import { gql } from "@apollo/client";


export const FURNISHING_ORDER = gql`
    mutation FurnishingOrderCreate(
        $input : FurnishingOrderFieldsInput!
    ) {
        furnishingOrderCreate(
            input: $input
        ) {
            label
            message
            furnishingOrder{
                id
                qty
                furnishing_id
                type
                ee_id
                furnishing {
                    id
                    title
                    sku
                    qty
                    price
                    ee_id
                    image
                    created_at
                }
                created_at
            }
        }
    }
`;

export const FURNISHING_ORDER_DELETE = gql`
    mutation FurnishingOrderDelete(
        $id: ID!
        $eeId : ID!
    ) {
        furnishingOrderDelete(
            id: $id 
            ee_id: $eeId 
            
        ) {
            label
            message
        }
    }
`;