import React from "react";
import { Link, useLocation } from "react-router-dom";

import Icons from "../../icons";
import { Localize } from "components/service";
import { UserHelpers } from "components/user";

import "./more-menu.scss";


const { permission } = UserHelpers;

const MoreMenu = () => {

    const location = useLocation();

    return(
        <ul className="more-menu">
            <li>
                <Link
                    to={{
                        pathname: "/exhibitions",
                        state: { from : location.pathname }
                    }}
                >
                    <Icons.List />
                    <Localize>MENUS.Label_SelectExhibition</Localize>
                </Link>
            </li>
            <li>
                <Link
                    to={{
                        pathname: "/more/expo-plan/",
                        state: { from : location.pathname }
                    }}
                >
                    <Icons.Plan/>
                    <Localize>MENUS.Label_ExpoPlan</Localize>
                </Link>
            </li>

            <li>
                <Link
                    to={{
                        pathname: "/more/halls-overview",
                        state: { from : location.pathname }
                    }}
                >
                    <Icons.Stands />
                    <Localize>MENUS.Label_HallsOverview</Localize>
                </Link>
            </li>
            { permission().has({ value: "view_damages" }) &&
                <li>
                    <Link
                        to={{
                            pathname: "/more/view-damages",
                            state: {from: location.pathname}
                        }}
                    >
                        <Icons.Damages/>
                        <Localize>MENUS.Label_ViewDamages</Localize>
                    </Link>
                </li>
            }
            <li>
                <Link to={{
                    pathname: "/more/furnishing",
                    state: { from : location.pathname }
                }}>
                    <Icons.Furnishing />
                    <Localize>MENUS.Label_FurnishingLeftovers</Localize>
                </Link>
            </li>
            <li>
                <Link to={{
                    pathname: "/more/documents",
                    state: { from : location.pathname }
                }}>
                    <Icons.Documents />
                    <Localize>MENUS.Label_Documents</Localize>
                </Link>
            </li>
            <li>
                <Link
                    to={{
                        pathname: "/more/help-and-imprint",
                        state: { from : location.pathname }
                    }}
                >
                    <Icons.Info />
                    <Localize>MENUS.Label_HelpImprint</Localize>
                </Link>
            </li>
        </ul>
    );
}

export default MoreMenu;