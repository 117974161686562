import StandKeyEditForm from "./stand-key-edit-form";
import StandPhotoForm from "./stand-photo-form";
import StandFilterForm from "./stand-filter-form";

import "./stand-forms.scss";


const StandForms = {
    KeyEdit : StandKeyEditForm,
    Photo   : StandPhotoForm,
    Filter  : StandFilterForm
};


export default StandForms;