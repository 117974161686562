import React from "react";
import { Select } from "antd";

import { Icons } from "components/layout";
import Localize from "components/service/localize";


const { Option } = Select;

const JobFlagSelectField = ({ ...props }) => {

    return(
        <Select
            className={ `job-status-select-field` }
            suffixIcon={
                <Icons.Arrow type="filled"/>
            }
            defaultValue={ null }
            { ...props }
        >
            <Option value={ null }>
                <Localize>FORMS.Select_DefaultValue_All</Localize>
            </Option>
            <Option value={ 1 }>
                <Localize>FORMS.Select_JobFlag_WithoutFlag</Localize>
            </Option>
            <Option value={ 2 }>
                <Localize>FORMS.Select_JobFlag_WithFlag</Localize>
            </Option>
        </Select>
    );
}

export default JobFlagSelectField;