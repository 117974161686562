import React, { useState } from "react";
import { Button, Form } from "antd";

import Exhibition from "components/exhibition";
import Craft from "components/craft";
import Localize, { localizeIt } from "components/service/localize";
import { UserHelpers } from "components/user";


const { permission } = UserHelpers;

const formFinish = (values, data, set, callback, changedFieldsCount) => {

    const [ craftType, craftLabel ] = values.craftType ?  values.craftType.split(":") : [],
          [ subcontractorId, subcontractorLabel ] = values.subcontractor ?  values.subcontractor.split(":") : [];

    set( {
        ...data,
        where : {
            ...data.where,
            craftType : values.craftType ? {
                label    :
                    localizeIt(
                        "JOB.Filter_Params_CraftType",
                        { withFilter : craftLabel }
                    ),
                column   : "CRAFT_TYPE",
                operator : "EQ",
                value    : craftType,
            } : {},
            subcontractor : values.subcontractor ? {
                label: subcontractorLabel,
                column: "SUBCONTRACTOR_ID",
                operator : "EQ",
                value : subcontractorId
            } : {}
        },
        activeFilters : changedFieldsCount
    })

    callback();
}

const HallOverviewFilterForm = ({ filter, callback }) => {

    const { data, set, reset } = filter,
        {
            where : {
                craftType,
                subcontractor
            },
            activeFilters
        } = data;


    const [ changedFieldsCount, setChangedFieldsCount ] = useState( activeFilters ),
          isSubcontractor = permission().has({ type: "sub_view" });


    return(
        <Form
            key="craft-overview-filter-form"
            layout="vertical"
            className="craft-overview-form"
            onFinish={ (values) => {
                formFinish( values, data, set, callback, changedFieldsCount );
            } }
            onValuesChange={ (changedValues, allValues) => {
                const activeFiltersCount = Object.values(allValues).filter( (item) => item !== null).length;

                setChangedFieldsCount(activeFiltersCount);
            }}
        >
            <Form.Item
                name="craftType"
                label={ <Localize>FORMS.Input_Label_CraftType</Localize> }
                className="dark"
                initialValue={ craftType && craftType.value ? `${ craftType.value }:${ Craft.Helpers.Types.list( craftType.value ) }` : null }
            >
                <Craft.Fields.TypeSelect />
            </Form.Item>
            { !isSubcontractor &&
                <Form.Item
                    name="subcontractor"
                    label={ <Localize>FORMS.Input_Label_Subcontractor</Localize> }
                    className="dark"
                    initialValue={ subcontractor && subcontractor.value ? `${ subcontractor.value }:${ subcontractor.label }` : null }
                >
                    <Exhibition.Fields.SubcontractorSelect />
                </Form.Item>
            }
            <div className="form-actions">
                { (!changedFieldsCount ) ?
                    <Button
                        className = "dark"
                        type = "default"
                        onClick = { callback }
                    >
                        <Localize wrap>GLOBAL.Button_Text_Cancel</Localize>
                    </Button> :
                    <>
                        <Button
                            className = "dark"
                            type = "default"
                            onClick = { () => {
                                reset();
                                callback();
                            }}
                        >
                            <Localize wrap>FORMS.Button_Text_ResetFilters</Localize>
                        </Button>
                        <Button
                            type="primary"
                            htmlType="submit"
                        >
                            <Localize wrap>FORMS.Button_Text_ApplyFilters</Localize>
                        </Button>
                    </>
                }
            </div>
        </Form>
    );
}

export default HallOverviewFilterForm;