import React from 'react';
import Icon from '@ant-design/icons';


const KeySvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M33.3,18.9c-2.6,0-4.6-2.1-4.6-4.6c0-2.6,2.1-4.6,4.6-4.6s4.6,2.1,4.6,4.6C37.9,16.8,35.8,18.9,33.3,18.9z M33.3,12.1
		c-1.2,0-2.1,1-2.1,2.1s1,2.1,2.1,2.1s2.1-1,2.1-2.1S34.5,12.1,33.3,12.1z"/>
        <g>
            <path d="M6.4,42.7c-0.3,0-0.6-0.1-0.9-0.4c-0.2-0.2-0.4-0.6-0.4-0.9v-7.6c0-0.3,0.1-0.7,0.4-0.9c0.2-0.2,0.6-0.4,0.9-0.4l1.6,0
			L8,30.2c0-0.3,0.1-0.7,0.4-0.9c0.2-0.2,0.6-0.4,0.9-0.4h3.1v-3.2c0-0.4,0.1-0.7,0.4-0.9c0.3-0.2,0.6-0.4,1-0.3l2.7,0.3l-0.1-2.9
			c0-0.4,0.1-0.7,0.4-0.9c0.3-0.2,0.6-0.4,1-0.4l3.4,0.2c-0.6-1.4-0.9-3-0.9-4.6c0-6.5,5.3-11.7,11.7-11.7c6.5,0,11.7,5.3,11.7,11.7
			c0,6.5-5.3,11.7-11.7,11.7c-1.4,0-2.6-0.1-3.8-0.5L13.3,42.2c-0.2,0.2-0.5,0.4-0.9,0.4L6.4,42.7C6.4,42.7,6.4,42.7,6.4,42.7z
			 M7.6,35.1v5l4.3-0.1l15-15c0.1-0.1,0.2-0.2,0.3-0.2c0.3-0.2,0.8-0.2,1.1,0c1.2,0.5,2.2,0.6,3.6,0.6c5.1,0,9.2-4.1,9.2-9.2
			S37,7,31.9,7s-9.2,4.1-9.2,9.2c0,1.9,0.6,3.7,1.7,5.3c0.3,0.4,0.3,0.9,0.1,1.3c-0.2,0.4-0.7,0.7-1.2,0.6l-4.3-0.3l0.1,2.9
			c0,0.4-0.1,0.7-0.4,1c-0.3,0.2-0.6,0.4-1,0.3l-2.8-0.3v3c0,0.7-0.6,1.2-1.2,1.2h-3.1l0.1,2.5c0,0.3-0.1,0.7-0.4,0.9
			c-0.2,0.2-0.6,0.4-0.9,0.4L7.6,35.1z"/>
        </g>
    </svg>
);

const KeyIcon = props => {
    return <Icon component={ KeySvg } className={ props.className } />;

};

export default KeyIcon;