import React from 'react';
import { Col, Row } from "antd";

import { useColumnsChildren } from "components/use-hooks";


const TwoColWrap = ({children, className = null}) => {

    const { leftColumn, rightColumn } = useColumnsChildren(children);

    const spanList = {
        left: { xs: 0, sm: 0, md: 6, lg: 6, xl: 6, xxl: 6, span: 8 },
        right: { xs: 24, sm: 24, md: 18, lg: 18, xl: 18, xxl: 18, span: 16 }
    };

    return (
        <Row className={className} type="flex" gutter={ 24 } justify="center">
            <Col { ...spanList.left }>
                { leftColumn }
            </Col>
            <Col { ...spanList.right }>
                { rightColumn }
            </Col>
        </Row>
    );
};

export default TwoColWrap;