import React, { useEffect, useState } from "react";
import { Button, Input } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import DamageButtons from "../buttons";
import { Localize } from "components/service";
import { Icons } from "components/layout";


const DamagePhotoDescriptionBlock = ({ fileId, description }) => {

    const [ descriptionText, setDescriptionText ] = useState( description ),
          [ showTextarea, setShowTextarea ] = useState(false);

    useEffect( () => {

        setShowTextarea(false);
        setDescriptionText( description );

    }, [ description, fileId ]);

    return(
        <div className="photo-description-wrap">
            <div className="text">
                { !showTextarea && descriptionText.length === 0 ?
                    <Button
                        type="link"
                        onClick={ () => setShowTextarea(true) }
                    >
                        <PlusOutlined />
                        <Localize>DAMAGE.Button_Text_AddNote</Localize>
                    </Button> :
                    <>
                        { !showTextarea ?
                            descriptionText :
                            <Input.TextArea
                                value={ descriptionText }
                                onChange={ (e) => setDescriptionText(e.target.value) }
                            />
                        }
                    </>
                }
            </div>
            { ( description.length > 0 || showTextarea ) &&
                <div className="action">
                    { showTextarea ?
                        <DamageButtons.UpdateDescription
                            id          = { fileId }
                            description = { descriptionText }
                            disabled    = { description === descriptionText || descriptionText === "" }
                        /> :
                        <Button
                            className = "round icon"
                            type      = "ghost"
                            onClick   = { () => setShowTextarea(true) }
                        >
                            <Icons.Edit />
                        </Button>
                    }
                </div>
            }
        </div>
    );
};

export default DamagePhotoDescriptionBlock;