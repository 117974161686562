import React from "react";
import { Button } from "antd";

import DamageBlocks from "../../blocks";
import Craft from "components/craft";
import { Localize, ModalStandard } from "components/service";
import { Icons } from "components/layout";


const DamagePhotoModal = ({ buttonType, ...props }) => {

    const { stand } = props;

    const ModalButton = (props) => {

        if(buttonType === "edit"){
            return (
                <button {...props}>
                    <Craft.Grids.Single
                        extraClass = { "in_progress" }
                        type = { "damage" }
                    />
                    <Localize>
                        { stand.damageReport !== null && Number(stand.damageReport.total_files) > 0 ?
                            "DAMAGE.Modal_Button_EditReport" : "DAMAGE.Modal_Button_CreateReport"
                        }
                    </Localize>
                </button>
            )
        }

        return(
            <Button type="ghost" className="dark" {...props}>
                <Icons.Photo />
                <Localize>DAMAGE.Button_Text_Open</Localize>
            </Button>

        )
    }

    return(
        <div className = "damage-photo-modal-wrap">
            <ModalStandard
                extraClass="dark full damage-photo-modal"
                title={ <Localize exhibitor={ stand.exhibitor }>DAMAGE.Modal_Title_Photo</Localize> }
                modalButton={ <ModalButton /> }
                centered={ false }
            >
                <DamageBlocks.Report
                    stand    = { stand }
                    reportId = { stand.damageReport !== null ? stand.damageReport.id : undefined }
                />
            </ModalStandard>
        </div>
    );
}

export default DamagePhotoModal;