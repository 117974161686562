import React, { useState } from "react";

import { Loader } from "components/request-result";


const ImageLoader = ({
        image,
        wrapClass,
        imgClass = "",
        altText,
        children
    }) => {

    const [ imageLoading, setImageLoading ] = useState(true);

    return(
        <>
        { image !== "" &&
            <div className={`${wrapClass} loader-image  ${ imageLoading ? "loading" : ""}`}>
                <img
                    src = { image }
                    alt = { altText }
                    className = { imgClass }
                    onLoad={ () => setImageLoading(false) }
                />
                { imageLoading &&
                    <Loader spinClass="spin-bg" />
                }
                { children }
            </div>
        }
        </>
    );
};


export default ImageLoader;