import React from "react";

import MainNavMenu from "../main-nav-menu";
import { Wraps } from "components/layout";

import { useVarParam } from "components/use-hooks";

import "./bottom-menu-bar.scss";


const BottomMenuBar = () => {

    if(!useVarParam().get( "bottomBar" )){
       return null;
    }

    return (
        <div className="bottom-menu-bar">
            <Wraps.Container>
                <MainNavMenu />
            </Wraps.Container>
        </div>
    );
};


export default BottomMenuBar;