import React from "react";

import FilterTag from "./filter-tag";


const FilterParamsList = ({ filter, fieldsArr = ["where"] }) => {

    let { data, set } = filter;


    const tagArr = [];

    fieldsArr.forEach( (filterGroup) => {

        for (let [key, item] of Object.entries(data[filterGroup])) {
            if(item.label){
                tagArr.push(
                    <FilterTag
                        key={ key }
                        action={ () => {
                            set({
                                ...data,
                                [filterGroup] : {
                                    ...data[filterGroup],
                                    [key] : {},
                                },
                                activeFilters: data.activeFilters - 1
                            })
                        } }
                    >
                        { item.label }
                    </FilterTag>
                )
            }
        }

    })

    if(!tagArr.length){
        return null;
    }

    return(
        <div className="filter-params-list">
            { tagArr }
        </div>
    );
}

export default FilterParamsList;