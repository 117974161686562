import React from "react";
import { useQuery } from "@apollo/client";
import { Select } from "antd";

import { GET_HALLS } from "graphql/query/hall-gql";

import { Icons } from "components/layout";
import { useMe } from "components/use-hooks";
import {Localize} from "../../../service";


const { Option } = Select;

const HallSelectField = ({ ...props }) => {

    const { me } = useMe();
    const { data, loading } = useQuery(GET_HALLS, {
        variables: {
            ee_id : me.eeCurrentId
        },
        fetchPolicy: 'cache-first'
    });

    const {
        halls : {
            data : hallList = []
        } = {}
    } = data ? data : {};


    return(
        <Select
            className={ `hall-select-field` }
            suffixIcon={
                <Icons.Arrow type="filled"/>
            }
            loading={ loading }
            defaultValue={ null }
            { ...props }
        >
            <Option value={ null }>
                <Localize>FORMS.Select_DefaultValue_All</Localize>
            </Option>
            { hallList.map(
                ( { id, title } ) => <Option key={ id } value={ `${id}:${title}` }>{ title }</Option>
            )}
        </Select>
    );
}

export default HallSelectField;