import React from "react";
import { Link, useLocation } from "react-router-dom";

import StandModals from "../../modals";
import Craft from "components/craft";
import { UserHelpers } from "components/user";
import { Icons } from "components/layout";
import { PhotoModalStandard } from "components/service";
import { useVarParam } from "components/use-hooks";

import Localize, { localizeIt } from "components/service/localize";


const { permission } = UserHelpers;
const { Helpers : { Types } } = Craft;

const StandGridItem = React.memo(({
        moment,
        //me,
        uniqueCraftCount,
        ...stand
    }) => {

    const {
        id,
        exhibitor,
        hall_name : hallName,
        block,
        stand_number : standNum,
        corridor,
        delivery_time_at: deliveryAt,
        crafts,
        file_stand_plan,
        damageReport,
        material : {
            file_stand_plan : standPlanImg
        },
        created_at,
        itsNew = moment().diff(created_at, "days", true) < 1
    } = stand;

    const location = useLocation(),
          varParams = useVarParam();

    const activeCrafts = Types.active({
        crafts,
        stand : {
            id,
            damage: damageReport ?
                damageReport : {
                "id" : "new",
                "status" : "delete",
                "total_files" : 0
            }
        },
        varParams
    });

    const standClass = [];

    itsNew && standClass.push("new-badge");
    activeCrafts.length === 1 && standClass.push("one-craft");
    uniqueCraftCount > 10 && standClass.push("three-row");

    return(
        <div
            className={ `stand-grid-item ${ standClass.join(" ") }` }
            data-badgetext={ itsNew ? localizeIt("GLOBAL.Badge_Text_New") : "" }
        >
            <div className="stand-grid-item-hero grid-item-hero">
                <h3>{ exhibitor }</h3>
                <ul className="info grid-four-col">
                    <li>
                        <Localize wrap="h5">STAND.Card_Hero_Hall</Localize>
                        <p>{ hallName }</p>
                    </li>
                    <li>
                        <Localize wrap="h5">STAND.Card_Hero_Block</Localize>
                        <p>{ block ? block : "—" }</p>
                    </li>
                    <li>
                        <Localize wrap="h5">STAND.Card_Hero_Stand</Localize>
                        <p>
                            { (corridor || standNum) ?
                                `${ corridor ? corridor : "" }${ standNum }` : "—"
                            }
                        </p>
                    </li>
                    <li>
                        <Localize wrap="h5">STAND.Card_Hero_DeliveryTime</Localize>
                        <p>{  moment(deliveryAt).format("DD. MMM HH:mm")  }</p>
                    </li>
                </ul>
            </div>
            <div className={ `stand-grid-item-body ${ activeCrafts.length === 1 ? "grid-two-col" : "" }` } >
                {
                    crafts.length >= 1 &&
                        <Craft.Grids.All
                            crafts = { activeCrafts }
                            stand  = {{
                                title: exhibitor,
                                ...stand
                            }}
                        />
                }
                <div className="actions-wrap">
                    <div className={ `group ${ activeCrafts.length !== 1 ? "grid-two-col" : "" }` }>
                        { permission().has({value: "upload_photo"}) &&
                            <StandModals.Photo
                                stand={stand}
                            />
                        }
                        <PhotoModalStandard
                            modalProps = {{
                                title     : <Localize>STAND.Modal_Title_StandPlan</Localize>,
                            }}
                            buttonProps = {{
                                text : <Localize wrap>STAND.Modal_Button_StandPlan</Localize>,
                                icon : "Plan",
                                type : "default"
                            }}
                            photo = { standPlanImg }
                            file  = { file_stand_plan }
                            title = { exhibitor }
                        />

                    </div>
                    <Link
                        className="link-button-ghost"
                        to={ () => {
                            return {
                                pathname: `/stands/${ id }`,
                                state: {
                                    from : location.pathname,
                                    returnPagePosition : window.scrollY
                                }
                            }
                        }}
                    >
                        <Localize icon={ <Icons.Arrow /> }>
                            STAND.Card_Button_Details
                        </Localize>
                    </Link>
                </div>
            </div>
        </div>
    );
});

export default StandGridItem;