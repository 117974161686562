import React from "react";
import { Switch, Route } from 'react-router-dom';

import { Pages } from "components/pages";
import { UserHelpers } from "components/user";


const { permission } = UserHelpers;
const { Stands } = Pages;

const StandRoutes = () => {

    return(
        <Switch>
            <Route path="/stands/" exact>
                <Stands.Collection />
            </Route>
            <Route path="/stands/:standId" exact>
                { ( { match }) =>
                    <Stands.Single standId={ +match.params.standId }  />
                }
            </Route>
            <Route path="/stands/:standId/keys" exact>
                { ( { match }) =>
                    <Stands.Keys standId={ +match.params.standId }  />
                }
            </Route>
            { permission().has({ type : "log" }) &&
                <Route path="/stands/:standId/log" exact>
                    { ( { match }) =>
                        <Stands.Log standId={ +match.params.standId }  />
                    }
                </Route>
            }
            <Route path="*" >
                <Pages.Page404 />
            </Route>
        </Switch>
    );
};

export default StandRoutes;