import { gql } from "@apollo/client";


export const CRAFT_CHANGE_STATUS = gql`
    mutation CraftUpdateStatus(
        $id     : ID!
        $status : CraftStatusField!
    ) {
        craftUpdateStatus(
            id: $id
            status: $status
        ) {
            label
            message
            craft{
                id
                title
                status
            }
        }
    }
`;