import React from 'react';
import Icon from '@ant-design/icons';


const JobsSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M32.3,19l-9.5,9.5l-5.3-5.3c-0.5-0.5-1.3-0.5-1.8,0s-0.5,1.3,0,1.8l6.2,6.2c0.2,0.2,0.6,0.4,0.9,0.4s0.6-0.1,0.9-0.4
		    l10.4-10.4c0.5-0.5,0.5-1.3,0-1.8C33.6,18.5,32.8,18.5,32.3,19z"
        />
        <path d="M37.6,8.1h-4.8V7.4c0-0.7-0.6-1.2-1.3-1.2h-2.8C28.2,4.3,26.4,3,24.4,3S20.5,4.3,20,6.2h-2.8c-0.7,0-1.2,0.6-1.2,1.2v0.7
		    h-4.8c-0.7,0-1.2,0.6-1.2,1.2v32.2c0,0.7,0.6,1.2,1.2,1.2h26.5c0.7,0,1.2-0.6,1.2-1.2V9.4C38.8,8.7,38.3,8.1,37.6,8.1z M18.4,8.7
		    H21c0.7,0,1.2-0.5,1.2-1.2c0.1-1.1,1-2,2.1-2c1.1,0,2.1,0.9,2.1,2c0,0.7,0.6,1.2,1.2,1.2h2.6v3.2H18.4V8.7z M36.3,40.3h-24V10.6
		    h3.5v2.5c0,0.7,0.6,1.2,1.2,1.2h14.4c0.7,0,1.3-0.6,1.3-1.2v-2.5h3.5V40.3z"
        />
    </svg>
);

const JobsIcon = props => {
    return <Icon component={ JobsSvg } className={ props.className } />;

};


export default JobsIcon;