import React from "react";
import { Link, useLocation } from "react-router-dom";

import { Icons } from "components/layout";
import { Localize, PhotoModalStandard } from "components/service";
import StandModals from "../modals";
import { UserHelpers } from "components/user";


const { permission } = UserHelpers;


const StandMaterialsBlock = ({
        exhibitor,
        file_stand_plan,
        file_info,
        file_graphic,
        material: {
            file_stand_plan : standPlanImg,
            file_info       : infoImg,
            file_graphic    : graphicImg,
        },
        hall,
        ...stand
    }) => {

    const location = useLocation();

    return(
        <div className="block-wrap stand-materials-block">
            <Localize wrap="h4" wrapClass={ "text-label" }>STAND.Block_Title_Materials</Localize>
            <div className="holder bg-light-bordered">
                <ul className="row grid-two-col">
                    <li>
                        <PhotoModalStandard
                            modalProps = {{
                                title     : <Localize>STAND.Modal_Title_StandPlan</Localize>,
                            }}
                            buttonProps = {{
                                text : <Localize wrap>STAND.Modal_Button_StandPlan</Localize>,
                                icon : "Plan",
                                type : "default"
                            }}
                            file = { file_stand_plan }
                            photo = { standPlanImg }
                            title = { exhibitor }
                        />
                    </li>
                    <li>
                        <Link
                            className = { `link-button-default ${ !hall.hall_plan ? "disabled" : "" }` }
                            to = {{
                                pathname: !hall.hall_plan ? `${ location.pathname }` : `/more/expo-plan/${ hall.id }`,
                                state: {
                                    from : location.pathname
                                }
                            }}
                        >
                            <Icons.Photo />
                            <Localize wrap>HALL.Button_Text_HallPlan</Localize>
                        </Link>
                    </li>
                    <li>
                        <PhotoModalStandard
                            modalProps = {{
                                title     : <Localize>STAND.Modal_Title_StandGraphic</Localize>,
                            }}
                            buttonProps = {{
                                text : <Localize wrap>STAND.Modal_Button_StandGraphic</Localize>,
                                icon : "Picture",
                                type : "default"
                            }}
                            file = { file_graphic }
                            photo = { graphicImg }
                            title = { exhibitor }
                        />
                    </li>
                    <li >
                        <PhotoModalStandard
                            modalProps = {{
                                title     : <Localize>STAND.Modal_Title_ExtraInfo</Localize>,
                            }}
                            buttonProps = {{
                                text : <Localize wrap>STAND.Modal_Button_ExtraInfo</Localize>,
                                icon : "Info",
                                type : "default"
                            }}
                            file = { file_info }
                            photo = { infoImg }
                            title = { exhibitor }
                        />
                    </li>
                    <li className="grid-two-col-to-one">
                        { permission().has({value: "upload_photo"}) &&
                            <StandModals.Photo
                                stand={ stand }
                            />
                        }
                    </li>
                </ul>
            </div>
        </div>
    );

}

export default StandMaterialsBlock;