import React from "react";

import Job from "components/job";
import Localize, { localizeIt } from "components/service/localize";


const hallOverviewCountTypesHelper = [
    {
        label  : <Localize>OVERVIEW_TYPES.Label_InTotal</Localize>,
        type   : "total",
        route  : "/stands/",
        getFilter : ({ hallName, hallId }) => {

            return {
                filter : {
                    where: {
                        hallId : {
                            label    : hallName,
                            column   : "HALL_ID",
                            operator : "EQ",
                            value    : hallId,
                        }
                    },
                    activeFilters : 1
                }
            }
        }
    },
    {
        label  : <Localize>OVERVIEW_TYPES.Label_Finished</Localize>,
        type   : "finished",
        route  : "/stands/",
        getColor : (count) => count > 0 ? "color-success" : "color-disabled",
        getFilter : ({ hallName, hallId }) => {

            return {
                filter : {
                    where: {
                        hallId : {
                            label    : hallName,
                            column   : "HALL_ID",
                            operator : "EQ",
                            value    : hallId,
                        }
                    },
                    whereCrafts: {
                        craftStatus: {
                            label: localizeIt("CRAFT.Status_Text_Completed"),
                            column: "STATUS",
                            operator: "EQ",
                            value: "completed"
                        },
                    },
                    activeFilters : 2
                }
            }
        }
    },
    {
        label  : <Localize>OVERVIEW_TYPES.Label_Open</Localize>,
        type   : "open",
        route  : "/stands/",
        getColor : (count) => count > 0 ? "color-warning" : "color-disabled",
        getFilter : ({ hallName, hallId }) => {

            return {
                filter : {
                    where: {
                        hallId : {
                            label    : hallName,
                            column   : "HALL_ID",
                            operator : "EQ",
                            value    : hallId,
                        }
                    },
                    whereCrafts: {
                        craftStatus: {
                            label: localizeIt("CRAFT.Status_Text_InProgress"),
                            column: "STATUS",
                            operator: "EQ",
                            value: "in_progress"
                        },
                    },
                    activeFilters : 2
                }
            }
        }
    },
    {
        label  : <Localize>OVERVIEW_TYPES.Label_Claims</Localize>,
        type   : "claims",
        route  :  "/jobs/",
        getColor  : (count) => count > 0 ? "color-warning" : "color-success",
        getFilter : ({ hallName, hallId }) => {

            return {
                filter : {
                    where: {
                        status : {
                            label    :
                                Job.Helpers.Status.arrToLabelStr(
                                    { jobStatuses : ["waiting"]}
                                ),
                            column   : "STATUS",
                            operator : "IN",
                            value    : ["waiting"],
                        },
                        type : {
                            label    :
                                Job.Helpers.NameByType.localized({ jobTypes: ["all_claims"] }),
                            column   : "TYPE",
                            operator : "IN",
                            value    : ["claims"],
                        }
                    },
                    whereCraft : {
                        hallId : {
                            label    : hallName,
                            column   : "HALL_ID",
                            operator : "EQ",
                            value    : hallId,
                        }
                    },
                    activeFilters : 3
                }
            }
        }
    },
    {
        label  : <Localize>OVERVIEW_TYPES.Label_LastMinute</Localize>,
        type   : "last_min",
        route  :  "/jobs/",
        getColor  : (count) => count > 0 ? "color-warning" : "color-success",
        getFilter : ({ hallName, hallId }) => {

            return {
                filter : {
                    where: {
                        status : {
                            label    :
                                Job.Helpers.Status.arrToLabelStr(
                                    { jobStatuses : ["waiting"]}
                                ),
                            column   : "STATUS",
                            operator : "IN",
                            value    : ["waiting"],
                        },
                        type : {
                            label    :
                                Job.Helpers.NameByType.localized({ jobTypes: ["last_minutes"] }),
                            column   : "TYPE",
                            operator : "IN",
                            value    : ["last_minutes"],
                        }
                    },
                    whereCraft : {
                        hallId : {
                            label    : hallName,
                            column   : "HALL_ID",
                            operator : "EQ",
                            value    : hallId,
                        }
                    },
                    activeFilters : 3
                }
            }
        }
    },
    {
        label  : <Localize>OVERVIEW_TYPES.Label_Overdue</Localize>,
        type   : "overdue",
        route  : "/stands/",
        getColor : (count) => count > 0 ? "color-error" : "color-success",
        getFilter : ({ hallName, hallId }) => {

            return {
                filter : {
                    where: {
                        hallId : {
                            label    : hallName,
                            column   : "HALL_ID",
                            operator : "EQ",
                            value    : hallId,
                        }
                    },
                    whereCrafts: {
                        craftStatus: {
                            label: localizeIt("CRAFT.Status_Text_Overdue"),
                            column: "STATUS",
                            operator: "EQ",
                            value: "overdue"
                        },
                    },
                    activeFilters : 2
                }
            }
        }
    },
];

export default hallOverviewCountTypesHelper;