import { useQuery } from "@apollo/client";

import { GET_ME } from "graphql/query/user-gql";


const useMe = ( fetchPolicy = "cache-only", withStatus = false ) => {

    const { data : { me } = { }, loading, error } = useQuery( GET_ME, { fetchPolicy } );

    if(withStatus) return { me, loading, error };

    if(me) return { me } ;

    return false;
}

export default useMe;