import StandKeysList from "./stand-keys-list";
import StandFilterParamsList from "./stand-filter-params-list";

import "./stand-lists.scss";


const StandLists = {
    Keys         : StandKeysList,
    FilterParams : StandFilterParamsList
}

export default StandLists;
