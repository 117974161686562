import { saveAs } from "file-saver";


const saveAsImage = (url, fileName, rotation = 0) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const a  = document.createElement('a');

    let base_image = new Image();
    base_image.src = url;
    base_image.crossOrigin="anonymous";

    const ext = url.match(/\.([^.]+)$|$/)[1];

    base_image.onload = function() {

        const { width: imgW, height : imgH} = base_image;

        if(rotation%2){
            canvas.width = imgH;
            canvas.height = imgW;
        } else {
            canvas.width = imgW;
            canvas.height = imgH;
        }

        if(rotation){

            ctx.translate(canvas.width / 2,canvas.height / 2);
            ctx.rotate((rotation*90) * Math.PI/180);
            ctx.drawImage(base_image, -imgW / 2, -imgH / 2);

        } else {

            ctx.rotate((rotation*90) * Math.PI/180);
            ctx.drawImage(base_image, 0, 0);
        }

        a.href = canvas.toDataURL(`image/${ ext }`);
        a.download = `${ fileName }.${ ext }`;

        a.click();

        canvas.remove();
        a.remove();

    }
}

const saveDocument = (url, filename) => {

    const name = filename ? filename : url.substring(url.lastIndexOf('/') + 1);

    saveAs(url, name);

}

const saveAsFile = {
    document: saveDocument,
    image : saveAsImage
}


export default saveAsFile;