import React from 'react';
import Icon from '@ant-design/icons';


const WarningSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M44.8,36.6L27.4,6.5c-0.7-1.2-1.9-1.9-3.3-1.9h0c-1.4,0-2.6,0.7-3.3,1.9L3.4,36.6c-0.7,1.2-0.7,2.6,0,3.9
		c0.7,1.2,1.9,1.9,3.3,1.9h34.8c1.4,0,2.6-0.7,3.3-1.9C45.5,39.3,45.5,37.8,44.8,36.6z M7,38.4L24.1,8.7l17.1,29.7H7z"/>
        <path d="M21.9,17.7v9.8c0,1.1,0.9,2,2,2s2-0.9,2-2v-9.8c0-1.1-0.9-2-2-2S21.9,16.6,21.9,17.7z"/>
        <path d="M23.9,31.3c-1.1,0-2,0.9-2,2s0.9,2,2,2c1.1,0,2-0.9,2-2S25,31.3,23.9,31.3z"/>
    </svg>
);

const WarningIcon = props => {
    return <Icon component={ WarningSvg } className={ props.className } />;

};


export default WarningIcon;