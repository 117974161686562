import HallPlanBlock from "./hall-plan-block";
import HallFilterBlock from "./hall-filter-block";

import "./hall-blocks.scss";


const HallBlocks = {
    Plan   : HallPlanBlock,
    Filter : HallFilterBlock
};

export default HallBlocks;