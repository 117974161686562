import StandModals from "./modals";
import StandGrids from "./grids";
import StandBlocks from "./blocks";
import StandForms from "./forms";
import StandLists from "./lists";
import StandFields from "./fields";
import StandHelpers from "./helpers";
import StandHooks from "./hooks";


const Stand = {
    Blocks  : StandBlocks,
    Grids   : StandGrids,
    Modals  : StandModals,
    Forms   : StandForms,
    Lists   : StandLists,
    Fields  : StandFields,
    Helpers : StandHelpers,
    Hooks   : StandHooks
}

export default Stand;