import React from 'react';
import Icon from '@ant-design/icons';

import { Loader } from "components/request-result";


const ArrowSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M16.3,46.7c-0.5,0-1-0.2-1.4-0.5c-0.8-0.8-0.8-2-0.1-2.8l18.4-19.4L14.8,4.5c-0.8-0.8-0.7-2.1,0.1-2.8C15.7,1,17,1,17.7,1.8
	l19.7,20.8c0.7,0.8,0.7,2,0,2.8L17.7,46.1C17.3,46.5,16.8,46.7,16.3,46.7z"/>
    </svg>
);

const ArrowFullSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M7.6,22.9h29.2L22.9,9.3c-0.6-0.6-0.6-1.5,0-2.1c0.6-0.6,1.5-0.6,2.1,0l16.5,16.2c0.3,0.3,0.5,0.7,0.5,1.1s-0.2,0.8-0.5,1.1
	        L25,41.6c-0.3,0.3-0.7,0.4-1,0.4c-0.4,0-0.8-0.2-1.1-0.5c-0.6-0.6-0.6-1.5,0-2.1l13.9-13.6H7.6c-0.8,0-1.5-0.7-1.5-1.5
	        S6.7,22.9,7.6,22.9z"
        />
    </svg>
);



const ArrowIcon = props => {

    if(props.loading){
        return <Loader />;
    }

    if(props.type === "full"){
        return <Icon className={ props.className } component={ ArrowFullSvg } />;
    }

    return <Icon className={ props.className } component={ ArrowSvg } />;

};


export default ArrowIcon;