import React, { useState }  from "react";
import { useMutation } from "@apollo/client";
import { Button } from "antd";

import { JOB_CHANGE_FLAG } from "graphql/mutation/job-gql";

import { UserHelpers } from "components/user";
import { useNotification } from "components/use-hooks";
import { Icons } from "components/layout";


const { permission } = UserHelpers;

const JobFlagBtn = ({ id, flag = 0 }) => {

    const [ jobChangeFlag, { loading } ] = useMutation( JOB_CHANGE_FLAG );

    const { nError } = useNotification(),
          [ currentFlag, setCurrentFlag ] = useState(flag);

    return(
        <div className="job-change-flag-button-wrap">
            <Button
                disabled  = { !permission().has({ value: "change_job_flag" })  }
                className = { `job-change-flag-button ${ currentFlag === 1 ? "active" : "" }` }
                onClick = {
                    () => {
                        if(!loading){
                            jobChangeFlag({
                                variables: {
                                    id,
                                    flag: currentFlag === 1 ? 0 : 1
                                }
                            }).then(
                                () => setCurrentFlag( currentFlag === 1 ? 0 : 1 )
                            ).catch((error) => nError( error ))
                        }
                    }
                }
            >
                <Icons.Flag />
            </Button>
        </div>
    );
}


export default JobFlagBtn;