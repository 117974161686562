import React from 'react';
import Icon from '@ant-design/icons';


const PlanSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M40.6,6.7H7.7C7,6.7,6.4,7.3,6.4,8v32.9c0,0.7,0.6,1.2,1.2,1.2h16.8c0.3,0,0.6-0.1,0.8-0.3l7.1-5.7c0.5-0.4,0.6-1.2,0.2-1.8
	        c-0.4-0.5-1.2-0.6-1.8-0.2L24,39.6H8.9V25.9h14.4v3.6c0,0.7,0.6,1.2,1.2,1.2s1.2-0.6,1.2-1.2v-9.6c0-0.7-0.6-1.2-1.2-1.2
	        s-1.2,0.6-1.2,1.2v3.5H8.9V9.2h14.4v3c0,0.7,0.6,1.2,1.2,1.2s1.2-0.6,1.2-1.2v-3h13.5v14.1h-5.2c-0.7,0-1.2,0.6-1.2,1.2
	        s0.6,1.2,1.2,1.2h5.2v13.8h-5.3c-0.7,0-1.2,0.6-1.2,1.2s0.6,1.2,1.2,1.2h6.5c0.7,0,1.2-0.6,1.2-1.2V8C41.8,7.3,41.3,6.7,40.6,6.7z"
        />
    </svg>
);

const PlanIcon = props => {
    return <Icon component={ PlanSvg } className={ props.className } />;

};


export default PlanIcon;