import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { Segmented } from "antd";

import { DAMAGE_REPORT_UPDATE } from "graphql/mutation/damages-gql";

import { Localize } from "components/service";
import { Icons } from "components/layout";
import { useNotification } from "components/use-hooks";


const DamageStatusSegmentedBtn = ({ id, status, disabled }) => {

    const { nError, nSuccess } = useNotification();

    const [ damageStatus, setDamageStatus ] = useState(status || "delete");

    const [ damageReportUpdate, { loading }] = useMutation( DAMAGE_REPORT_UPDATE,
        {
            update(cache, { data }) {

                const {
                    damageReportUpdate : {
                        label,
                        message
                    }
                } = data;

                nSuccess( label, message );
            },
            onError(error) {
                nError(error);
            },
        }
    );

    return(
        <div className="damage-status-segmented-wrap">
            <Segmented
                className = { damageStatus }
                block
                disabled  = { disabled }
                value     = { damageStatus }
                options   = {[
                    {
                        label: <Localize>DAMAGE.Status_Label_New</Localize>,
                        value: 'new',
                        icon: <Icons.Question />
                    },
                    {
                        label: <Localize>DAMAGE.Status_Label_Check</Localize>,
                        value: 'check',
                        icon: <Icons.Check />
                    },
                    {
                        label: <Localize>DAMAGE.Status_Label_DCheck</Localize>,
                        value: 'double_check',
                        icon: <Icons.Check type="double" />
                    },
                ]}
                onChange={ (value) => {
                    if( !loading ){

                        damageReportUpdate({
                            variables : {
                                input: {
                                    id,
                                    status : value
                                }
                            }
                        }).then( () => {
                            setDamageStatus(value);
                        }).catch( nError )
                    }
                }}
            />
        </div>
    )
};

export default DamageStatusSegmentedBtn;