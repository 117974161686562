import { gql } from '@apollo/client';


export const GET_EE_STATISTIC = gql`
    query eeStatistic(
        $id: ID!
    ) {
        eeStatistic(
            id: $id
        ) {
            id
            statistic
        }
    }
`;

export const GET_EE_NEW_STAND_JOBS = gql`
    query NewStandJobsCount(
        $eeId: ID!
    ) {
        countNewStandsJobs(
            ee_id: $eeId
        ) {
            stands
            jobs
        }
    }
`;

export const GET_DOCUMENTS = gql`
    query GetDocuments(
        $eeId  : ID
        $first  : Int
        $after  : String
    ) {
        attachmentsCursor(
            ee_id   : $eeId
            first   : $first
            after   : $after
            
        ){
            pageInfo{
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
                total
                currentPage
                lastPage
            }
            edges {
                cursor
                node {
                    id
                    title
                    description
                    original_name
                    path
                    size
                    extension
                    created_at
                }
            }
        }
    }
`;