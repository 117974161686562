import React from "react";
import { useQuery } from "@apollo/client";

import { GET_FURNISHING_ORDERS } from "graphql/query/furnishing-gql";

import FurnishingLists from "../lists";
import { Loader, NoData } from "components/request-result";
import { whereConditionBuilder } from "utils";
import { Localize } from "components/service";



const FurnishingTabRefund = ({ eeId, perPage = 50, beforeLoad = () => {}, ...props}) => {

    beforeLoad();

    const { data, loading, fetchMore } = useQuery(GET_FURNISHING_ORDERS, {
        variables: {
            where: whereConditionBuilder({
                eeId : {
                    column: "EE_ID",
                    operator : "EQ",
                    value : eeId
                },
                type : {
                    column: "TYPE",
                    operator : "IN",
                    value : ["refund", "correction"]
                },
            }),
            orderBy: [{ column: "ID", order : "DESC" }],
            first: perPage,
        },
        fetchPolicy: "cache-and-network",
        notifyOnNetworkStatusChange: true
    });


    const {
        furnishingsOrdersCursor : {
            edges: furnishingRefunds = [],
            pageInfo = {}
        } = {}
    } = data ? data : {};

    return(
        <div className="furnishing-tab storage">
            { !furnishingRefunds.length && !loading &&
                <NoData
                    text={{
                        after :  <Localize br={ <br /> }>NO_DATA.Furnishing_Orders</Localize>
                    }}
                />
            }
            {!!furnishingRefunds.length &&
                <FurnishingLists.Custom
                    data={{
                        furnishings: furnishingRefunds.map(
                            ({ node: { furnishing: {title, sku, image}, ...order}}) => {
                                return {node: {...order, title, sku, image}};
                            }
                        ),
                        pageInfo,
                        fetchMore,
                        perPage,
                        model: "furnishingsOrdersCursor"
                    }}
                    itemClassName = "bg-light-bordered"
                    withCreatorData
                    withType
                    moment = { props.moment }
                />
            }
            { loading && <Loader type="block" /> }
        </div>
    );
}

export default FurnishingTabRefund;