import React from "react";

import { FilterTag } from "components/service";


const CraftOverviewFilterParamsList = ({ filter }) => {

    let { data, set } = filter;

    const tagArr = [];

    if(data.hallId.value){
        tagArr.push(
            <FilterTag
                key={ data.hallId.value }
                action={ () => {

                    set({ ...{...data, hallId : {} }, activeFilters: data.activeFilters - 1 })
                } }
            >
                { data.hallId.label }
            </FilterTag>
        )
    }

    if(!tagArr.length){
        return null;
    }

    return(
        <div className="filter-params-list">
            { tagArr }
        </div>
    );
}

export default CraftOverviewFilterParamsList;