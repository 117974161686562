import React from "react";

import { useVarParam } from "components/use-hooks";
import { Wraps } from "components/layout";
import { localizeIt } from "components/service/localize";
import {Localize} from "../../service";


const HelpImprintPage = () => {

    const appParams = useVarParam();

    appParams.set({
        headerTitle: localizeIt("PAGE.Title_HelpImprint"),
        bottomBar: true
    });

    return (
        <Wraps.Container className="page help-imprint-page">
            <p>&nbsp;</p>
            <p><b>Dienstanbieter 5 TMG:</b></p>
            <p>Messeprojekt GmbH</p>
            <p>Bergweg 7</p>
            <p>04356 Leipzig</p>
            <p>&nbsp;</p>
            <p>Amtsgericht Leipzig</p>
            <p>HRB 4669</p>
            <p>&nbsp;</p>
            <p><b>Represented by:</b></p>
            <p>Dipl.-Betriebsw. (BA) Raimo Bunsen</p>
            <p>&nbsp;</p>
            <p>
                <b>Tel:</b>
                <a href="tel:+4934152625820">+49 (0)341-526 258 20</a>
            </p>
            <p>
                <b>Fax:</b>
                <a href="tel:+4934152625821">+49(0)341-526 258 21</a>
            </p>
            <p>
                <b>E-Mail:</b>
                <a href="mailto:info@messeprojekt.de">info@messeprojekt.de</a>
            </p>
            <p>
                <b>Web:</b>
                <a href="https://messeprojekt.de/">messeprojekt.de</a>
            </p>
            <p>&nbsp;</p>
            <p><b>Idea & Concept:</b> Raimo Bunsen</p>
            <p><b>Technical management:</b> Robby Palmer</p>
            <p><b>Programming:</b> Andrii Kalachnikov, Aleksandr Yakovenko, Alexandr Dudka</p>
            <p><b>Design:</b> Iryna Taranova</p>
            <p><b>QA and translation:</b> Martin Hoffmann, Marina Schnabel</p>
            <p>&nbsp;</p>
            <Localize
                wrap="p"
                versionNumber={ appParams.get("appVersion") }
            >
                GLOBAL.Text_Version
            </Localize>
        </Wraps.Container>
    );
}

export default HelpImprintPage;