import React from "react";

import FurnishingLists from "../lists";
import FurnishingModals from "../modals";


const FurnishingTabStorage = ({ eeId, perPage = 50, beforeLoad = () => {} }) => {

    beforeLoad();

    return(
        <div className="furnishing-tab storage">
            <FurnishingLists.Simple
                eeId    = { eeId }
                perPage = { perPage }
                itemClassName   = "bg-light-bordered"
                ActionComponent = { <FurnishingModals.Refund eeId={ eeId } /> }

            />
        </div>
    );
}

export default FurnishingTabStorage;