import React from "react";
import { useQuery } from "@apollo/client";

import { GET_STAND } from "graphql/query/stand-gql";

import Stand from "components/stand";
import Exhibition from "components/exhibition";
import { useMe, useVarParam } from "components/use-hooks";
import { Wraps } from "components/layout";
import Localize, { localizeIt } from "components/service/localize";
import { Loader, NoData } from "components/request-result";
import { checkUser } from "utils";


const { Helpers : { EE } } = Exhibition;

const StandPage = ({ standId }) => {

    const { data, loading } = useQuery(GET_STAND, {
        variables: {
            id: standId
        },
        fetchPolicy: 'cache-and-network',
    });

    const appParams = useVarParam(),
          { me } = useMe(),
          { isEventPermitted } = checkUser;

    appParams.set({
        headerTitle: localizeIt("PAGE.Title_Stand"),
        bottomBar: true
    });

    const {
        stand = {}
    } = data ? data : {};

    const standPermitted = isEventPermitted(me, stand?.ee_id);

    return (
        <Wraps.Container className="page stand-page">
            { data && stand && standPermitted &&
                <>
                    <h3>{ stand.exhibitor }</h3>
                    <Stand.Blocks.Overview
                        { ...stand }
                    />
                    <Stand.Blocks.Materials
                        { ...stand }
                    />
                    <Stand.Blocks.KeyLogs
                        standId={ stand.id  }
                    />
                    { EE.damageOldOrNew(appParams) === "new" &&
                        <Stand.Blocks.Damages
                            stand={stand}
                            damageReport={!!stand.damageReport ? stand.damageReport : {}}
                        />
                    }
                    <Stand.Blocks.Crafts
                        crafts = { stand.crafts }
                        stand  = { stand }
                    />

                </>
            }
            { !loading && (!standPermitted || !stand || !data) &&
                <NoData
                    text = { {
                        before : <Localize>NO_DATA.Stand_DoesNotExist</Localize>
                    } }
                    icon = "stand"
                    className = "block"
                />
            }
            { loading && <Loader type="block" /> }
        </Wraps.Container>
    );
}

export default StandPage;