import React from "react";

import Job from "components/job";
import { Icons } from "components/layout";
import { Loader } from "components/request-result";


const Wrap = ({
        action = () => {},
        wrapped,
        children
    }) => {

    if(wrapped){
        return (
            <button onClick={ action }>
                { children }
            </button>
        );
    }

    return (
        <>{ children }</>
    );
}

const CraftGridItem = ({
        extraClass,
        type,
        title,
        loading,
        count,
        craft_jobs = [],
        hasJobType = [
            ...new Set(craft_jobs.map(item => item.type))
        ],
        ...props
    }) => {

    const comboTypes = ["claims", "customer_claims"];

    if(hasJobType.filter( item => comboTypes.indexOf(item) !== -1 ).length === 2){
        hasJobType = hasJobType.filter( item => comboTypes.indexOf(item) === -1);
        hasJobType.push("mp_and_customer_claims");
    }

    return(
        <div className={ `craft-grid-item ${ extraClass }` }>
            { !loading ?
                <Wrap { ...props }>
                    { count !== undefined &&
                        <span className="count-tag">{ count }</span>
                    }
                    { hasJobType.map(
                        ( jobType ) => {


                            return (
                                <Job.Helpers.Tag
                                    jobType = { jobType }
                                    key = { `${props.id}_${jobType}` }
                                />
                            )
                        })
                    }
                    <Icons.Craft
                        type={ /\d/.test(type) ? type.replace(/_\d/g, '') : type }
                    />
                    { title &&
                        <span className="label">{ title }</span>
                    }
                </Wrap> :
                <Loader />
            }
        </div>
    );
}

export default CraftGridItem;