import React from "react";

import Hall from "components/hall";
import { Wraps } from "components/layout";
import { useVarParam, useMe } from "components/use-hooks";
import { localizeIt } from "components/service/localize";


const HallsPage = () => {

    const { me } = useMe(),
        appParams = useVarParam();

    appParams.set({
        headerTitle: localizeIt("PAGE.Title_ExpoPlan"),
        bottomBar: true
    });


    return (
        <Wraps.Container className="page halls-page">
            <Hall.Lists.Selection
                eeId={ me.eeCurrentId }
            />
        </Wraps.Container>
    );
}

export default HallsPage;