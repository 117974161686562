import { gql } from '@apollo/client';


export const GET_STANDS = gql`
    query StandsCursor(
        $text        : String
        $where       : QueryStandsCursorWhereWhereConditions
        $whereCrafts : QueryStandsCursorWhereCraftsWhereHasConditions
        $orderBy     : [QueryStandsCursorOrderByOrderByClause!]
        $first       : Int
        $after       : String
    ) {
        standsCursor(
            text         : $text
            where        : $where
            whereCrafts  : $whereCrafts
            orderBy      : $orderBy
            first        : $first
            after        : $after
        ) {
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
                total
                currentPage
                lastPage
            }
            edges {
                cursor
                node {
                    id
                    ee_id
                    hall_id
                    hall_name
                    exhibitor
                    aquise_number
                    proauf_number
                    block
                    corridor
                    stand_number
                    delivery_time_at
                    file_stand_plan
                    file_info
                    file_graphic
                    file_photo
                    material{
                        file_stand_plan
                        file_info
                        file_graphic
                        file_photo
                        file_animation
                    }
                    crafts {
                        id
                        title
                        subcontractor_id
                        stand_id
                        type
                        status
                        subcontractor{
                            id
                            title
                        }
                        craft_jobs{
                            id
                            type
                            status
                        }
                    }
                    damageReport {
                        id
                        status
                        total_files
                    }
                    created_at
                    updated_at
                }
            }
        }
    }
`;


export const GET_STAND = gql`
    query Stand(
        $id: ID!
    ) {
        stand(
            id: $id
        ) {
            id
            ee_id
            hall_id
            hall_name
            exhibitor
            aquise_number
            proauf_number
            block
            corridor
            stand_number
            cad_number
            delivery_time_at
            file_stand_plan
            file_info
            file_graphic
            file_photo
            material{
                file_stand_plan
                file_info
                file_graphic
                file_photo
                file_animation
            }
            hall {
                id
                hall_plan
            }
            exhibitionEvent {
                id
                title
                status
            }
            keys {
                id
                title
                number
                stand_id
            }
            crafts {
                id
                title
                subcontractor_id
                stand_id
                type
                status
                complete_at
                subcontractor{
                    id
                    title
                    users {
                        id
                        email
                        phone
                        exhibitionEvent {
                            id
                            title
                            status
                        }
                    }
                }
                craft_jobs {
                    id
                    type
                    status
                }
            }
            damageReport {
                id
                status
                total_files
            }
            created_at
            updated_at
        }
    }
`;


export const GET_STAND_LOG = gql`
    query GetStandLog(
        $text        : String
        $where       : QueryLogsWhereWhereConditions
        $orderBy     : [QueryLogsOrderByOrderByClause!]
        $first       : Int
        $after       : String
    ) {
        logs(
            text         : $text
            where        : $where
            orderBy      : $orderBy
            first        : $first
            after        : $after
        ) {
            edges {
                cursor
                node {
                    id
                    ee_id
                    ee_title
                    stand_id
                    user_id
                    user_full_name
                    msg_en
                    msg_de
                    created_at
                    updated_at  
                }
            }
            
        }
    }
`;