import React from "react";

import Damage from "components/damage";
import { Localize } from "components/service";


const StandDamagesBlock = ({ stand, damageReport }) => {

    const {
        id,
        status = "new",
        total_files : count = 0,
    } = damageReport;


    return(
        <div className="block-wrap stand-damages-block">
            <Localize
                wrap="h4"
                wrapClass={ "text-label" }
            >
                STAND.Block_Title_Damages
            </Localize>
            <Damage.Blocks.Preview
                reportId = { id }
                stand    = { stand }
                count    = { count }
                status   = { Number(count) === 0 ? "double_check" : status }
            />
        </div>
    );

}

export default StandDamagesBlock;