import React, { useState } from "react";
import { Button, Form, Input, Radio } from "antd";
import { SyncOutlined } from "@ant-design/icons";

import { JOB_CREATE } from "graphql/mutation/job-gql";

import Furnishing from "components/furnishing";
import { PreUploadImage } from "components/service";
import { useMutation } from "@apollo/client";
import { useNotification } from "components/use-hooks";
import { Icons } from "components/layout";
import Localize, { localizeIt } from "components/service/localize";


const JobCreateForm = ({ craftId, type, eeId,  callback }) => {

    const { nError, nSuccess } = useNotification();

    const [ jobCreate, { loading }] = useMutation( JOB_CREATE,
        {
            update(cache, { data }) {

                const {
                    craftJobCreate : {
                        label,
                        message
                    }
                } = data;

                callback();

                nSuccess( label, message );
            }
        }
    );

    const [ contentType, setContentType ] =  useState('description');
    const [ selectedFurnishing, setSelectedFurnishing ] = useState([]);

    return(
        <Form
            layout="vertical"
            className="job-create-form"
            onFinish={ ({ description, file }) => {

                jobCreate({
                    variables: {
                        input: {
                            craft_id : Number(craftId),
                            type,
                            status : type === "damages" ? "completed" : "waiting",
                            description : description || "",
                            file : file?.originFileObj
                        },
                        furnishings : selectedFurnishing.map(
                            ( fOrder ) => {
                                return {
                                    furnishing_id : fOrder.furnishingId,
                                    type  : type === "claims" ? 'lost' : "order",
                                    ee_id : eeId,
                                    qty   : fOrder.qty
                                }
                            }
                        )
                    }
                }).catch((error) => nError( error ))

            } }
        >
            { (type === "last_minutes" || type === "claims") &&
                <Form.Item
                    name="additionalType"
                    initialValue={ contentType }
                >
                    <Radio.Group
                        onChange={ (e) => {
                            setContentType(e.target.value)
                        }}
                    >
                        <Radio value={ 'description' }>
                            <Localize>FORMS.RadioInput_Text_Description</Localize>
                        </Radio>
                        <Radio value={ 'furnishing' }>
                            { type === "claims" ?
                                <Localize>FORMS.RadioInput_Text_FurnishingClaim</Localize> :
                                <Localize>FORMS.RadioInput_Text_Furnishing</Localize>
                            }
                        </Radio>
                    </Radio.Group>
                </Form.Item>
            }
            <Form.Item
                style={ contentType !== "description" ? { display : "none" } : {} }
                name="description"
                className="dark"
                rules={[
                    { required: !selectedFurnishing.length, message: localizeIt("FORM_RULES.Required_JobDescription")}
                ]}
            >
                <Input.TextArea
                    placeholder={ localizeIt("FORM.Textarea_Placeholder_JobDescription") }
                    name="description"
                    rows={6}
                />
            </Form.Item>
            { (type === "last_minutes" || type === "claims") &&
                <Furnishing.Blocks.Select
                    eeId={ eeId }
                    visible = { contentType === "furnishing" }
                    furnishings = { selectedFurnishing }
                    callback    = { setSelectedFurnishing }
                />
            }
            <Form.Item
                name="file"
                valuePropName="file"
                getValueFromEvent={ (e) => {
                    if (Array.isArray(e)) {
                        return e;
                    }

                    return e?.file;
                }}
            >
                <PreUploadImage
                    onDelete={ (callback) => {
                        callback();
                    }}
                >
                    <Button
                        type = { "ghost" }
                        className = { "add-photo dark" }
                    >
                        <Icons.Photo />
                        <Localize wrap>FORM.Button_AddJobPhoto</Localize>
                    </Button>
                    <Button className="icon rounded" >
                        <SyncOutlined />
                    </Button>
                </PreUploadImage>
            </Form.Item>
            <div className="form-actions">
                <Button
                    className = "dark"
                    type = "default"
                    onClick = { callback }
                >
                    <Localize wrap>GLOBAL.Button_Text_Cancel</Localize>
                </Button>
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={ loading }
                >
                    <Localize wrap>FORM.Button_Text_AddJob</Localize>
                </Button>
            </div>
        </Form>
    )
}

export default JobCreateForm;