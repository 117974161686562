import React from "react";
import { useMutation } from "@apollo/client";
import { Form, Input, Button } from "antd";

import { STAND_KEY_CREATE, STAND_KEY_UPDATE } from "graphql/mutation/stand-gql";

import { useNotification } from "components/use-hooks";
import Localize, { localizeIt } from "components/service/localize";


const StandKeyEditForm = ({
        standId,
        keyId,
        callback,
        title,
        number,
        requestParams = {
            name : !keyId ? "standKeyCreate" : "standKeyUpdate",
            mutation : !keyId ? STAND_KEY_CREATE : STAND_KEY_UPDATE,
        }
    }) => {

    const { nError, nSuccess } = useNotification();

    const [ standKeyAction, { loading }] = useMutation( requestParams.mutation,
        {
            update(cache, { data }) {

                const {
                    [ requestParams.name ] : {
                        label,
                        message
                    }
                } = data;

                callback();

                nSuccess( label, message );
            }
        }
    );

    return(
        <Form
            key="edit-key-form"
            layout="vertical"
            className="stand-key-edit-form"
            onFinish={ (values) => {

                standKeyAction({
                    variables: {
                        input: {
                            id: keyId,
                            stand_id: standId,
                            ...values,
                        }
                    }
                }).catch((error) => nError( error ))
            } }
        >
            <Form.Item
                name="title"
                label={ <Localize>FORMS.Input_Label_Description</Localize> }
                className="dark"
                initialValue={ title }
                rules={ [
                    { required: true, message: localizeIt("FORM_RULES.Required_KeyDescription")}
                ] }
            >
                <Input name="title" />
            </Form.Item>
            <Form.Item
                name="number"
                label={ <Localize>FORMS.Input_Label_KeyNumber</Localize> }
                className="dark"
                initialValue={ number }
                rules={ [
                    { required: true, message: localizeIt("FORM_RULES.Required_KeyNumber") }
                ] }
            >
                <Input name="number" />
            </Form.Item>
            <div className="form-actions">
                <Button
                    className = "dark"
                    type = "default"
                    onClick = { callback }
                >
                    <Localize wrap>GLOBAL.Button_Text_Cancel</Localize>
                </Button>
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={ loading }
                >
                    { !keyId ?
                        <Localize wrap>STAND.Modal_Button_AddKey</Localize> :
                        <Localize wrap>STAND.Modal_Button_UpdateKey</Localize>
                    }
                </Button>
            </div>
        </Form>
    );
}

export default StandKeyEditForm;