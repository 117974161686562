import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { GET_JOBS } from "graphql/query/job-gql";

import JobGridItem from "./jobs-grid-item";
import { Loader, NoData } from "components/request-result";
import { useMe, useVarParam } from "components/use-hooks";
import { Localize, OptimizedGrid } from "components/service";
import { restorePosition, whereConditionBuilder } from "utils";


const whereCrutch = ({ status, type, ...where }) => {
    return {
        ...where,
        status : status.value && status.value.indexOf("waiting") !== -1 ?
            { ...status, value : [...status.value, 'in_progress'] } : status,
        type   : type.value && type.value.indexOf("claims") !== -1 ?
            { ...type, value : [...type.value, 'customer_claims'] } : type,
    };
}


const JobsGrid = ({ sort = [], filter, eeId}) => {

    const { state = {}, pathname } = useLocation(),
          { me } = useMe(),
          history = useHistory();

    const perPage = 30;

    const { data, loading, fetchMore } = useQuery(GET_JOBS, {
        variables: {
            where: whereConditionBuilder(whereCrutch(filter.where)),
            whereCrafts: whereConditionBuilder(filter.whereCraft),
            eeId,
            orderBy: sort.length ? [ ...sort ] : [{ column: "CREATED_AT", order : "DESC" }],
            first: perPage,
        },
        fetchPolicy: state.pagePosition ? "cache-first" : "cache-and-network",
        notifyOnNetworkStatusChange: true
    });

    const {
        craftJobsCursor : {
            edges : jobs = [],
            pageInfo = {}
        } = {}
    } = data ? data : {};

    const { moment } = useVarParam().get();

    if( data && state.pagePosition ){
        restorePosition(history, pathname, state);
    }

    return(
        <>
            { !jobs.length && !loading &&
                <NoData
                    text={{
                        after :  <Localize br={ <br /> }>NO_DATA.Jobs</Localize>
                    }}
                    icon="job"
                />
            }
            { !!jobs.length &&
                <OptimizedGrid
                    listClass = "job-grid"
                    itemClass = "job-grid-item-wrap"
                    gridItems = { jobs }
                    pageInfo  = { pageInfo }
                    fetchMore = { fetchMore }
                    perPage   = { perPage }
                    model     = "craftJobsCursor"
                >
                    <JobGridItem
                        moment = { moment }
                        me     = { me }
                    />
                </OptimizedGrid>
            }
            { loading && <Loader type="block" /> }
        </>
    );
}

export default JobsGrid;