import React from 'react';
import Icon from '@ant-design/icons';


const FilterSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M28.4,43.7c-0.2,0-0.4-0.1-0.6-0.2l-7-4.1c-0.4-0.2-0.6-0.6-0.6-1.1V26.9L6,9C5.8,8.7,5.7,8.1,5.9,7.7C6.1,7.3,6.6,7,7,7
	h35.6c0.5,0,0.9,0.3,1.1,0.7C44,8.1,43.9,8.7,43.6,9l-14,17.9v15.5c0,0.4-0.2,0.9-0.6,1.1C28.8,43.7,28.6,43.7,28.4,43.7z
	 M22.6,37.6l4.5,2.7V26.5c0-0.3,0.1-0.6,0.3-0.8L40.1,9.5H9.6l12.7,16.2c0.2,0.2,0.3,0.5,0.3,0.8V37.6z"/>
    </svg>
);

const FilterIcon = props => {
    return <Icon component={ FilterSvg } className={ props.className } />;

};


export default FilterIcon;