import React from "react";

const StandsLogGridItem = React.memo(({
        lang,
        user_full_name,
        msg_en,
        msg_de,
        created_at,
        moment
    }) => {


    return(
        <div className="stand-log-grid-item bg-light-bordered">
            <div className="time">
                <b>{ moment(created_at).format("HH:mm") }</b>
                { moment(created_at).format("DD. MMM") }
            </div>
            <div className="name">{ user_full_name }</div>
            <div className="description">
                { lang === "de" ? msg_de : msg_en }
            </div>
        </div>
    );
});

export default StandsLogGridItem;