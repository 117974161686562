import React from "react";
//import { useLocation } from "react-router-dom";

import { Forms } from "components/auth";
import { useVarParam } from "components/use-hooks";
import { Wraps } from "components/layout";
import { Localize } from "components/service";


const LoginPage = () => {

    //const location = useLocation(),
    const guestParams = useVarParam("guest");

    // if(location.state){
    //     guestParams.set({ "comeFrom" : location.state.path } );
    // }

    return(
        <Wraps.Container className="page login-page">
            <div className="block">
                <Forms.SignIn />
                <Localize
                    wrap="div"
                    wrapClass="copyright"
                    versionNumber={ guestParams.get("appVersion") }
                >
                    GLOBAL.Text_Version
                </Localize>
            </div>
        </Wraps.Container>
    );
};

export default LoginPage;