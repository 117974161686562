import React from "react";
import { Radio } from "antd";

import { Icons } from "components/layout";
import { useVarParam } from "components/use-hooks";


const ExhibitionSelectionList = ({
        currentEEid,
        action = () => {},
        type = "button",
        ee = [],
        callback = () => {}
    }) => {

    const moment = useVarParam().get("moment");

    return(
        <Radio.Group
            onChange={
                ({ target: { value } }) => action(value, callback)
            }
            value={ currentEEid }
            className={ "exhibition-selection-list-wrap" }
        >
            <ul className={ `exhibition-selection-list ${ type }` }>
                { ee.map(
                    ( { id, title, start_at, end_at } ) => {

                        const current = currentEEid && id === currentEEid,
                              startDate = moment(start_at).format("DD MMMM YYYY"),
                              endDate = moment(end_at).format("DD MMMM YYYY");

                        return (
                            <li key={ id } className={  current ? "current" : "" }>
                                { type === "radio-list" ?
                                    <Radio value={ id }>
                                        { title }<br />
                                        <b>{ startDate } - { endDate }</b>
                                    </Radio> :
                                    <Radio.Button value={ id }>
                                        <span>
                                            { title }<br />
                                            <b>{ startDate } - { endDate }</b>
                                        </span>
                                        <Icons.Arrow/>
                                    </Radio.Button>
                                }
                            </li>
                        );
                    })
                }
            </ul>
        </Radio.Group>
    );
}


export default ExhibitionSelectionList;