import { InMemoryCache, makeVar } from "@apollo/client";
import packageInfo from '../../package.json'


const version = packageInfo.version;

const cache = new InMemoryCache({
    typePolicies: {
        User: {
            fields: {
                eeCurrentId: {
                    read () {
                        const eeId = localStorage.getItem("eeCurrentId");

                        return  !!eeId ? eeId : false;
                    }
                }
            }
        },
        Stand: {
            fields: {
                keys: {
                    merge(existing, incoming) {
                        return incoming;
                    },
                },
            },
        },
        DamageReport : {
            fields : {
                damageFiles : {
                    merge(existing, incoming) {
                        return incoming;
                    },
                }
            }
        },
        Query: {
            fields: {
                isLoggedIn: {
                    read () {
                        return !!localStorage.getItem("authToken");
                    }
                },
                guestParams: {
                    read () {
                        return guestParamsVar();
                    }
                },
                appParams: {
                    read () {
                        return appParamsVar();
                    }
                }
            },
        },
    },
});

export const guestParamsInitial = {
    mainBlockClass: [],
    appBlockClass: [],
    defaultLanguage : "de",
    appVersion : version,
};

export const guestParamsVar = makeVar(guestParamsInitial);

export const appParamsInitial = {
    headerClass        : [],
    mainBlockClass     : [],
    appBlockClass      : [],
    leftHeaderControl  : false,
    headerTitle        : "",
    rightHeaderControl : false,
    bottomBar          : false,
    searchOn           : true,
    logoOn             : false,
    notifications      : {
        errorOn   : true,
        successOn : false
    },
    rememberQueryParams : {
        stand         : false,
        job           : false,
        craftOverview : false,
        hallOverview  : false
    },
    appVersion : version,
    defaultLanguage : "de",
    moment : () => {},
    //damageJobExistTill : "2022-06-01",
    damageJobExistTill : "2024-01-01",
    me : {}
};

export const appParamsVar = makeVar(appParamsInitial);

export default cache;