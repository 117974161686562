import React from "react";
import { Link, useLocation } from "react-router-dom";

import Job from "components/job";
import { Icons } from "components/layout";
import { Localize } from "components/service";
import { useMe } from "components/use-hooks";
import { UserHelpers } from "components/user";


const { permission } = UserHelpers;

const StandOverviewBlock = ({
        id,
        exhibitor,
        hall_name : hallName,
        block,
        stand_number : standNum,
        corridor,
        proauf_number : proauf,
        crafts,

    }) => {


    const location = useLocation(),
          { me } = useMe();

    let activeCrafts = crafts;

    if(permission().has({ type: "sub_view" })){
        activeCrafts = activeCrafts.filter( ({ subcontractor_id } ) => subcontractor_id === me.subcontractor.id );
    }

    const jobCount = Job.Helpers.CountInCrafts( { crafts :  activeCrafts } );

    return(
        <div className="block-wrap stand-overview-block">
            <Localize wrap="h4" wrapClass={ "text-label" }>STAND.Block_Title_Overview</Localize>
            <div className="holder bg-light-bordered">
                <ul className="row grid-three-col">
                    <li>
                        <Localize wrap="h5">STAND.Block_ItemTitle_Hall</Localize>
                        <p>{ hallName }</p>
                    </li>
                    <li>
                        <Localize wrap="h5">STAND.Block_ItemTitle_Block</Localize>
                        <p>{ block ? block : "—" }</p>
                    </li>
                    <li>
                        <Localize wrap="h5">STAND.Block_ItemTitle_Stand</Localize>
                        <p>
                            { (corridor || standNum) ?
                                `${ corridor ? corridor : "" }${ standNum }` : "—"
                            }
                        </p>
                    </li>
                </ul>
                <ul className="row grid-two-col">
                    <li>
                        <Localize wrap="h5">STAND.Block_ItemTitle_ProaufNumber</Localize>
                        <p>{ proauf ? proauf : "—" }</p>
                    </li>
                    <li >
                        <Localize wrap="h5">STAND.Block_ItemTitle_Jobs</Localize>
                        { !jobCount.total || (jobCount.total && !jobCount.mustBeDone) ?
                            <Localize
                                wrap="p"
                                wrapClass={ "color-success" }
                                jobCount={ jobCount.completed }
                            >
                                { !jobCount.total ?
                                    "STAND.Block_Item_JobCountOpen" :
                                    "STAND.Block_Item_JobCountFinished"
                                }
                            </Localize> :
                            <>
                                {!!jobCount.open &&
                                    <Localize
                                        wrap="p"
                                        jobCount={jobCount.open }
                                    >
                                        STAND.Block_Item_JobCountOpen
                                    </Localize>
                                }
                                {!!jobCount.inProgress &&
                                    <Localize
                                        wrap="p"
                                        wrapClass={ "color-warning" }
                                        jobCount={ jobCount.inProgress }
                                    >
                                        STAND.Block_Item_JobCountInProgress
                                    </Localize>
                                }
                            </>

                        }


                    </li>
                </ul>
                { !!jobCount.total && permission().has({type: "craft_job", value: "view_job"}) &&
                    <Link
                        className="link-button-primary"
                        to={{
                            pathname: `/jobs/`,
                            state: {
                                from : location.pathname,
                                queryParams : {
                                    filter : {
                                        whereCraft: {
                                            standId: {
                                                label: `Exhibitor: ${ exhibitor }`,
                                                column: "STAND_ID",
                                                operator: "EQ",
                                                value: id,
                                            },
                                        }
                                    }
                                }
                            }
                        }}
                    >
                        <Localize wrap>STAND.Block_Button_ViewStandJobs</Localize>
                        <Icons.Arrow />
                    </Link>
                }
            </div>
        </div>
    );

}

export default StandOverviewBlock;