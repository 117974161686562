import { useEffect, useState } from "react";

const useWindowDimensions = () => {

    const [dimensions, setDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {

        const handleResize = () => {
            setDimensions({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        window.addEventListener("resize", handleResize, false);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return dimensions;
}

export default useWindowDimensions;